import { DependencyList, useEffect } from 'react';

export const addAdaptiveListener = (cb: () => void) => {
  window.addEventListener('resize', cb);
  window.addEventListener('orientationchange', cb);

  return () => {
    window.removeEventListener('resize', cb);
    window.removeEventListener('orientationchange', cb);
  };
};

export const useAdaptiveEffect = (effect: () => void, deps?: DependencyList) =>
  useEffect(() => {
    effect();

    return addAdaptiveListener(effect);
  }, deps);
