import LogoIcon from "../../../icons/Logo"
import { GearIcon } from "../../../icons/Gear"

import { 
  Backdrop, 
  InfoContainer, 
  LogoContainer, 
  MaintenanceContainer, 
  MaintenanceTitle
} from "./styles"

export const MaintenancePage = () => (
  <MaintenanceContainer>
    <Backdrop />
    <LogoContainer>
      <LogoIcon />
    </LogoContainer>
    <InfoContainer>
      <MaintenanceTitle>
        Entravel is currently undergoing maintenance.
      </MaintenanceTitle>
      <MaintenanceTitle>
        We'll be back shortly - stay tuned!
      </MaintenanceTitle>
      <GearIcon />
    </InfoContainer>
  </MaintenanceContainer>
)