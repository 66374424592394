import { VFC } from 'react';

const ArrowDown: VFC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="6"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96756 0.266515L5.49553 3.80182L2.02349 0.266515C1.6745 -0.0888381 1.11074 -0.0888381 0.761745 0.266515C0.412752 0.621868 0.412752 1.1959 0.761745 1.55125L4.86913 5.73349C5.21812 6.08884 5.78188 6.08884 6.13087 5.73349L10.2383 1.55125C10.5872 1.1959 10.5872 0.621868 10.2383 0.266515C9.88926 -0.0797267 9.31656 -0.0888381 8.96756 0.266515Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDown;
