import Typography, { TypographyProps } from '@mui/material/Typography';
import styled from 'styled-components/macro';

const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

export const EllipsisTypography = ({
  children,
  ...props
}: Omit<TypographyProps, 'ref'>) => (
  <StyledTypography
    {...props}
    title={typeof children === 'string' ? children : undefined}
  >
    {children}
  </StyledTypography>
);
