import { inputClasses } from '@mui/material/Input';
import { inputLabelClasses } from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';

type Props = Omit<TextFieldProps, 'color' | 'variant'> & {
  focused: boolean;
  alignRight?: boolean;
};

const StyledTextField = styled(TextField)<TextFieldProps>`
  .${inputClasses.input} {
    ${({ theme }) => theme.typography.label1 as {}}
  }
`;

const StyledButton = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchPanelInput = forwardRef<HTMLDivElement, Props>(
  ({ focused, alignRight, children, ...props }, ref) => (
    <StyledTextField
      {...props}
      ref={ref}
      variant="standard"
      color="focusSecondary"
      InputLabelProps={{ ...props.InputLabelProps, focused, shrink: true }}
      //@ts-expect-error
      InputProps={{
        ...props.InputProps,
        ...(children
          ? {
              componentsProps: {
                input: {
                  children,
                },
              },
              inputComponent: StyledButton,
            }
          : {}),
        disableUnderline: true,
      }}
      inputProps={{ tabIndex: -1, ...props.inputProps }}
      sx={
        alignRight
          ? {
              [`.${inputLabelClasses.root}`]: { right: 0, left: 'auto' },
              [`.${inputClasses.input}`]: { textAlign: 'right' },
            }
          : undefined
      }
    />
  )
);
