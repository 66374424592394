import { VFC } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

import Container from 'src/components/common/Container';
import useMatchMedia from 'src/hooks/useMatchMedia';
import LinksStack, { StackLink } from 'src/components/common/LinksStack';
import Logo from 'src/components/common/Logo';
import { HEADER_HEIGHT_VARIABLE } from 'src/components/common/GlobalStyle';
import { useMatchPage } from 'src/hooks/useMatchPage';
import { CurrencyButton as DesktopCurrencyButton } from 'src/components/Panels/HeaderPanel/ModalMenu/CurrencyButton';
import { userSelector } from 'src/store/user/selectors';

import { AuthBlock } from './ModalMenu/AuthBlock';

const HeaderWrapper = styled.header<P>`
  background: ${({ theme, $isContrast }) =>
    $isContrast ? 'transparent' : '#121212'};
  position: relative;
  z-index: 4;
  border-bottom: 1px solid rgba(8, 31, 45, 0.1);
`;

const HeaderContainer = styled(Container)`
  height: ${HEADER_HEIGHT_VARIABLE};
  display: grid;
`;

const SupportButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
`

type P = { $isContrast: boolean };

const StyledStack = styled(Stack)<P>`
  ${({ theme, $isContrast }) =>
    $isContrast && {
      [theme.breakpoints.down('sm')]: { color: theme.palette.common.white },
    }}
`;

const HeaderPanel: VFC = () => {
  const theme = useTheme();
  const { isAuthenticated } = useSelector(userSelector);

  const isDesktop = useMatchMedia(theme.breakpoints.up('md'));

  const isContrast = useMatchPage('/');

  const handleClickSupport = () => {
    window.open('/contact');
  }

  const links: StackLink[] = [];
  const color = isContrast ? '#000000' : '#FFF';

  const Text = styled.div`
    color: ${color};
  `;

  return (
    <HeaderWrapper $isContrast={isContrast}>
      <HeaderContainer disableMargin>
        <StyledStack
          spacing={2}
          justifyContent={{ xs: 'space-between', md: 'space-between' }}
          alignItems="center"
          direction="row"
          position="relative"
          $isContrast={isContrast}
        >
          <Stack alignItems="center" spacing={12} direction="row">
            <Logo isContrast={isContrast} />
            {isDesktop && (
              <LinksStack spacing={9} direction="row" links={links} />
            )}
          </Stack>
          <Stack
            spacing={8}
            direction="row"
            alignItems="center"
            position={{ xs: 'absolute', md: 'initial' }}
            right={0}
          >
            {isAuthenticated && isDesktop ? (
              <SupportButton         
                color="buttonSecondary"
                onClick={handleClickSupport}
              >
                <Text>
                  Support
                </Text>
              </SupportButton>
            ) : null}
            
            {isDesktop ? <DesktopCurrencyButton isContrast={isContrast} /> : null}
            <AuthBlock isContrast={isContrast} />
          </Stack>
        </StyledStack>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default HeaderPanel;
