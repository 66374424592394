import { FC, memo } from 'react';
import styled from 'styled-components/macro';

import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from 'src/configs';
import { media } from 'src/modules/mediaQuery';
import Loader from 'src/components/common/Loader';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  height: calc(100vh - ${HEADER_DESKTOP_HEIGHT}px);

  ${media.phoneTablet`
    height: calc(100vh - ${HEADER_MOBILE_HEIGHT}px);
  `}
`;

const WrapInner = styled.div``;

const MainLoader: FC = memo(() => (
  <Wrap>
    <WrapInner>
      <Loader />
    </WrapInner>
  </Wrap>
));

export default MainLoader;
