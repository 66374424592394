import { FC, useRef, useEffect } from 'react';

type Props = { freezed: boolean };

/**
 * Note: on first render `freezed` should be `false`
 */
export const Freeze: FC<Props> = ({ children, freezed }) => {
  const ref = useRef<HTMLDivElement>(null);

  const el = ref.current;

  const childNodesRef = useRef<ChildNode[] | undefined>();

  childNodesRef.current ||=
    freezed && el ? Array.from(el.childNodes) : undefined;

  useEffect(() => {
    if (freezed) {
      ref.current?.append(...(childNodesRef.current || []));
    } else {
      childNodesRef.current = undefined;
    }
  }, [freezed]);

  return <div ref={ref}>{!freezed && children}</div>;
};
