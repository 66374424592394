import { VFC } from 'react';
import styled, { useTheme } from 'styled-components';
import 'styled-components/macro';

import useToggler from 'src/hooks/useToggler';
import { I18n } from 'src/components/I18n';
import { CustomButton } from 'src/components/shared/CustomButton';
import useMatchMedia from 'src/hooks/useMatchMedia';
import CModal from 'src/components/common/CModal';
import CDrawer from 'src/components/common/CDrawer';

import {
  IRoomFacilitiesModal,
  RoomFacilitiesModal,
} from './RoomFacilitiesModal';

const Btn = styled(CustomButton)`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
  text-decoration-line: underline;
`;

export const RoomFacilitiesModalButton: VFC<IRoomFacilitiesModal> = (props) => {
  const { toggler, handleClose, handleOpen } = useToggler();

  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return (
    <>
      <Btn btnType="link" onClick={handleOpen}>
        <I18n id="GENERAL.SHOW_MORE" />
      </Btn>

      {isMobile ? (
        <CDrawer
          open={toggler}
          onClose={handleClose}
          header={
            <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.MORE_FACILITY_INFORMATION" />
          }
          css={`
            width: 100%;
          `}
        >
          <RoomFacilitiesModal {...props} />
        </CDrawer>
      ) : (
        <CModal open={toggler} onClose={handleClose} withCloseButton>
          <RoomFacilitiesModal {...props} />
        </CModal>
      )}
    </>
  );
};
