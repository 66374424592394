import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';

const widths = {
  sm: 672, // 612 + padding 30px
  md: 988, // 928 + padding 30px
  lg: 1324, // 1264 + padding 30px
};

type Size = 'sm' | 'md' | 'lg';

const MainContainer = styled.div<{ size?: Size }>`
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ size }) => (size ? `${widths[size]}px` : 'initial')};

  ${media.extraSmall`
    padding: 0 20px;
    max-width: initial;
  `};
`;

export default MainContainer;
