import styled from "styled-components";

import { Button } from "src/components/common/WrappedButtons";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;

  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
`;
