import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@formatjs/intl-relativetimeformat/polyfill';

import smoothscroll from 'smoothscroll-polyfill';
import { useEffect, useState, VFC } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import 'intersection-observer';
import moment from 'moment';
import '@formatjs/intl-pluralrules/polyfill';
import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';

import App from 'src/components/App/App';
import ErrorBoundaryFallback from 'src/components/ErrorBoundaryFallback';
import { store, persistor } from 'src/store';
import history from 'src/modules/history';
import { setSocialRefs } from 'src/modules/setSocialRefs';
import 'src/styles/index.css';

import muiTheme from './configs/muiTheme';
import { unregister } from './serviceWorker';
import { appConfig } from './modules/app-config';
import { MaintenancePage } from './components/pages/maintenance-page/maintenance-page';
import { apiCall } from './modules/api';

smoothscroll.polyfill();

declare module 'immutability-helper';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    google: typeof google;
    grecaptcha: ReCaptchaV2.ReCaptcha;
    // eslint-disable-next-line no-undef
    moment: typeof moment;
  }
}

setSocialRefs(window.location.search);

Sentry.init({
  dsn: 'https://181b8808fd98462da71a962c44d68272@o998054.ingest.sentry.io/5956491',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  enabled: appConfig('ENV') === 'production',
  environment: appConfig('ENV'),
  release: appConfig('APP_BUILD_VERSION'),
});

const AppContainer: VFC = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const checkHealth = async () => {
    try {
      await apiCall(
        '/healthcheck',
        { method: 'GET' }
      );
    } catch (e) {
      console.error(e);
      setIsMaintenanceMode(true);
    }
  }

  useEffect(() => {
    void checkHealth()
  }, [])

  if (isMaintenanceMode) {
    return <MaintenancePage />
  }
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            {/* @ts-expect-error */}
            <ConnectedRouter history={history}>
              {/* @ts-expect-error */}
              <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <App />
              </Sentry.ErrorBoundary>
            </ConnectedRouter>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  )
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
