import update from 'immutability-helper';

import { ConfirmationActionTypes, LoadingTypes } from 'src/constants';
import { createReducer } from 'src/modules/helpers';
import { ErrorResponseAction, ActionSuccess } from 'src/models/actions';
import { CustomerOfferResponse } from 'src/models/customers/manager';

export interface ConfirmationState {
  isLoading: LoadingTypes;
  offerKey?: string;
  data?: CustomerOfferResponse;
  error?: any[];
}

const ConfirmationInitialState: ConfirmationState = {
  isLoading: LoadingTypes.IDLE,
  offerKey: undefined,
  data: undefined,
  error: [],
};

export const confirmationReducer = createReducer(ConfirmationInitialState, {
  [ConfirmationActionTypes.START_CONFIRMATION_POLLING]: (
    state: ConfirmationState
  ) =>
    update(state, {
      isLoading: { $set: LoadingTypes.RUNNING },
      data: { $set: undefined },
      error: { $set: [] },
    }),
  [ConfirmationActionTypes.FETCH_CONFIRMATION_SUCCESS]: (
    state: ConfirmationState,
    action: ActionSuccess<
      ConfirmationActionTypes.FETCH_CONFIRMATION_SUCCESS,
      CustomerOfferResponse
    >
  ) => {
    const { payload } = action;

    return update(state, {
      data: { $set: payload },
    });
  },
  [ConfirmationActionTypes.FETCH_CONFIRMATION_FAILURE]: (
    state: ConfirmationState,
    action: ErrorResponseAction<ConfirmationActionTypes.FETCH_CONFIRMATION_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      error: { $push: [error.message] },
    });
  },
  [ConfirmationActionTypes.STOP_CONFIRMATION_POLLING]: (
    state: ConfirmationState
  ) =>
    update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
    }),
});
