import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';
import { CustomButton } from 'src/components/shared/CustomButton';
import { HotelPreview } from 'src/components/HotelPreview';

export const Column = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  ${media.tabletDesktop`
    ${Column}:first-child {
      width: 40%;
    }

    ${Column}:nth-child(2) {
      width: 24%;
    }

    ${Column}:last-child {
      width: 36%;
    }
  `}
`;

export const Label = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;

export const BookRoomButton = styled(CustomButton)`
  white-space: unset;
  background: #19191A;
  border-radius: 12px;
  padding: 12px 24px;

  &:hover {
    background: #19191A;
  }

  &:active {
    background: #19191A;
  }

  &:focus {
    background: #19191A;
  }
`;

export const StyledHotelPreview = styled(HotelPreview)`
  padding-bottom: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(p) => p.theme.custom.whiteMainColor};
  cursor: ${(p) => (p.images.length > 1 ? 'pointer' : 'default')};

  ${media.phone`
    padding-bottom: 60%;
  `}

  &::after {
    border-radius: 5px;
  }
`;

export const NoImagePlaceholder = styled.span.attrs({ className: 'icon-room' })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: ${(p) => p.theme.custom.grayTextColor};
`;
