import update from 'immutability-helper';
import _random from 'lodash/random';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import setDay from 'date-fns/setDay';

import { createReducer } from 'src/modules/helpers';
import { ActionTypes } from 'src/constants';
import topDestinations from 'src/components/pages/Home/TopDestinations/topDestinations';
import { Destination } from 'src/components/pages/Home/TopDestinations/types';
import { FieldsName } from 'src/components/Panels/SearchPanel';

export type Dates = [checkIn: Date | null, checkOut: Date | null];

export interface Child {
  age: number;
}

export interface Room {
  adults: number;
  children: Child[];
}

export type SearchParamsState = {
  [FieldsName.DESTINATION]: Destination;
  [FieldsName.DATES]: Dates;
  [FieldsName.ROOMS]: Room[];
  [FieldsName.BUSINESS_TRIP]: boolean;
};

export const searchParamsState: SearchParamsState = {
  [FieldsName.DESTINATION]:
    topDestinations[_random(0, topDestinations.length - 1)].dest,
  [FieldsName.DATES]: [
    setDay(startOfDay(addDays(new Date(), 0)), 6),
    setDay(startOfDay(addDays(new Date(), 0)), 7),
  ],
  [FieldsName.ROOMS]: [
    {
      adults: 2,
      children: [],
    },
  ],
  [FieldsName.BUSINESS_TRIP]: false,
};

export const searchParams = createReducer(searchParamsState, {
  [ActionTypes.SEARCH_PARAMS_CHANGE](
    state: SearchParamsState,
    action: {
      payload: Partial<SearchParamsState>;
    }
  ) {
    return update(state, {
      $merge: action.payload,
    });
  },
});
