import { FC, memo } from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { toI18nKey } from 'src/modules/helpers';
import { fontSizeSmall } from 'src/modules/fontSizes';
import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

const TrustYouReview = styled.span`
  display: inline-block;
  background-color: ${(p) => rgba(p.theme.custom.greenMainColor, 0.1)};
  color: ${(p) => p.theme.custom.greenMainColor};
  ${fontSizeSmall};
  font-weight: bold;
  border-radius: 15px;
  padding: 8px 11px;
`;

interface TrustYouProps {
  data: NonNullable<HotelInfoSelectorResult>;
  className?: string;
}

const TrustYou: FC<TrustYouProps> = memo((props) => {
  const { className, data } = props;
  const [review] = _get(data, 'reviews', []);

  if (_isNil(review) || !review.score || !review.scoreDescription) {
    return null;
  }

  return (
    <TrustYouReview className={className}>
      {review.score} |{' '}
      {<I18n id={toI18nKey(review.scoreDescription, 'HOTEL_RATING')} />}
    </TrustYouReview>
  );
});

export { TrustYou };
