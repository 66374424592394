import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import { ActionTypes, LoadingTypes } from 'src/constants';
import { ErrorResponseAction, Error } from 'src/models/actions';

export interface GeneralState {
  isLoading: LoadingTypes;
  error?: Error;
}

const generalInitialState: GeneralState = {
  isLoading: LoadingTypes.IDLE,
};

export const generalReducer = createReducer(generalInitialState, {
  [ActionTypes.SUBMIT_CONTACT_FORM_REQUEST]: (state: GeneralState) =>
    update(state, {
      isLoading: { $set: LoadingTypes.RUNNING },
      error: { $set: undefined },
    }),

  [ActionTypes.SUBMIT_CONTACT_FORM_SUCCESS]: (state: GeneralState) =>
    update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
    }),
  [ActionTypes.SUBMIT_CONTACT_FORM_FAILURE]: (
    state: GeneralState,
    action: ErrorResponseAction<ActionTypes.SUBMIT_CONTACT_FORM_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
      error: { $set: error },
    });
  },
});
