import { Action } from 'redux';

import { ActionTypes } from 'src/constants';

interface HotelActionParams {
  hotelId: number;
  silent?: boolean;
  searchId?: number;
}

interface HotelRoomsActionParams {
  hotelId: number;
  srId?: number;
}

export type HotelAction = Action<ActionTypes.FETCH_HOTEL_REQUEST> &
  HotelActionParams;

export type HotelRoomsAction = Action<ActionTypes.FETCH_HOTEL_ROOMS_REQUEST> &
  HotelRoomsActionParams;

export type StopHotelPollingAction =
  Action<ActionTypes.STOP_HOTEL_ROOMS_POLLING>;

export function getHotel({ hotelId, silent, searchId }: HotelActionParams): HotelAction {
  return {
    type: ActionTypes.FETCH_HOTEL_REQUEST,
    hotelId,
    silent,
    searchId
  };
}

export function getHotelRooms({
  hotelId,
  srId,
}: HotelRoomsActionParams): HotelRoomsAction {
  return {
    type: ActionTypes.FETCH_HOTEL_ROOMS_REQUEST,
    hotelId,
    srId,
  };
}

export function stopHotelRoomsPolling(): StopHotelPollingAction {
  return {
    type: ActionTypes.STOP_HOTEL_ROOMS_POLLING,
  };
}
