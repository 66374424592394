import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';

import { HotelSearchPanel } from 'src/components/Panels/SearchPanel';
import { getHotel, getHotelRooms } from 'src/store/hotel/actions';
import { hotelSelector } from 'src/store/hotel/selectors';
import { setDeprecatedParams } from 'src/store/deprecated/actions';

interface SearchFormProps {
  onHandleSubmit?: () => void;
  isStatic: boolean;
}

export const SearchForm: FC<SearchFormProps> = ({
  onHandleSubmit,
  isStatic,
}) => {
  const dispatch = useDispatch();
  const { hotelId } = useSelector(hotelSelector);
  const onSubmitHandler = () => {
    dispatch(
      setDeprecatedParams({
        es: undefined,
        searchId: undefined,
        Residency: undefined,
      })
    );

    dispatch(getHotelRooms({ hotelId }));

    if (isStatic) {
      dispatch(getHotel({ hotelId, silent: true }));
      dispatch(replace(`/hotel/${hotelId}`));
    }

    onHandleSubmit?.();
  };

  return <HotelSearchPanel onSubmit={onSubmitHandler} isStatic={isStatic} />;
};
