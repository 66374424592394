import { useTheme } from 'styled-components/macro';

import useMatchMedia from 'src/hooks/useMatchMedia';
import useFormatDistance from 'src/hooks/useFormatDistance';
import useFormatPrice from 'src/hooks/useFormatPrice';

export type HotelCardData = {
  formatPrice: (price: number) => string;
  formatDistance: (distance: number) => string;
  formatReviewsCount: (count: number) => string;
  isDesktop: boolean;
};

const useHotelCardData = (): HotelCardData => ({
  formatPrice: useFormatPrice(),
  formatDistance: useFormatDistance(),
  formatReviewsCount: (count) =>
    new Intl.NumberFormat('en', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(count),
  isDesktop: useMatchMedia(useTheme().breakpoints.up('lg')),
});

export default useHotelCardData;
