import { ReactNode, FC } from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div<{ isSearchResults?: boolean }>`
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.custom.textColor};

  ${({ isSearchResults }) =>
    isSearchResults &&
    css`
      color: ${(props) => props.theme.custom.grayTextColor};
      padding: 0 5px;
    `}
`;

export const Info = styled.div<{ isSearchResults?: boolean }>`
  padding: 5px 0 0 45px;
  min-height: 18px;

  ${({ isSearchResults }) =>
    isSearchResults &&
    css`
      display: flex;
      align-items: center;
      padding: 0 0 0 30px;
    `}
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const IconDefault = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.custom.grayTextColor};
  font-size: 18px;
`;

interface InfoBlockProps {
  title: ReactNode;
  iconClass?: string;
  className?: string;
  children?: ReactNode;
  isSearchResults?: boolean;
  iconElement?: JSX.Element;
}

export const InfoBlock: FC<InfoBlockProps> = ({
  title,
  iconClass,
  className,
  children,
  isSearchResults,
  iconElement,
}) => (
  <Wrapper className={className} isSearchResults={isSearchResults}>
    {iconElement ?? <IconDefault className={iconClass} />}
    <Info isSearchResults={isSearchResults}>
      <Title>{title}</Title>
      {children}
    </Info>
  </Wrapper>
);
