import { FC, memo, useEffect, useRef, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import {
  disablePageScroll,
  enablePageScroll,
  clearQueueScrollLocks,
} from 'scroll-lock';
import _isFunction from 'lodash/isFunction';

import usePortal from 'src/hooks/usePortal';
import { handleOutsideClick } from 'src/modules/handleOutsideClick';

const PortalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  background-color: ${(p) => p.theme.custom.whiteMainColor};
  z-index: 10;
`;

interface MobilePortalProps {
  children?: any;
  closePortal: () => void;
  className?: string;
  disableScrollLock?: boolean;
}

const preventClickOnElementUnder = (ev: SyntheticEvent) => {
  ev.stopPropagation();
};

export const SimplePortal: FC<MobilePortalProps> = memo(
  ({ children, className, closePortal, disableScrollLock }) => {
    const target = usePortal();
    const portalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!disableScrollLock) {
        const portal = portalRef.current || undefined;

        disablePageScroll(portal);

        return () => {
          clearQueueScrollLocks();
          enablePageScroll(portal);
        };
      }
    }, [disableScrollLock]);

    useEffect(() => {
      const handleEscClick = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape' || ev.key === 'Esc') {
          closePortal();
        }
      };

      const handleOutside = handleOutsideClick(portalRef, closePortal);

      document.addEventListener('keyup', handleEscClick);
      target.addEventListener('click', handleOutside);

      return () => {
        document.removeEventListener('keyup', handleEscClick);
        target.removeEventListener('click', handleOutside);
      };
    }, [closePortal, target]);

    const renderPortalChild = () => (
      <PortalWrapper
        ref={portalRef}
        className={className}
        onClick={preventClickOnElementUnder}
      >
        {_isFunction(children) ? children(closePortal) : children}
      </PortalWrapper>
    );

    return ReactDOM.createPortal(renderPortalChild(), target);
  }
);
