import { useSelector } from 'react-redux';

import { intlSelector } from 'src/store/intl/selectors';

const useFormatDistance = () => {
  const { currentLocale } = useSelector(intlSelector);

  return (distance: number) => {
    let unit;

    if (distance >= 1000) {
      distance /= 1000;

      unit = 'kilometer';
    } else {
      distance = Math.round(distance / 10) * 10;

      unit = 'meter';
    }

    try {
      return new Intl.NumberFormat(currentLocale, {
        style: 'unit',
        unit,
        maximumFractionDigits: 2,
      }).format(distance);
    } catch {
      return `${new Intl.NumberFormat(currentLocale, {
        style: 'decimal',
        maximumFractionDigits: 2,
      }).format(distance)} ${unit === 'meter' ? 'm' : 'km'}`;
    }
  };
};

export default useFormatDistance;
