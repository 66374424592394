import { LoaderStatus, useGoogleMapLoader } from 'google-maps-js-api-react';
import { ComponentType } from 'react';
import styled from 'styled-components/macro';
import CircularProgress from '@mui/material/CircularProgress';

const LoaderContainer = styled.div`
  background: ${({ theme }) => theme.palette.divider};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props<T extends {}> = {
  isLoading?: boolean;
  component: ComponentType<T>;
} & T;

const GoogleMapLoader = <T extends {}>({
  isLoading,
  component: Component,
  ...rest
}: Props<T>) => {
  const status = useGoogleMapLoader();

  return !isLoading && status === LoaderStatus.LOADED ? (
    <Component {...(rest as unknown as T)} />
  ) : (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  );
};

export default GoogleMapLoader;
