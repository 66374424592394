import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { userSelector } from "src/store/user/selectors";

import { useOpenAuthModal } from "../Auth/AuthModalProvider";
import { EAuth } from "../Auth";

const LoyaltyProgramButton = styled.div(({ variant }: LoyaltyButtonProps) => {
  let styles = `
    border-radius: 20px;
    backdrop-filter: blur(12.5px);
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    color: #2D2D2D;
    font-size: 16px;
    width: 100%;

    :hover {
      opacity: 0.8;
    };
  `;

  if (variant === 'primary') {
    styles = `
      ${styles}
      border: 1px solid #FFF;
      background: rgba(255, 250, 246, 0.60);
    `
  };

  if (variant === 'secondary') {
    styles = `
      ${styles}
      border: 1px solid #E3E3E7;
      background:#FFF;
    `
  }

  return styles;
})

interface LoyaltyButtonProps {
  variant?: 'primary' | 'secondary'
}

export const LoyaltyButton = ({ variant = 'primary' }: LoyaltyButtonProps) => {
  const history = useHistory();
  const { isAuthenticated } = useSelector(userSelector);
  const handleOpenModal = useOpenAuthModal();

  const handleClick = () => {
    if (!isAuthenticated) {
      handleOpenModal(EAuth.SIGN_IN);

      return;
    }

    history.push('/account/loyalty')
  }

  return (
    <LoyaltyProgramButton onClick={handleClick} variant={variant}>
      🔥 Book 10 nights, get 
      <strong>1 free</strong>
    </LoyaltyProgramButton>
  )
}
