import { FC } from 'react';
import styled from 'styled-components/macro';

interface ContainerProps {
  className?: string;
  children?: any | any[];
}

const Container: FC<ContainerProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

const TwoColumnLayout = styled(Container)`
  text-align: left;
  display: flex;
`;

export const RightColumn = styled(Container)``;

export const LeftColumn = styled(Container)`
  flex-grow: 1;
`;

export default TwoColumnLayout;
