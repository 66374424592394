import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from 'styled-components/macro';
import { TypographyVariant } from 'src/configs/muiTheme';

type Props = { label: ReactNode; width: string };
export const labelVariant: TypographyVariant = 'label1';

type StyledTextProps = {
  width: string;
};

const StyledText = styled.div<StyledTextProps>`
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  width: ${props => props.width};
`;

export const RoomChooserFormItem: FC<Props> = ({ children, label, width }) => (
  <Stack
    direction="row"
    spacing={6}
    alignItems="center"
    justifyContent="space-between"
  >
    <StyledText width={width}>
      {label}
    </StyledText>
    <Box flexGrow={1} position="relative">
      {children}
    </Box>
  </Stack>
);

