import parse from 'date-fns/parse';

import { isValidDates } from 'src/modules/isValidDates';

export const datesUrlParser = (datesString: string) => {
  const [checkIn, checkOut] = datesString
    .split(',')
    .map((item) => parse(item, 'yyyyMMdd', new Date()));

  // if undefined, spotify's url library leaves dates in state without changes
  return isValidDates([checkIn, checkOut]) ? [checkIn, checkOut] : undefined;
};
