import { LinksStackProps, StackLink } from 'src/components/common/LinksStack';
import { TextProps } from 'src/modules/getText';

export const USER_NAV_MENU: StackLink[] = [
  {
    url: '/account/bookings',
    textI18nKey: 'MAIN_NAV_MENU.BOOKINGS',
  },
  {
    url: '/account/wallet-balance',
    textI18nKey: 'MAIN_NAV_MENU.WALLET_BALANCE',
  },
  {
    url: '/account/favorites',
    textI18nKey: 'MAIN_NAV_MENU.FAVORITES',
  },
  {
    url: '/account/personal-info',
    textI18nKey: 'MAIN_NAV_MENU.PERSONAL_INFO',
  },
  {
    url: '/account/settings',
    textI18nKey: 'MAIN_NAV_MENU.ACCOUNT_SETTINGS',
  },
];

type FooterLinkSection = Pick<LinksStackProps, 'links'> & TextProps;

export const getFooterLinksSections = (locale: string): FooterLinkSection[] => [
  {
    textI18nKey: 'MAIN.FOOTER.ABOUT_US.INFO_BLOCK.TITLE',
    links: [
      { textI18nKey: 'MAIN_NAV_MENU.ABOUT', url: '/about' },
      { textI18nKey: 'MAIN_NAV_MENU.CONTACT', url: '/contact' },
      { textI18nKey: 'MAIN_NAV_MENU.PRIVACY', url: '/privacy-policy' },
      { textI18nKey: 'MAIN_NAV_MENU.TERMS', url: '/terms-and-conditions' },
    ],
  },
  {
    textI18nKey: 'MAIN.FOOTER.HELP.INFO_BLOCK.TITLE',
    links: [
      { textI18nKey: 'MAIN_NAV_MENU.FAQ', url: '/faq' },
      { textI18nKey: 'MAIN_NAV_MENU.IMPRESSUM', url: '/impressum' },
    ],
  },
];
