import { FC, MouseEvent, useCallback } from 'react';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';
import { fontSizeSmall } from 'src/modules/fontSizes';
import { SimplePortal } from 'src/components/shared/SimplePortal';
import { CCloseButton } from 'src/components/shared/CCloseButton';
import Loader from 'src/components/common/Loader';
import { LoadingTypes } from 'src/constants';
import { Image } from 'src/types';

import { useFetchHotelImages } from './useFetchHotelImages';
import { NavigationPrev, NavigationNext } from './ModalNavigation';

const StyledPortal = styled(SimplePortal)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.custom.modalMaskBgColor};

  .modal-gallery {
    width: 60%;
  }

  .image-gallery-slide-wrapper {
    padding-bottom: 55px;
  }

  .image-gallery-slides {
    margin: 0 auto;
    height: 50vh;
    width: calc(100% - 128px);
  }

  .image-gallery-slide {
    height: 100%;
    background: none;
  }

  .image-gallery-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  .image-gallery-thumbnails-wrapper {
    position: fixed;
    bottom: 50px;
    width: 60%;
  }

  .image-gallery-thumbnail.active {
    border-color: ${(p) => p.theme.custom.secondaryColor};
  }

  .image-gallery-thumbnail {
    height: 10vh;
    width: 10vh;
    margin: 0 !important;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 100%;
  }

  .image-gallery-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-gallery-index {
    top: initial;
    bottom: 0;
    padding: 0;
    width: 100%;
    background: none;
    text-align: center;
    ${fontSizeSmall};
    z-index: 0;
  }

  ${media.phoneTablet`
    .modal-gallery {
      width: 100%;
    }

    .image-gallery-slide-wrapper {
      padding-bottom: 50px;
    }

    .image-gallery-thumbnails-wrapper {
      width: 100%;
    }
  `}

  ${media.phone`
    .image-gallery-slides {
      width:100%;
      height: 40vh;
    }

    .image-gallery-slide-wrapper {
      padding-bottom: 0;
    }

    .image-gallery-thumbnails-wrapper {
      bottom: 10px;
      padding: 0 10px;
    }

    .image-gallery-index {
      position: fixed;
      bottom: 15vh;
      line-height: 34px;
    }
  `}

  ${media.tablet`
    .image-gallery-slides {
      width: calc(100% - 188px);
    }

    .image-gallery-thumbnails-wrapper {
      bottom: 30px;
      padding: 0 30px;
    }
  `}
`;

const StyledCCloseButton = styled(CCloseButton)`
    z-index: 1;
    position: absolute;
    top: -40px;
    right: 0;
    width: 34px;
    height: 34px;
    outline: none;

    &:hover {
      opacity: 0.8;
    }

    background-color: #006ce4;

    &:hover {
      background-color: #004c9f;
    }

    &:active {
      background-color: #003a7a;
    }

    ${media.phone`
      right: 16px;
    `}

    ${media.tablet`
      right: 30px;
    `}
`;

const renderLeftNav = (onClick: (event: MouseEvent<HTMLElement>) => void) => (
  <NavigationPrev onClick={onClick} />
);

const renderRightNav = (onClick: (event: MouseEvent<HTMLElement>) => void) => (
  <NavigationNext onClick={onClick} />
);

interface GalleryPortalProps {
  closeModal: () => void;
  initialIndex?: number;
  images?: Image[];
  hotelId?: number;
  isModalOpen?: boolean;
}

export const GalleryPortal: FC<GalleryPortalProps> = (props) => {
  const { initialIndex, closeModal, isModalOpen = true } = props;

  const { status, images = [] } = useFetchHotelImages(
    isModalOpen,
    props.hotelId,
    props.images
  );

  const items = images.map(({ url }) => ({
    original: url,
    thumbnail: url,
  }));

  const renderCustomControls = useCallback(
    () => <StyledCCloseButton onClick={closeModal} />,
    [closeModal]
  );

  return (
    <StyledPortal closePortal={closeModal}>
      {!items.length && status === LoadingTypes.RUNNING ? (
        <Loader />
      ) : (
        <ImageGallery
          items={items}
          startIndex={initialIndex}
          infinite={true}
          lazyLoad={true}
          showThumbnails={true}
          showIndex={true}
          showBullets={false}
          showFullscreenButton={false}
          showPlayButton={false}
          slideDuration={100}
          additionalClass="modal-gallery"
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderCustomControls={renderCustomControls}
        />
      )}
    </StyledPortal>
  );
};
