import { FC } from 'react';

import useLozad from 'src/hooks/useLozad';

interface ImageLazyProps {
  className?: string;
  url: string;
  alt?: string;
}

export const ImageWithLazyLoad: FC<ImageLazyProps> = (props) => {
  const { className, url, alt = '', ...rest } = props;
  const ref = useLozad<HTMLImageElement>();

  return (
    <img
      className={className}
      src="/assets/i/null.jpg"
      data-src={url}
      ref={ref}
      alt={alt}
      {...rest}
    />
  );
};
