import Cookies from 'js-cookie';
import addHours from 'date-fns/addHours';
import { parse } from 'query-string';

import { parseClientOptions } from './queryStringOptions';

/**
 * @param query - window.location.search
 */
export const setSocialRefs = (query: string) => {
  const { rfid }: { rfid?: string } = parse(query, parseClientOptions);

  // handle social refs to cookies
  if (rfid) {
    const [name, value] = rfid.split('-');

    Cookies.set(name, value, { expires: addHours(new Date(), 1) });
  }
};
