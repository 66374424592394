import { FC } from 'react';
import MediaQuery from 'react-responsive';

import { breakPoints } from 'src/configs';

const { md, lg, xl } = breakPoints;

const LargeDesktop: FC = ({ children }) => (
  <MediaQuery minWidth={xl}>{children}</MediaQuery>
);

const Desktop: FC = ({ children }) => (
  <MediaQuery minWidth={lg}>{children}</MediaQuery>
);

const TabletDesktop: FC = ({ children }) => (
  <MediaQuery minWidth={md}>{children}</MediaQuery>
);

const Phone: FC = ({ children }) => (
  <MediaQuery maxWidth={md - 1}>{children}</MediaQuery>
);

const PhoneTablet: FC = ({ children }) => (
  <MediaQuery maxWidth={lg - 1}>{children}</MediaQuery>
);

export { Desktop, LargeDesktop, TabletDesktop, Phone, PhoneTablet };
