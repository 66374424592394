import { ReactNode, MouseEvent } from 'react';

import { Image } from '../../types';

export enum HotelPreviewSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface HotelPreviewProps {
  className?: string;
  id?: string;
  size?: HotelPreviewSize;
  images: Image[];
  imagesCount?: number;
  toggleModal?: (initialIndex?: number) => void;
  hotelId?: number;
  placeholder?: ReactNode;
  trackingId?: string;
  showMapButton?: boolean;
  onMapButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}
