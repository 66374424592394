import { ComponentProps, VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

import { defaultMessages } from 'src/containers/ConnectedIntl/messages/messages';

export type I18nProps = Omit<
  ComponentProps<typeof FormattedMessage>,
  'id' | 'values'
> &
  Required<Pick<ComponentProps<typeof FormattedMessage>, 'id'>> & {
    values?: Partial<Record<string, any>>;
  };

export const I18n: VFC<I18nProps> = ({ id, ...props }) => {
  if (_isEmpty(defaultMessages)) {
    throw new Error(
      'Seems like you did not pass "messages" for I18n in IntlProvider'
    );
  }

  const messageData = defaultMessages[id] || { id };

  return <FormattedMessage tagName="span" {...props} {...messageData} />;
};
