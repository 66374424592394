import { MouseEvent, TouchEvent } from 'react';

const handlePreventLostFocus = (
  e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>
) => e.preventDefault();

const preventLostFocus = Object.freeze({
  onMouseDown: handlePreventLostFocus,
  onTouchStart: handlePreventLostFocus,
});

export default preventLostFocus;
