import Typography from '@mui/material/Typography';
import differenceInDays from 'date-fns/differenceInDays';
import { VFC } from 'react';

import { DateRange } from 'src/components/Calendar';
import { I18n } from 'src/components/I18n';
import styled from 'styled-components/macro';

type Props = { dates: DateRange };

const StyledText = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    justify-content: center;
    display: flex;
`;

export const NightsCount: VFC<Props> = ({ dates: [start, end] }) => (
  <StyledText>
    {start && end ? (
      <I18n
        id="CAIRBNB_CALENDAR.STAY_DURATION"
        values={{ nights: differenceInDays(end, start) }}
      />
    ) : (
      <I18n id="CAIRBNB_CALENDAR.SELECT_DATES" />
    )}
  </StyledText>
);
