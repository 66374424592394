import { Breakpoint } from '@mui/system';
import { CSSObject } from 'styled-components';

import { ThemeProps } from 'src/types';

type _Variable = number | string;

type Variable<P extends ThemeProps> = _Variable | ((props: P) => _Variable);

type CssVar<T extends string> = `var(--${T})`;

type VariablesObj<T extends string> = { [Key in T]: CssVar<Key> };

type Variables<P extends ThemeProps> = Record<string, Variable<P>>;

type BreakpointsObj<P extends ThemeProps> = Partial<
  Record<Breakpoint, Variables<P>>
>;

type _O<T> = {
  [key in keyof T]: keyof T[key];
};

type ExtractVariableName<
  T extends Partial<Record<string, Record<string, any>>>
> = _O<T>[keyof _O<T>];

const getVars = <T extends string>(breakpointsObj: BreakpointsObj<any>) => {
  const breakpointsValues = Object.values(breakpointsObj);

  const variables: VariablesObj<T> = {} as any;

  for (let i = breakpointsValues.length; i--; ) {
    const v = Object.keys(breakpointsValues[i]);

    for (let i = v.length; i--; ) {
      const item = v[i] as T;

      if (!variables[item]) variables[item] = `var(--${item})`;
    }
  }

  return variables;
};

const mediaVariables =
  <P extends ThemeProps, O extends BreakpointsObj<P>>(
    breakpointsObj: O,
    getCSSObject?: (
      v: VariablesObj<
        ExtractVariableName<O> extends string ? ExtractVariableName<O> : never
      >,
      props: P
    ) => CSSObject
  ) =>
  (props: P) => {
    const { breakpoints } = props.theme;

    const vars = breakpoints.keys.reduce((acc, breakpoint) => {
      const variables = breakpointsObj[breakpoint];

      if (!variables) return acc;

      return (
        Object.keys(variables).reduce((acc, variableName) => {
          const variable = variables[variableName];

          return `${acc}--${variableName}:${
            typeof variable === 'function' ? variable(props) : variable
          };`;
        }, `${acc}${breakpoints.up(breakpoint)}{`) + '}'
      );
    }, '');

    return getCSSObject
      ? [vars, getCSSObject(getVars(breakpointsObj), props)]
      : vars;
  };

export default mediaVariables;
