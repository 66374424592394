import { FC } from 'react';
import styled from 'styled-components/macro';

import { Title, Info, InfoBlock } from 'src/components/shared/InfoBlock';
import { I18n } from 'src/components/I18n';
import { getIconByFacilityId } from 'src/modules/facilities';
import { toI18nKey } from 'src/modules/helpers';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { Icon } from './common';
import { FacilitiesItem } from './FacilitiesItem';

const InfoBlockWrapper = styled.li`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
`;

const StyledInfoBlock = styled(InfoBlock)`
  ${Title} {
    font-size: 16px;
  }

  ${Info} {
    padding-left: 25px;
  }
`;

interface FacilitiesListProps {
  items: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
}

export const FacilitiesList: FC<FacilitiesListProps> = ({ items }) => (
  <>
    {items.map((facilitiesItem) => {
      const iconClass = getIconByFacilityId(facilitiesItem.id);

      return (
        <InfoBlockWrapper key={facilitiesItem.name}>
          <StyledInfoBlock
            title={
              <I18n id={toI18nKey(facilitiesItem.name, 'FACILITY_GROUPS')} />
            }
            iconElement={<Icon className={iconClass} />}
          >
            <FacilitiesItem facilities={facilitiesItem.facilities} />
          </StyledInfoBlock>
        </InfoBlockWrapper>
      );
    })}
  </>
);
