import { Action, Dispatch, MiddlewareAPI } from 'redux';
import { listenForHistoryChange } from 'redux-location-state';
import { REHYDRATE } from 'redux-persist';
import _get from 'lodash/get';

import history from 'src/modules/history';
import { ReduxState } from 'src/store/reducers';

const SPOTIFY_LOCATION_POP_ACTION_TYPE = 'REDUX-LOCATION-POP-ACTION';

const listenLocationForSyncMiddleware =
  (locationMiddleware: any) =>
  (store: MiddlewareAPI<Dispatch, ReduxState>) =>
  (next: Dispatch) =>
  (action: Action) => {
    const state = store.getState();
    const isRehydrated = _get(state, ['_persist', 'rehydrated']);

    if (isRehydrated) {
      locationMiddleware(store)(next)(action);
    } else {
      next(action);
    }
    /** listen to history change after persist rehydrated */
    if (action.type === REHYDRATE) {
      /**
       * We need setTimeout to defer connection of spotify's listener
       * to make sure that listener of connected-react-router is connected first
       */
      setTimeout(() => {
        listenForHistoryChange(store, history);
      }, 0);

      /**
       * call spotify sync to map location query to state,
       * before url will be rewrited by state's value
       * as a result of connected-react-router initial action
       */
      next({
        type: SPOTIFY_LOCATION_POP_ACTION_TYPE,
        payload: history.location,
      });
    }
  };

export default listenLocationForSyncMiddleware;
