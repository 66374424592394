import { RouteProps } from 'react-router-dom';

import {
  CheckoutPage,
  ConfirmationPage,
  ContactPage,
  HomePage,
  NoMatchPage,
  NotAuthenticatedPage,
  SearchPage,
  HotelDetailsPage,
  StaticPage,
  FrequentlyAskedQuestionsPage,
  AboutUsPage,
  ImpressumPage,
  AccountPage,
  ResetPasswordPage,
  ThankYouPage,
  UnsubscribePage,
  AuthPage,
  SubscriptionPage,
  AffiliateDashboardPage
} from 'src/components/pages';

import { authPath, AUTH_TYPE_PARAM, EAuth } from '../Auth';

type Props = 'exact' | 'path' | 'component' | 'render';
export type PickedRouteProps = Pick<RouteProps, Props>;
export const routes: PickedRouteProps[] = [
  {
    exact: true,
    path: '/',
    component: HomePage,
  },
  {
    exact: true,
    path: '/search',
    component: SearchPage,
  },
  {
    exact: true,
    path: '/checkout',
    component: CheckoutPage,
  },
  {
    exact: true,
    path: '/account/:sectionId?',
    component: AccountPage,
  },
  {
    exact: true,
    path: '/confirmation',
    component: ConfirmationPage,
  },
  {
    exact: true,
    path: '/hotel/:hotelId',
    component: HotelDetailsPage,
  },
  {
    exact: true,
    path: '/hotel-name/:hotelId',
    render: (props) => <HotelDetailsPage isStatic={true} {...props} />,
  },
  {
    exact: true,
    path: '/(terms-and-conditions|privacy-policy)',
    component: StaticPage,
  },
  {
    exact: true,
    path: '/contact',
    component: ContactPage,
  },
  {
    exact: true,
    path: '/affiliate-dashboard',
    component: AffiliateDashboardPage,
  },
  {
    exact: true,
    path: '/about',
    component: AboutUsPage,
  },
  {
    exact: true,
    path: '/impressum',
    component: ImpressumPage,
  },
  {
    exact: true,
    path: '/faq',
    component: FrequentlyAskedQuestionsPage,
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetPasswordPage,
  },
  {
    exact: true,
    path: authPath,
    component: AuthPage,
  },
  {
    exact: true,
    path: `/:${AUTH_TYPE_PARAM}(${EAuth.SIGN_UP})/inviteLink/:token`,
    component: AuthPage,
  },
  {
    exact: true,
    path: '/403',
    component: NotAuthenticatedPage,
  },
  {
    exact: true,
    path: '/newsletter/thankyou',
    component: ThankYouPage,
  },
  {
    exact: true,
    path: '/unsubscribe',
    component: UnsubscribePage,
  },
  {
    exact: true,
    path: '/subscriptions-management/:token',
    component: SubscriptionPage,
  },
  {
    component: NoMatchPage,
  },
];
