import { FC, ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = { title: ReactNode };

export const ListItem: FC<Props> = ({ title, children }) => (
  <Stack spacing={2}>
    <Typography
      variant="overline"
      color="text.secondary"
      position="relative"
      display="flex"
      alignItems="center"
    >
      {title}
    </Typography>
    <Stack spacing={5}>{children}</Stack>
  </Stack>
);
