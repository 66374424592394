import { Room } from 'src/store/search/reducers';

export const ROOMS_SPLITTER = ';';
export const CHILDREN_SPLITTER = ',';
export const ADULTS_CHILDREN_SPLITTER = '-';

const serialize = (rooms: Room[]) =>
  rooms
    .map(
      (room) =>
        `${room.adults}${ADULTS_CHILDREN_SPLITTER}${room.children
          .map(({ age }) => age)
          .join(CHILDREN_SPLITTER)}`
    )
    .join(ROOMS_SPLITTER);

const parse = (s: string): Room[] =>
  s.split(ROOMS_SPLITTER).map((r) => {
    const separateAges = r.split(ADULTS_CHILDREN_SPLITTER);

    const children = separateAges[1];

    return {
      adults: parseInt(separateAges[0], 10),
      children: children
        ? children
            .split(CHILDREN_SPLITTER)
            .map((v) => ({ age: parseInt(v, 10) }))
        : [],
    };
  });

const roomConverter = {
  serialize,
  parse,
};

export default roomConverter;
