export const Marker = () => (
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 16.4C0.5 6.73141 7.86059 0.499969 16 0.499969C24.1394 0.499969 31.5 6.73141 31.5 16.4C31.5 19.5852 30.2183 23.1128 27.5837 26.9968C25.0042 30.7996 21.1478 34.9141 16 39.3415C10.8522 34.9141 6.99584 30.7996 4.41629 26.9968C1.78168 23.1128 0.5 19.5852 0.5 16.4Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M12.667 17.6666C13.7737 17.6666 14.667 16.7733 14.667 15.6666C14.667 14.56 13.7737 13.6666 12.667 13.6666C11.5603 13.6666 10.667 14.56 10.667 15.6666C10.667 16.7733 11.5603 17.6666 12.667 17.6666ZM20.667 13.6666H15.3337V18.3333H10.0003V12.3333H8.66699V22.3333H10.0003V20.3333H22.0003V22.3333H23.3337V16.3333C23.3337 14.86 22.1403 13.6666 20.667 13.6666Z"
      fill="black"
    />
  </svg>
);
