type Var<T extends string> = `@${T}`;

type Colors = Record<string, string>;

type Theme<V extends Colors> = Record<
  string,
  String | Var<keyof V extends string ? keyof V : never> | number
>;

const createMainTheme = <C extends Colors, T extends Theme<C>>(
  colors: C,
  theme: keyof T extends Exclude<keyof T, keyof C> ? T | Theme<C> : never
) => ({
  ...colors,
  ...Object.keys(theme).reduce<{
    readonly [key in keyof T]: T[key] extends Var<infer K> ? C[K] : T[key];
  }>((acc, key: keyof typeof theme) => {
    const colorName = /^@(?<var>.*)$/.exec(theme[key] as string)?.groups
      ?.var as undefined | keyof C;

    if (colorName) {
      return { ...acc, [key]: colors[colorName] };
    } else {
      return { ...acc, [key]: theme[key] };
    }
  }, {} as any),
});

export default createMainTheme;
