// TODO Change icons and add other groups to list
const hotelFacilitiesGroups: { [key: number]: string } = {
  1: 'icon-beach',
  2: 'icon-entertainment',
  3: 'icon-food-drinks',
  4: 'icon-guests4',
  5: 'icon-general',
  6: 'icon-hotel-services',
  7: 'icon-internet',
  8: 'icon-outdoors',
  9: 'icon-parking',
  10: 'icon-pets',
  11: 'icon-reception-services',
  12: 'icon-room-facilities',
  13: 'icon-wellness',
  14: 'icon-spa-wellness',
};

export const getIconByFacilityId = (id: number) => hotelFacilitiesGroups[id];
