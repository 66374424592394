import { useState, useEffect } from 'react';

export const useFirstRender = () => {
  const [isFirstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return isFirstRender;
};
