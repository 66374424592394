const joinBy = (arr: JSX.Element[], separator: JSX.Element) => {
  const l = arr.length - 1;

  if (!l) return arr;

  const _arr: JSX.Element[] = [];

  for (let i = 0; i < l; i++) {
    _arr.push(arr[i], { ...separator, key: `__${i}__` });
  }

  _arr.push(arr[l]);

  return _arr;
};

export default joinBy;
