import { combineEpics } from 'redux-observable';

import { sendContactForm } from 'src/store/contactForm/epics';
import {
  userLogin,
  userLogout,
  userUpdate,
  userSignUp,
  getBookings,
  cancelBooking,
  getUserInfo,
  resetUserPassword,
  changeUserPassword,
  updateUserConsents,
  sendResetPasswordLink,
  unsubscribeUserByToken,
  subscribeUserByToken,
} from 'src/store/user/epics';
import { handleLocaleChange } from 'src/store/intl/epics';
import {
  handleHotelRoomsRequest,
  handleHotelRequest,
} from 'src/store/hotel/epics';
import {
  handleCheckoutCreateRequest,
  handleCheckoutInfoRequest,
  handleCheckoutBookRequest,
  handleCheckoutCheckRequest,
  handlePackagePriceUpdate,
  handlePayAtHotelHoldRequest,
  handleStripePaymentSecretRequest,
  handleSetPackageAndProceedToCheckout,
  handlePayAtHotelBookRequest,
  handlePaybyrd,
} from 'src/store/checkout/epics';
import { handleConfirmationInfoRequest } from 'src/store/confirmation/epics';
import { initApp } from 'src/store/app/epics';

export default combineEpics(
  userLogin,
  // @ts-expect-error
  userLogout,
  userUpdate,
  userSignUp,
  getBookings,
  cancelBooking,
  getUserInfo,
  resetUserPassword,
  changeUserPassword,
  sendResetPasswordLink,
  updateUserConsents,
  unsubscribeUserByToken,
  subscribeUserByToken,
  handleLocaleChange,
  handleCheckoutCreateRequest,
  handleCheckoutInfoRequest,
  handleCheckoutCheckRequest,
  handleHotelRoomsRequest,
  handleHotelRequest,
  handlePackagePriceUpdate,
  handleCheckoutBookRequest,
  handlePaybyrd,
  handleStripePaymentSecretRequest,
  handlePayAtHotelHoldRequest,
  handleSetPackageAndProceedToCheckout,
  handlePayAtHotelBookRequest,
  handleConfirmationInfoRequest,
  sendContactForm,
  initApp
);
