import ModalUnstyled from '@mui/base/ModalUnstyled';
import Fade from '@mui/material/Fade';
import { FC } from 'react';
import styled from 'styled-components/macro';

export type CModalContainerProps = { onClose?: () => void; isOpen: boolean };

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
`;

const CModalContainer: FC<CModalContainerProps> = ({
  children,
  onClose,
  isOpen,
}) => (
  <StyledModal
    open={isOpen}
    hideBackdrop
    closeAfterTransition
    disableRestoreFocus
    disableEnforceFocus
    onClose={onClose}
  >
    <Fade in={isOpen} mountOnEnter unmountOnExit>
      <StyledContainer>
        <StyledBackdrop onClick={onClose} />
        {children}
      </StyledContainer>
    </Fade>
  </StyledModal>
);

export default CModalContainer;
