import styled from 'styled-components/macro';

export const CONTAINER_MAX_WIDTH = 1260;

type Props = { disableMargin?: boolean; $truncated?: boolean };

const Container = styled.div<Props>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    --gutter: ${({ theme }) => theme.spacing(6)};
    --width: 1100px;
    ${({ theme, disableMargin }) =>
      !disableMargin && { marginBottom: theme.spacing(12) }}
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    --gutter: ${({ theme }) => theme.spacing(10)};
    ${({ theme, disableMargin }) =>
      !disableMargin && { marginBottom: theme.spacing(16) }}
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    --width: ${({ $truncated }) => ($truncated ? 1068 : CONTAINER_MAX_WIDTH)}px;
  }

  padding-left: var(--gutter);
  padding-right: var(--gutter);
  max-width: calc(var(--gutter) * 2 + var(--width));
`;

export default Container;
