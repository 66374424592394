import { HTMLProps } from 'react';

import type {
  DefaultAppMessagesTypeKey,
  I18nErrorType,
} from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { KeyValueType } from 'src/modules/helpers';

export enum ErrorCode {
  NO_ERROR = 0, // "No errors"
  // HOTEL_IS_NULL = 1, // "Hotel is null"
  SIGNUP_OR_SIGNIN = 2, // "User should be signin"
  // HOTEL_ALREADY_ADDED = 3, // "Hotel is already added"
  SOMETHING_WRONG = 4, // "Something wrong"
  // CANT_FIND_HOTEL = 5, // "Can't find hotel"
  PASSWORD_OR_EMAIL_EMPTY = 6, // "Password on email is empty"
  CANT_FIND_USER = 7, // "Can't find user"
  WRONG_PASSWORD = 8, // "Wrong password"
  // REQUEST_NULL = 9, // "Request is null"
  LOWER_CASE_CHAR_IN_PASSWORD = 11, // "Should be one lower case symbol"
  UPPER_CASE_CHAR_IN_PASSWORD = 12, // "Should have one upper case symbol"
  GREATER_SYMBOLS_COUNT = 13, // "Symbols count should be greater than"
  NUMERIC_VALUE_IN_PASSWORD = 14, // "Symbols count should be greater than"
  SPECIAL_CASE_CHARACTER_IN_PASSWORD = 15, // "Symbols count should be greater than"
  EMAIL_NOT_VALID = 16, // "Email not valid"
  USER_ALREADY_EXIST = 17, // "User already exists"
  // UNKNOWN_ERROR = 18, // "Unknown error"
  // EMPTY_OFFER_KEY = 19, // "OfferKey is empty"
  PASSWORD_EMPTY = 20, // "Password is empty"
  USER_NOT_FOUND = 21, // "User not found"
  PASSWORDS_DONOT_MATCH = 22, // "Passwords do not match"
  WRONG_CONFIRMATION_CODE = 30, // "Wrong confirmation code"
  WRONG_REFERRAL_CODE = 32, // "Wrong confirmation code"
  USER_DELETED = 33, // "Wrong confirmation code"
}

const ErrorCodeMapping: Record<ErrorCode, I18nErrorType> = {
  [ErrorCode.NO_ERROR]: 'NO_ERROR',
  // [ErrorCode.HOTEL_IS_NULL]: 'HOTEL_IS_NULL',
  [ErrorCode.SIGNUP_OR_SIGNIN]: 'SIGNUP_OR_SIGNIN',
  // [ErrorCode.HOTEL_ALREADY_ADDED]: 'HOTEL_ALREADY_ADDED',
  [ErrorCode.SOMETHING_WRONG]: 'SOMETHING_WRONG',
  // [ErrorCode.CANT_FIND_HOTEL]: 'CANT_FIND_HOTEL',
  [ErrorCode.PASSWORD_OR_EMAIL_EMPTY]: 'PASSWORD_OR_EMAIL_EMPTY',
  [ErrorCode.CANT_FIND_USER]: 'CANT_FIND_USER',
  [ErrorCode.WRONG_PASSWORD]: 'WRONG_PASSWORD',
  // [ErrorCode.REQUEST_NULL]: 'REQUEST_NULL',
  [ErrorCode.LOWER_CASE_CHAR_IN_PASSWORD]: 'LOWER_CASE_CHAR_IN_PASSWORD',
  [ErrorCode.UPPER_CASE_CHAR_IN_PASSWORD]: 'UPPER_CASE_CHAR_IN_PASSWORD',
  [ErrorCode.GREATER_SYMBOLS_COUNT]: 'GREATER_SYMBOLS_COUNT',
  [ErrorCode.NUMERIC_VALUE_IN_PASSWORD]: 'NUMERIC_VALUE_IN_PASSWORD',
  [ErrorCode.SPECIAL_CASE_CHARACTER_IN_PASSWORD]:
    'SPECIAL_CASE_CHARACTER_IN_PASSWORD',
  [ErrorCode.EMAIL_NOT_VALID]: 'EMAIL_NOT_VALID',
  [ErrorCode.USER_ALREADY_EXIST]: 'USER_ALREADY_EXIST',
  // [ErrorCode.UNKNOWN_ERROR]: 'UNKNOWN_ERROR',
  // [ErrorCode.EMPTY_OFFER_KEY]: 'EMPTY_OFFER_KEY',
  [ErrorCode.PASSWORD_EMPTY]: 'PASSWORD_EMPTY',
  [ErrorCode.USER_NOT_FOUND]: 'USER_NOT_FOUND',
  [ErrorCode.PASSWORDS_DONOT_MATCH]: 'PASSWORDS_DONOT_MATCH',
  [ErrorCode.WRONG_CONFIRMATION_CODE]: 'WRONG_CONFIRMATION_CODE',
  [ErrorCode.WRONG_REFERRAL_CODE]: 'WRONG_REFERRAL_CODE',
  [ErrorCode.USER_DELETED]: 'USER_DELETED',
};

export const getErrorCodeNameBy = (
  errorCode: ErrorCode
): DefaultAppMessagesTypeKey =>
  `USER.FORM.VALIDATION.ERROR.NAME.${ErrorCodeMapping[errorCode]}`;

export type ServerErrorCodesType = KeyValueType<number>;

export type MappedErrorMessageType = Record<string, DefaultAppMessagesTypeKey>;

// Enum for the new error messages
enum ServerErrorCodes {
  FIELD_REQUIRED = 160,
}

export const serverErrorTranslations: { [key: number]: string } = {
  [ServerErrorCodes.FIELD_REQUIRED]: 'GENERAL_FIELD.REQUIRED',
};

export type FormFieldProps<T = HTMLInputElement> = Omit<
  HTMLProps<T>,
  'ref' | 'as'
> & {
  name: string;
};
