import 'styled-components/macro';

import { useRef, useState, useEffect, VFC } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import styled from 'styled-components';
import { lighten } from 'polished';
import { Loader, useAutocompleteService } from 'google-maps-js-api-react';
import { useFormContext } from 'react-hook-form';
import { useCombobox } from 'downshift';
import TextField from '@mui/material/TextField';
import { useSWRConfig } from 'swr';

import { I18n } from 'src/components/I18n';
import joinBy from 'src/modules/joinBy';
import { SearchParamsState } from 'src/store/search/reducers';
import CDrawer from 'src/components/common/CDrawer';
import { Button } from 'src/components/common/WrappedButtons';
import handleSetValue from 'src/modules/handleSetValue';
import preventLostFocus from 'src/modules/preventLostFocus';
import useDebouncedSWR from 'src/hooks/swr/useDebouncedSWR';
import { POINTER_FINE } from 'src/configs/muiTheme';

import { ListItem } from '../../ListItem';
import { SearchPanelPopover } from '../../SearchPanelPopover';
import { SearchPanelInput } from '../../SearchPanelInput';
import { FieldsName, SearchPanelInputProps } from '../..';

import { generateVariants } from './utils';
import {
  // ClicktripPredictionVariant,
  GooglePredictionVariant,
} from './variants';

const StyledButton = styled(Button)`
  margin-left: 8px;
  padding: 4px;
  font-size: 16px;
  background: ${({ theme }) => theme.palette.divider};
  ${POINTER_FINE} {
    &:hover {
      background: ${({ theme }) => lighten(0.02, theme.palette.divider)};
    }
  }
  @media (hover: none) {
    &:hover {
      background: ${({ theme }) => theme.palette.divider};
    }
  }
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.text.primary};
  > svg {
    font-size: 1em;
  }
`;

const MobileInputWrapper = styled.div<{ spacing: number }>`
  margin-bottom: ${({ theme, spacing }) => theme.spacing(spacing)};
`;

export const DestinationChooser: VFC<
  SearchPanelInputProps & { className?: string }
> = ({ isMobile, openDefault, handleIsOpen, className }) => {
  const methods = useFormContext<SearchParamsState>();

  const defaultValue = methods.control._defaultValues.destination?.q || '';

  const [inputValue, setInputValue] = useState(defaultValue);

  const { cache } = useSWRConfig();

  const [wasOpened, setWasOpened] = useState(
    () => openDefault || (cache as Map<string, any>).has(`${inputValue}__gs`)
  );

  const { getPlacePredictions } = useAutocompleteService();

  const [isLongLoading, setIsLongLoading] = useState(false);

  const { data } = useDebouncedSWR(
    wasOpened && inputValue ? `${inputValue}__gs` : null,
    () => getPlacePredictions({ input: inputValue }),
    {
      debounce: 500,
      onLoadingSlow: () => {
        setIsLongLoading(true);
      },
      onSuccess: () => {
        setIsLongLoading(false);
      },
      loadingTimeout: 300,
    }
  );

  const placePredictions = data?.predictions;

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    openMenu,
    closeMenu,
    isOpen,
  } = useCombobox({
    items: placePredictions || [],
    onIsOpenChange:
      handleIsOpen && (({ isOpen }) => handleIsOpen(isOpen ?? false)),
    itemToString: (item) => item?.description || '',
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: isMobile,
            inputValue: state.selectedItem?.description || '',
          };

        default:
          return changes;
      }
    },
    inputValue,
    defaultSelectedItem: {
      description: defaultValue,
    } as google.maps.places.AutocompletePrediction,
    onInputValueChange: (v) => setInputValue(v.inputValue!),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        handleSetValue(
          methods,
          FieldsName.DESTINATION,
          {
            q: selectedItem.description,
            placeId: selectedItem.place_id,
          },
          (a, b) => a.placeId === b.placeId
        );
      }
    },
  });

  const anchorElRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const inputLabelRef = useRef<HTMLLabelElement>(null);

  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDefault && !isMobile && !isOpen) {
      void Loader.completion.then(() => {
        inputRef.current?.focus();

        openMenu();
      });
    }
  }, []);

  const variants = [];

  if (
    inputValue &&
    (!placePredictions || placePredictions.length || isLongLoading)
  ) {
    variants.push(
      generateVariants(
        <I18n id="HOME_PAGE.SEARCH_PANEL.DESTINATION_FIELD.DESTINATIONS" />,
        'destinations',
        isLongLoading ? undefined : placePredictions,
        (item, index) => (
          <GooglePredictionVariant
            key={item?.place_id ?? index}
            item={item}
            {...(item ? getItemProps({ item, index }) : {})}
          />
        )
      )
    );
  }

  const cleanButton = (
    <Fade in={isOpen && Boolean(inputValue)}>
      <StyledButton
        variant="contained"
        size="small"
        color="buttonSecondary"
        onClick={() => setInputValue('')}
        tabIndex={-1}
        {...preventLostFocus}
      >
        <CloseIcon />
      </StyledButton>
    </Fade>
  );

  const formSpacing = 6;

  const modalContent = (
    <Stack
      spacing={formSpacing}
      {...getMenuProps({}, { suppressRefError: true })}
    >
      {joinBy(
        variants.map((props) => <ListItem {...props} />),
        <Divider />
      )}
    </Stack>
  );

    const handleOpen = () => {
      if (!isOpen) {
        if (!wasOpened) {
          void Loader.load();

          setWasOpened(true);
        }

        openMenu();
      }
    };

  return (
    <div ref={anchorElRef} className={className}>
      <div {...(isMobile ? {} : getComboboxProps())}>
        <SearchPanelInput
          focused={isOpen}
          InputLabelProps={{ ref: inputLabelRef }}
          {...(isMobile
            ? { children: inputValue, onClick: handleOpen }
            : {
                ...getInputProps({
                  refKey: 'inputRef',
                  ref: inputRef,
                  onClick: handleOpen,
                }),
                InputProps: {
                  endAdornment: cleanButton,
                },
              })}
          fullWidth
          ref={ref}
          label={
            <I18n id="HOME_PAGE.SEARCH_PANEL.DESTINATION_FIELD.PLACEHOLDER" />
          }
        />
      </div>
      {isMobile ? (
        <CDrawer
          open={isOpen}
          header={
            <I18n id="HOME_PAGE.SEARCH_PANEL.DESTINATION_FIELD.PORTAL_HEADER" />
          }
          onClose={closeMenu}
          SlideProps={{
            onEntered() {
              const el = inputRef.current;

              if (el) {
                const end = el.value.length;

                el.setSelectionRange(end, end);
              }
            },
          }}
        >
          <MobileInputWrapper
            spacing={formSpacing}
            {...getComboboxProps({}, { suppressRefError: true })}
          >
            <TextField
              variant="outlined"
              fullWidth
              label={false}
              autoFocus
              InputProps={{
                endAdornment: cleanButton,
              }}
              {...getInputProps(
                {
                  refKey: 'inputRef',
                  ref: inputRef,
                },
                { suppressRefError: true }
              )}
            />
          </MobileInputWrapper>
          {modalContent}
        </CDrawer>
      ) : (
        <SearchPanelPopover
          anchorEl={anchorElRef.current}
          open={isOpen && Boolean(variants.length)}
          css={`
            width: 600px;
          `}
          ref={popoverRef}
          arrowTargetRef={inputLabelRef}
        >
          {modalContent}
        </SearchPanelPopover>
      )}
    </div>
  );
};
