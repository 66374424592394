import { createTransform } from 'redux-persist';
import _omit from 'lodash/omit';
import update from 'immutability-helper';

import {
  SearchParamsState,
  searchParamsState,
} from 'src/store/search/reducers';
import { isValidDates } from 'src/modules/isValidDates';

export const searchParamsTransform = createTransform(
  // transform state on its way to being serialized and persisted
  (inboundState: any) => _omit(inboundState, ['es', 'k']) as any,
  // transform state being rehydrated
  (outboundState: SearchParamsState) => {
    const dates = (
      outboundState.dates as unknown as [string, string] | undefined
    )?.map((item) => new Date(item));
    const datesValue = isValidDates(dates as [Date, Date])
      ? dates
      : searchParamsState.dates;

    return update(outboundState, {
      dates: {
        $set: datesValue!,
      },
    });
  },
  // define which reducers this transform gets called for
  { whitelist: ['searchParams'] }
);
