import { v4 as uuid } from 'uuid';

import { Image } from 'src/types';

const getImagePlaceholder = (): Image => ({
  url: '',
  id: uuid(),
});

export const getPreviewThumbnails = (images: Image[], itemsCount: number) => {
  const imagesForPreview = images.slice(0, itemsCount);
  const placeholdersCount = itemsCount - imagesForPreview.length;

  return [
    ...imagesForPreview,
    ...Array.from(Array(placeholdersCount)).map(getImagePlaceholder),
  ];
};
