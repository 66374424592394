import { VFC } from 'react';

import { getThemeColorVariableName } from '../modules/themeColorVariablesMixin';

interface LogoIconProps {
  isContrast?: boolean;
}

const LogoIcon: VFC<LogoIconProps> = ({ isContrast = false }) => {
  const color = isContrast ? '#081F2D' : '#FFF';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="194"
      height="18"
      viewBox="0 0 194 18"
      fill="none"
    >
      <g>
        <mask
          id="mask0_1596_21"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="194"
          height="18"
        >
          <path d="M193.328 0H0V18H193.328V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1596_21)">
          <mask
            id="mask1_1596_21"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="192"
            height="16"
          >
            <path
              d="M192.234 1.20703H1.20688V16.957H192.234V1.20703Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_1596_21)">
            <mask
              id="mask2_1596_21"
              maskUnits="userSpaceOnUse"
              x="1"
              y="1"
              width="192"
              height="16"
            >
              <path
                d="M192.234 1.20605H1.20688V16.9561H192.234V1.20605Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_1596_21)">
              <mask
                id="mask3_1596_21"
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="192"
                height="16"
              >
                <path
                  d="M192.234 1.20898H1.20688V16.959H192.234V1.20898Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask3_1596_21)">
                <path
                  d="M3.5395 8.02508H12.3656V9.93305H3.5395V8.02508ZM3.76015 15.0061H13.7631V16.9589H1.30844V1.24609H13.4199V3.19898H3.76015V15.0061ZM26.314 16.9589V1.24609H28.3243L39.6757 14.1531H38.6214V1.24609H41.0733V16.9589H39.0628L27.7114 4.05196H28.7656V16.9589H26.314ZM58.1989 16.9589V3.19898H52.3149V1.24609H66.5102V3.19898H60.6262V16.9589H58.1989ZM77.7616 16.9589V1.24609H84.4546C85.9583 1.24609 87.2414 1.46308 88.3038 1.89705C89.3661 2.33103 90.1833 2.95954 90.7554 3.78261C91.3275 4.60566 91.6135 5.58583 91.6135 6.72316C91.6135 7.86047 91.3275 8.84063 90.7554 9.66368C90.1833 10.4718 89.3661 11.0928 88.3038 11.5268C87.2414 11.9608 85.9583 12.1777 84.4546 12.1777H79.11L80.2132 11.1452V16.9589H77.7616ZM89.2354 16.9589L84.8714 11.2574H87.4948L91.9077 16.9589H89.2354ZM80.2132 11.3697L79.11 10.2698H84.3811C85.95 10.2698 87.1352 9.96298 87.9361 9.34943C88.7532 8.72093 89.1619 7.8455 89.1619 6.72316C89.1619 5.60079 88.7532 4.73285 87.9361 4.1193C87.1352 3.50575 85.95 3.19898 84.3811 3.19898H79.11L80.2132 2.07663V11.3697ZM101.557 16.9589L109.329 1.24609H111.756L119.552 16.9589H116.978L110.04 2.50313H111.02L104.082 16.9589H101.557ZM104.867 13.0307L105.529 11.235H115.188L115.899 13.0307H104.867ZM134.736 16.9589L127.185 1.24609H129.833L136.771 15.7468H135.251L142.238 1.24609H144.69L137.163 16.9589H134.736ZM157.691 8.02508H166.517V9.93305H157.691V8.02508ZM157.911 15.0061H167.914V16.9589H155.459V1.24609H167.571V3.19898H157.911V15.0061ZM180.464 16.9589V1.24609H182.917V15.0061H192.208V16.9589H180.464Z"
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
