import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { pick, stringifyUrl } from 'query-string';
import { QueryParamConfig } from 'use-query-params';

import {
  MAX_CHILDREN,
  MAX_GUESTS,
  MAX_ROOMS,
} from 'src/components/Panels/SearchPanel/choosers/RoomChooser/RoomChooserForm';
import { GOOGLE_PLACES_COUNTRY_TYPE, SortingType } from 'src/constants';
import { Room } from 'src/store/search/reducers';
import { SearchRequest } from 'src/models/search';
import roomConverter from 'src/modules/roomsConverter';

import { ESearchType } from './SearchPageProvider';

import { SearchPageQueryParams } from '.';

export const datesConverter: QueryParamConfig<[Date, Date] | undefined> = {
  encode: (dates) => dates?.map((item) => format(item, 'yyyyMMdd')),
  decode: (dates) => {
    if (!dates) {
      return undefined;
    }

    if (!Array.isArray(dates) || dates.length !== 2) {
      return undefined;
    }

    return dates.map((item) => parse(item!, 'yyyyMMdd', new Date())) as [
      Date,
      Date
    ];
  },
};

export const roomsConverter: QueryParamConfig<Room[] | undefined> = {
  encode: (rooms) =>
    rooms?.map(({ adults, children }) => {
      let acc = String(adults);

      if (children.length) {
        acc += `-${children.map(({ age }) => age).join(';')}`;
      }

      return acc;
    }),
  decode: (item) => {
    if (!item) {
      return undefined;
    }

    try {
      const rooms = Array.isArray(item) ? item : [item];

      if (rooms.length > MAX_ROOMS) {
        throw new Error();
      }

      return rooms.map((item) => {
        if (!item) {
          throw new Error();
        }

        const [adultsStr, childrenStr] = item.split('-');

        const adults = +adultsStr;

        let children: string[] = [];

        if (childrenStr) {
          children = childrenStr.split(';');

          if (
            children.length > MAX_CHILDREN ||
            isNaN(adults) ||
            adults + children.length > MAX_GUESTS
          ) {
            throw new Error();
          }
        }

        return {
          adults,
          children: children.map((age) => {
            if (Number.isNaN(age)) {
              throw new Error();
            }

            return { age: +age };
          }),
        };
      });
    } catch (err) {
      return undefined;
    }
  },
};

export const booleanConverter: QueryParamConfig<boolean | undefined> = {
  encode: (value) => (typeof value === 'boolean' ? value.toString() : value),
  decode: (value) => (value === undefined ? value : value === 'true'),
};

export const googleTypesToSearchType = (types: string[]) => {
  if (
    [
      'country',
      'administrative_area_level_1',
      'administrative_area_level_2',
    ].some((item) => types.includes(item))
  ) {
    return ESearchType.COUNTRY;
  }

  if (
    ['locality', 'administrative_area_level_3'].some((item) =>
      types.includes(item)
    )
  ) {
    return ESearchType.CITY;
  }

  return ESearchType.POI;
};

const getSortingParams = (sortingType: SortingType) => {
  const params = sortingType.split('-');

  return {
    's.prop': params[0],
    's.dir': params[1],
  };
};

type ParamsFromState = Omit<SearchPageQueryParams, 'placeId' | 'q'>;

export const getSearchRequestQuery = (
  geocoderRes: google.maps.GeocoderResult,
  searchType: ESearchType,
  {
    dates,
    rooms,
    sorting,
    name,
    stars,
    suitableTypes,
    distanceToPoint,
    priceFrom,
    priceTo,
    facilities,
    rating,
    businessTrip,
    hideSoldOut,
    propertyTypes,
  }: ParamsFromState
): Readonly<SearchRequest> => {
  const {
    geometry: { location, viewport },
  } = geocoderRes;

  const ne = viewport.getNorthEast();

  const sw = viewport.getSouthWest();

  const isCountry = geocoderRes.types.includes(GOOGLE_PLACES_COUNTRY_TYPE);

  return {
    // the first param (0) is for BACK-END (ask Misha Barabash)
    k: [
      0,
      ...(datesConverter.encode(dates as any) as any),
      roomConverter.serialize(rooms),
    ].join(':'),
    ...(isCountry
      ? { 'd.countryCode': geocoderRes.address_components[0].short_name }
      : {}),
    ...(searchType !== ESearchType.COUNTRY || !isCountry
      ? {
          'd.r': Math.min(
            searchType === ESearchType.CITY ? 25000 : 100000,
            Math.ceil(
              Math.max(
                google.maps.geometry.spherical.computeDistanceBetween(
                  location,
                  {
                    lat: ne.lat(),
                    lng: sw.lng(),
                  }
                ),
                google.maps.geometry.spherical.computeDistanceBetween(
                  location,
                  {
                    lat: sw.lat(),
                    lng: ne.lng(),
                  }
                ),
                google.maps.geometry.spherical.computeDistanceBetween(
                  location,
                  sw
                ),
                google.maps.geometry.spherical.computeDistanceBetween(
                  location,
                  ne
                ),
                searchType === ESearchType.CITY ? 500 : 5000
              )
            )
          ),
        }
      : {}),
    'd.dd': searchType !== ESearchType.COUNTRY,
    'd.lat': location.lat(),
    'd.lng': location.lng(),
    'f.name': name,
    'f.distanceToPointMax': distanceToPoint ?? undefined,
    'f.priceFrom': priceFrom ?? undefined,
    'f.priceTo': priceTo ?? undefined,
    'f.stars': stars,
    'f.suitableTypes': suitableTypes,
    'f.facilities': facilities,
    'f.rating': rating,
    'f.hideSoldOut': hideSoldOut ?? true,
    'f.propertyTypes': propertyTypes,
    tfb: businessTrip,
    utcOffset: format(new Date(), 'xxx'),
    ...getSortingParams(sorting!),
  };
};

export const pickSearchParams = (url: string) =>
  pick(url, ['k', 'd.lat', 'd.lng', 'd.countryCode'] as Array<
    keyof SearchRequest
  >);

export const withPaginationParams = (
  url: string,
  start: number,
  limit: number
) =>
  stringifyUrl({
    url,
    query: {
      'p.start': start,
      'p.limit': limit,
    },
  });
