export const GearIcon = () => (
  <svg height="202" width="202" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
      <g>
        <path d="m94.273 142.063-2.466 9.192a1.7 1.7 0 0 1 -2.084 1.2l-4-1.069a28.718 28.718 0 0 1 -5.861 7.629l2.069 3.579a1.7 1.7 0 0 1 -.623 2.321l-8.251 4.759a1.7 1.7 0 0 1 -2.324-.622l-2.069-3.58a28.764 28.764 0 0 1 -9.545 1.256l-1.071 3.991a1.7 1.7 0 0 1 -2.084 1.2l-9.2-2.463a1.7 1.7 0 0 1 -1.2-2.081l1.071-3.992a28.741 28.741 0 0 1 -7.635-5.85l-3.586 2.067a1.7 1.7 0 0 1 -2.324-.622l-4.763-8.242a1.7 1.7 0 0 1 .623-2.32l3.583-2.067a28.678 28.678 0 0 1 -1.258-9.534l-4-1.07a1.7 1.7 0 0 1 -1.2-2.081l2.466-9.192a1.7 1.7 0 0 1 2.084-1.2l4 1.07a28.7 28.7 0 0 1 5.862-7.629l-2.069-3.58a1.7 1.7 0 0 1 .623-2.321l8.25-4.758a1.7 1.7 0 0 1 2.324.622l2.069 3.579a28.764 28.764 0 0 1 9.541-1.255l1.071-3.991a1.7 1.7 0 0 1 2.084-1.2l9.2 2.463a1.7 1.7 0 0 1 1.2 2.081l-1.071 3.992a28.723 28.723 0 0 1 7.638 5.855l3.583-2.067a1.7 1.7 0 0 1 2.324.622l4.763 8.242a1.7 1.7 0 0 1 -.623 2.321l-3.583 2.066a28.681 28.681 0 0 1 1.257 9.535l4 1.069a1.7 1.7 0 0 1 1.205 2.075zm-30.491-16.383a13.906 13.906 0 1 0 9.889 17.02 13.993 13.993 0 0 0 -9.889-17.02z" fill="#4d9aed"/>
        <path d="m63.947 125.724a14.075 14.075 0 1 1 -17.336 9.949 14.061 14.061 0 0 1 17.336-9.949zm-.5-.134a13.561 13.561 0 1 1 -16.7 9.585 13.548 13.548 0 0 1 16.7-9.585z" fill="#3e90e7"/>
      </g>
      <g>
        <path d="m174.919 88.6-3.771 14.059a2.6 2.6 0 0 1 -3.187 1.837l-6.1-1.633a43.942 43.942 0 0 1 -8.97 11.674l3.158 5.463a2.6 2.6 0 0 1 -.952 3.55l-12.618 7.277a2.6 2.6 0 0 1 -3.554-.951l-3.159-5.466a44.017 44.017 0 0 1 -14.606 1.922l-1.635 6.1a2.6 2.6 0 0 1 -3.187 1.838l-14.074-3.77a2.6 2.6 0 0 1 -1.84-3.183l1.635-6.095a43.967 43.967 0 0 1 -11.687-8.96l-5.472 3.157a2.6 2.6 0 0 1 -3.554-.951l-7.285-12.6a2.6 2.6 0 0 1 .952-3.551l5.472-3.156a43.875 43.875 0 0 1 -1.924-14.59l-6.1-1.633a2.6 2.6 0 0 1 -1.84-3.183l3.771-14.058a2.6 2.6 0 0 1 3.186-1.837l6.1 1.633a43.927 43.927 0 0 1 8.969-11.674l-3.159-5.466a2.6 2.6 0 0 1 .952-3.55l12.618-7.277a2.6 2.6 0 0 1 3.554.951l3.159 5.466a44.014 44.014 0 0 1 14.606-1.922l1.635-6.1a2.6 2.6 0 0 1 3.187-1.838l14.074 3.767a2.6 2.6 0 0 1 1.839 3.183l-1.635 6.095a43.957 43.957 0 0 1 11.687 8.959l5.472-3.156a2.6 2.6 0 0 1 3.554.952l7.286 12.6a2.6 2.6 0 0 1 -.953 3.55l-5.472 3.156a43.869 43.869 0 0 1 1.924 14.59l6.1 1.633a2.6 2.6 0 0 1 1.844 3.188zm-45.682-25.794a20.838 20.838 0 1 0 14.832 25.509 20.99 20.99 0 0 0 -14.832-25.509z" fill="#34495e"/>
        <path d="m129.487 62.873a21.1 21.1 0 1 1 -26.006 14.909 21.071 21.071 0 0 1 26.006-14.909zm-.752-.2a20.325 20.325 0 1 1 -25.056 14.365 20.3 20.3 0 0 1 25.056-14.37z" fill="#273747"/>
      </g>
    </g>
  </svg>
)