import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { memo } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { useUrlWithCurrencyAndLocale } from 'src/hooks/useUrlWithCurrencyAndLocale';
import { L10nUrlMapperType } from 'src/modules/l10nUrlMappers';

type CLinkProps<T extends LinkProps['to']> = Omit<MuiLinkProps, 'href'> &
  (T extends string
    ? T extends `${'http' | 'https'}://${string}`
      ? { to: T }
      : LinkProps & { to: string }
    : LinkProps);

export const Link = <T extends LinkProps['to']>({
  to,
  children,
  ...rest
}: CLinkProps<T>) =>
  typeof to === 'string' && /^https?:\/\//.exec(to) ? (
    <MuiLink href={to} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </MuiLink>
  ) : (
    <MuiLink component={RouterLink} to={to} {...rest}>
      {children}
    </MuiLink>
  );

type L10nCLinkProps = CLinkProps<string> & {
  l10nUrlMapper?: L10nUrlMapperType;
};

export const L10nLink = memo<L10nCLinkProps>(
  ({ to, l10nUrlMapper, ...rest }) => {
    const toWithLocaleAndCurrency = useUrlWithCurrencyAndLocale(
      to,
      l10nUrlMapper
    );

    return <Link to={toWithLocaleAndCurrency} {...rest} />;
  }
);
