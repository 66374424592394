export const getCurrencySymbol = (currency: string) => {
  const getSymbol = (currency: string) => {
    const validCurrency = currency.length <= 3 ? currency : 'USD';
    try {
      return new Intl.NumberFormat('en', {
        currency: validCurrency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol',
      })
        .format(0)
        .replace(/\d/g, '')
        .trim();
    } catch (error) {
      if (error instanceof RangeError) {
        console.warn(`Invalid currency code: ${currency}, falling back to USD`);
        return new Intl.NumberFormat('en', {
          currency: 'USD',
          style: 'currency',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          currencyDisplay: 'narrowSymbol',
        })
          .format(0)
          .replace(/\d/g, '')
          .trim();
      } else {
        throw error;
      }
    }
  };

  return getSymbol(currency);
};
