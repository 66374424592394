import { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useMatchMedia from 'src/hooks/useMatchMedia';
import {
  RoomGroup,
  CtDomainMarketingPricePresentation,
} from 'src/models/hotels';
import { Image } from 'src/types';
import { I18n } from 'src/components/I18n';
import { HotelPreviewSize } from 'src/components/HotelPreview/type';
import { RoomFacility } from 'src/models/config';
import { setPackageAndProceedToCheckout } from 'src/store/checkout/actions';

import {
  StyledHotelPreview,
  BookRoomButton,
  NoImagePlaceholder,
  Label,
} from './CommonComponents';
import { RoomInfo } from './RoomInfo';
import { RoomFacilities } from './RoomFacilities';
import { PriceForStay } from './PriceForStay';

const StyledPriceForStay = styled(PriceForStay)`
  margin-top: auto;
  margin-bottom: 20px;
`;

const StyledRoomInfo = styled(RoomInfo)`
  margin-bottom: 20px;

  > div:first-child {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: ${(p) => p.theme.custom.blackMainColor};
  }
`;

export interface IRoomFacilitiesModal
  extends Omit<RoomGroup, 'creditCardType'> {
  facilities: RoomFacility[];
  images: Image[];
  pricePresentation: CtDomainMarketingPricePresentation;
}

export const RoomFacilitiesModal: FC<IRoomFacilitiesModal> = ({
  id,
  facilities,
  price,
  boarding,
  isRefundable,
  pricePresentation,
  images,
}) => {
  const theme = useTheme();
  const isDesktop = useMatchMedia(theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  const createCheckout = () => {
    dispatch(setPackageAndProceedToCheckout(id));
  };
  return (
    <Grid container spacing={{ xs: 1, md: 3, lg: 5 }}>
      <Grid
        item
        md={10}
        xs={16}
        borderRight={{
          md: `0.5px dashed ${theme.custom.greyAlternativeColor}`,
        }}
        borderBottom={{
          xs: `0.5px dashed ${theme.custom.greyAlternativeColor}`,
          md: 'unset',
        }}
        paddingBottom={{ xs: 3, md: 'unset' }}
      >
        <Grid container spacing={{ xs: 2, md: 5 }}>
          {isDesktop && (
            <Grid item lg={7} md={7} xs={16}>
              <StyledHotelPreview
                size={HotelPreviewSize.SMALL}
                images={images}
                withGallery={false}
                placeholder={<NoImagePlaceholder />}
              />
            </Grid>
          )}
          <Grid item lg={9} md={9} xs={16}>
            <Typography variant="h5" marginBottom={2}>
              <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.FACILITY_INFORMATION" />
            </Typography>
            <RoomFacilities facilities={facilities} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={16}>
        {!isDesktop && (
          <Label>
            <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.ROOM_TYPE" />
          </Label>
        )}
        <StyledRoomInfo
          foodType={boarding}
          isRefundable={isRefundable}
          tax={price.tax}
          fee={price.fee}
          pricePresentation={pricePresentation}
        />
        <StyledPriceForStay
          pricePerNight={price.pricePerNight}
          totalPrice={price.price}
        />
        <BookRoomButton btnType="primary" block={true} onClick={createCheckout}>
          <I18n id="HOTEL_DETAILS_PAGE.ROOMS.BOOK_NOW_BUTTON" />
        </BookRoomButton>
      </Grid>
    </Grid>
  );
};
