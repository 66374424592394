import { useEffect, useState } from 'react';

const useDebounce = <T extends number | string | boolean | null | undefined>(
  value: T,
  delay: number
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (debouncedValue !== value) {
      const handle = window.setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => window.clearTimeout(handle);
    }
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
