import { of, interval, iif, merge, EMPTY, Observable, concat } from 'rxjs';
import { mergeMap, catchError, switchMap, takeUntil } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import _random from 'lodash/random';
import format from 'date-fns/format';

import { ConfirmationActionTypes, TrackingActionTypes } from 'src/constants';
import {
  StartConfirmationPollingAction,
  stopPolling,
} from 'src/store/confirmation/actions';
import { observableApi$ } from 'src/modules/api';
import {
  CustomerOfferRequest,
  CustomerOfferResponse,
} from 'src/models/customers/manager';
import { mapResponse, mapErrorResponse } from 'src/store/epicHelpers';
import { TaxAndFee } from 'src/models/checkout';
import {
  getTransactionTotalPrice,
  getIncludedTaxes,
} from 'src/modules/helpers';
import { logEndpointError } from 'src/modules/logError';

import { ReduxState } from '../reducers';
import { getHotel } from '../hotel/actions';

const CONFIRMATION_POLLING_INTERVAL = 10000;

const ConfirmationAPI = {
  info$: (params: CustomerOfferRequest) =>
    observableApi$<CustomerOfferResponse>('/offers/reservationInfo', {
      method: 'get',
      params,
    }),
};

interface ITrackGHAConversion {
  hotelId: number;
  checkInDate: string;
  checkOutDate: string;
  currency: string;
  totalNights: number;
  salePrice: number;
  taxAndFees: TaxAndFee[];
  creditCardFee: number;
  transactionId: string;
}

const trackGHAConversion = ({
  hotelId,
  checkInDate,
  checkOutDate,
  currency,
  totalNights,
  salePrice,
  taxAndFees,
  creditCardFee,
  transactionId,
}: ITrackGHAConversion) => {
  const cachePreventionNumber = _random(1, 100000000);
  const includedTaxes = getIncludedTaxes(taxAndFees);
  const basePrice = Math.round((salePrice - includedTaxes) * 100) / 100;
  const transactionTotal = getTransactionTotalPrice(salePrice, creditCardFee);
  const dateFormat = encodeURIComponent('%Y-%m-%d');
  const url =
    `https://www.googletraveladservices.com/travel/clk/pagead/conversion/333234366/?label=HPA&guid=ON&script=0` +
    `&ord=${cachePreventionNumber}` +
    `&data=hct_partner_hotel_id%3D${hotelId}%3B` +
    `hct_base_price%3D${basePrice}%3B` +
    `hct_total_price%3D${transactionTotal}%3B` +
    `hct_currency_code%3D${currency}%3B` +
    `hct_checkin_date%3D${format(new Date(checkInDate), 'yyyy-MM-dd')}%3B` +
    `hct_checkout_date%3D${format(new Date(checkOutDate), 'yyyy-MM-dd')}%3B` +
    `hct_length_of_stay%3D${totalNights}%3B` +
    `hct_date_format%3D${dateFormat}%3B` +
    `hct_booking_xref%3D${transactionId}%3B` +
    `hct_ver%3D1.0.i`;

  const img = new Image();
  img.setAttribute('height', '1');
  img.setAttribute('width', '1');
  img.setAttribute('src', url);

  document.body.appendChild(img);
};

// TODO create epic for polling and epic for sending requests
export function handleConfirmationInfoRequest(
  action$: Observable<StartConfirmationPollingAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(ConfirmationActionTypes.START_CONFIRMATION_POLLING),
    mergeMap((params) => {
      const requestParams = { offerKey: params.offerKey };

      return interval(CONFIRMATION_POLLING_INTERVAL).pipe(
        switchMap(() =>
          ConfirmationAPI.info$(requestParams).pipe(
            mergeMap((resp) => {
              if (resp.isSuccess) {
                trackGHAConversion({
                  checkInDate: resp.checkInDate,
                  checkOutDate: resp.checkOutDate,
                  hotelId: resp.hotelId!,
                  salePrice: resp.package!.salePrice,
                  currency: 'EUR',
                  totalNights: resp.package!.totalNights,
                  taxAndFees: resp.package!.taxAndFees,
                  creditCardFee: resp.package!.creditCardFee,
                  transactionId: params.offerKey,
                });
              }

              const fn = () => {
                const { info } = state$.value.hotel;

                const checkIn = new Date(resp.checkInDate);
                const checkOut = new Date(resp.checkOutDate);

                return iif(
                  () => resp.isFinished,
                  merge(
                    mapResponse(
                      ConfirmationActionTypes.FETCH_CONFIRMATION_SUCCESS,
                      resp
                    ),
                    resp.isSuccess && info
                      ? of({
                          type: TrackingActionTypes.TRACK_BOOK,
                          payload: {
                            checkIn,
                            checkOut,
                            country: info.country,
                            hotelId: resp.hotelId!,
                            countryCode: info.countryCode,
                            city: info.city,
                            currency: 'EUR',
                            adultsCount: resp.package!.rooms.reduce(
                              (count, room) => count + room.adultsCount,
                              0
                            ),
                            childrenCount: resp.package!.rooms.reduce(
                              (count, room) => count + room.childrenAges.length,
                              0
                            ),
                            price: resp.package!.salePrice,
                            transactionId: params.offerKey,
                          },
                        })
                      : EMPTY,
                    resp.isSuccess
                      ? of({
                          type: TrackingActionTypes.TRACK_BING_CONVERSION,
                          payload: {
                            checkIn,
                            checkOut,
                            currency: 'EUR',
                            price: resp.package!.salePrice,
                            transactionId: params.offerKey,
                            hotelId: resp.hotelId!,
                            taxAndFees: resp.package!.taxAndFees,
                            creditCardFee: resp.package!.creditCardFee,
                            salePrice: resp.package!.salePrice,
                          },
                        })
                      : EMPTY,
                    of(stopPolling())
                  ),
                  mapResponse(
                    ConfirmationActionTypes.FETCH_CONFIRMATION_SUCCESS,
                    resp
                  )
                );
              };

              if (state$.value.hotel.info) return fn();

              return concat(
                of(getHotel({ hotelId: resp.hotelId!, silent: false })),
                fn()
              );
            }),
            catchError((err) => {
              void logEndpointError(
                { path: '/offers/reservationInfo', method: 'GET' },
                err
              );

              return mapErrorResponse(
                ConfirmationActionTypes.FETCH_CONFIRMATION_FAILURE,
                err
              );
            })
          )
        ),
        takeUntil(
          action$.pipe(
            // @ts-expect-error
            ofType(ConfirmationActionTypes.STOP_CONFIRMATION_POLLING)
          )
        )
      );
    })
  );
}
