import update, { extend } from 'immutability-helper';
import _isUndefined from 'lodash/isUndefined';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { RLSQueryParams } from 'redux-location-state';
import { Location } from 'history';

import { ReduxState } from 'src/store/reducers';
import {
  getQueryParamsByPathname,
  StateShapeFromQuery,
} from 'src/configs/queryParamMapSetup';

// add autovivification for objects to dynamically create prop in store if it doesn't exists
// https://github.com/kolodny/immutability-helper#autovivification
extend('$auto', (value, object) =>
  object ? update(object, value) : update({}, value)
);

const updateState = (
  state: ReduxState,
  queryParams: RLSQueryParams,
  query: StateShapeFromQuery
) =>
  Object.keys(queryParams).reduce((innerState, key) => {
    const paramConfig = queryParams[key];
    const { stateKey, initialState } = paramConfig;
    const fromQuery = _get(query, stateKey);
    const fromState = _get(state, stateKey);

    let value;
    if (!_isUndefined(fromQuery)) {
      value = fromQuery;
    } else if (!_isUndefined(fromState)) {
      value = fromState;
    } else {
      value = initialState;
    }

    const updateStateSpecObject = _set({}, stateKey.replace(/\./g, '.$auto.'), {
      $set: value,
    });

    return update(innerState, updateStateSpecObject);
  }, state);

export const mapLocationToState = (
  state: ReduxState,
  location: Location & { query: StateShapeFromQuery }
) => {
  const { query, pathname } = location;

  const queryParams = getQueryParamsByPathname(pathname);
  const newState = updateState(state, queryParams, query);
  return newState;
};
