import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import AppleIcon from '@mui/icons-material/Apple';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import useMatchMedia from 'src/hooks/useMatchMedia';

import GooglePlayIcon from '../../assets/icons/playstore.svg';

import {
  AppButton,
  AppButtonInfo,
  AppButtonStoreTitle,
  AppButtonTitle,
  CloseIconWrapper,
  ModalActions,
  ModalContainer,
  ModalDescription,
  ModalTitle
} from './styles';

const iOSLink = 'https://apps.apple.com/us/app/entravel/id6502831135';
const androidLink = 'https://play.google.com/store/apps/details?id=com.entravel.android';
const dayInMilliseconds = 86400000;
const storageKey = 'mobile-app-modal';

export const MobileAppModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    const now = new Date();

    const item = {
        isClosed: true,
        expiry: now.getTime() + dayInMilliseconds,
    };
    localStorage.setItem(storageKey, JSON.stringify(item));
  }

  const handleOpenAndroidApp = () => {
    window.open(androidLink);
  }

  const handleOpenIOSApp = () => {
    window.open(iOSLink);
  }

  useEffect(() => {
    const isClosedModalStore = localStorage.getItem(storageKey)
    const parsedStore = isClosedModalStore ? JSON.parse(isClosedModalStore) : null;

    if (!isMobile) {
      return;
    }

    if (!parsedStore || (parsedStore.isClosed === true && parsedStore.expiry < Date.now())) {
      handleOpen()
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
        <ModalTitle>
          Unlock Bigger Discounts!
        </ModalTitle>
        <ModalDescription>
          Why settle for great deals when you can get even better?🎉
        </ModalDescription>
        <ModalDescription>
          Our Entravel iOs and Android Apps bring you exclusive discounts that are only available in the app!
        </ModalDescription>
        <ModalDescription>
          Ready to save more?
        </ModalDescription>
        <ModalDescription>
          Download now and take your travels to the next level!🚀💸
        </ModalDescription>

        <ModalActions>
          <AppButton onClick={handleOpenIOSApp}>
            <AppleIcon fontSize='large' />
            <AppButtonInfo>
              <AppButtonTitle>
                Download on the
              </AppButtonTitle>
              <AppButtonStoreTitle>
                App Store
              </AppButtonStoreTitle>
            </AppButtonInfo>
          </AppButton>

          <AppButton onClick={handleOpenAndroidApp}>
            <img src={GooglePlayIcon} alt="google-play-button" />
            <AppButtonInfo>
              <AppButtonTitle>
                Get in on
              </AppButtonTitle>
              <AppButtonStoreTitle>
                Google Play
              </AppButtonStoreTitle>
            </AppButtonInfo>
          </AppButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}
