import { pathToRegexp } from 'path-to-regexp';
import { useLocation } from 'react-router';

export const isPageMatched = (match: string, currentPath: string) =>
  pathToRegexp(match).test(currentPath);

export const useMatchPage = (pathnameRegExp: string) => {
  const { pathname } = useLocation();

  return isPageMatched(pathnameRegExp, pathname);
};
