import styled, {
  css,
  FlattenSimpleInterpolation,
} from 'styled-components/macro';
import { rgba } from 'polished';

const horizontalDividerStyles = css`
  margin: 15px 0;
  border-top-width: 1px;
  width: 100%;
  height: 1px;
`;

const verticalDividerStyles = css`
  position: relative;
  top: -0.06em;
  margin: 0 5px;
  display: inline-block;
  border-right-width: 1px;
  width: 1px;
  height: 0.9em;
  vertical-align: middle;
`;

type DividerType = 'horizontal' | 'vertical';

interface CustomDividerProps {
  className?: string;
  type?: DividerType;
  dashed?: boolean;
}

const dividerTypeStyles: Record<DividerType, FlattenSimpleInterpolation> = {
  horizontal: horizontalDividerStyles,
  vertical: verticalDividerStyles,
};

export const CustomDivider = styled.div.attrs({
  role: 'separator',
})<CustomDividerProps>`
  border-width: 0;
  border-color: ${({ theme }) => rgba(theme.custom.greyAlternativeColor, 0.3)};
  border-style: ${({ dashed }) => (dashed ? 'dashed' : 'solid')};
  ${({ type = 'horizontal' }) => dividerTypeStyles[type]}
`;
