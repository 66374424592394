import { createGoogleMapComponent } from 'google-maps-js-api-react';
import styled from 'styled-components/macro';

const CGoogleMap = styled(createGoogleMapComponent(['onClick'], []))`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.divider};
`;

export const mapOptions: google.maps.MapOptions = {
  clickableIcons: false,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  mapId: '44b9c2c938c14e7e',
};

export default CGoogleMap;
