import styled from 'styled-components/macro';

import { StyledMonthContainer } from '../Month/styled';

const SPACING = 5;

export const StyledMonthWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin: 0 -${({ theme }) => theme.spacing(SPACING)};
`;

export const StyledContainer = styled.div`
  position: absolute;
  left: -50%;
  display: flex;
  ${StyledMonthContainer} {
    padding: 0 ${({ theme }) => theme.spacing(SPACING)};
  }
`;

export const StyledArrowsContainer = styled.div`
  position: absolute;
  top: 2px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;
