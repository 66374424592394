import CheckIcon from 'src/icons/Check';

import { Button, ButtonProps } from './WrappedButtons';

type TogglerButtonProps<T> = Omit<ButtonProps, 'onClick' | 'endIcon'> & {
  selected?: boolean;
  value: T;
  onClick?: (value: T) => void;
};

const TogglerButton = <T extends string | number>({
  selected,
  value,
  onClick,
  ...props
}: TogglerButtonProps<T>) => (
  <Button
    {...props}
    onClick={onClick && (() => onClick(value))}
    endIcon={selected && <CheckIcon />}
    {...{ selected }}
  />
);

export default TogglerButton;
