import { VFC } from 'react';
import { CSSProperties } from 'styled-components';

import { StyledDay, StyledDayContainer } from './styled';

export enum EDayStatus {
  NONE,
  START,
  BETWEEN,
  END,
}

type Props = {
  date: Date;
  getDayStatus: (v: Date) => EDayStatus;
  shouldDisableDate: (v: Date) => boolean;
  onSelect: (v: Date) => void;
  style?: CSSProperties;
};

export const Day: VFC<Props> = ({
  date,
  getDayStatus,
  shouldDisableDate,
  onSelect,
  style,
}) => (
  <StyledDayContainer
    status={getDayStatus(date)}
    disabled={shouldDisableDate(date)}
    onClick={() => onSelect(date)}
    style={style}
  >
    <StyledDay variant="body2" color="inherit">
      {date.getDate()}
    </StyledDay>
  </StyledDayContainer>
);
