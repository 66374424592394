import { ReactNode, VFC } from 'react';
import styled from 'styled-components/macro';

import BorderedBlock from 'src/components/common/BorderedBlock';
import { fontSizeMedium } from 'src/modules/fontSizes';
import { media } from 'src/modules/mediaQuery';

interface CPanelProps {
  withBorder?: boolean;
  title?: ReactNode;
  extras?: ReactNode;
  className?: string;
  children?: any;
  id?: string;
}

const Block = styled.div`
  &:nth-child(n + 2) {
    margin-top: 50px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  padding: 15px 20px;
  color: ${(props) => props.theme.custom.textColor};
  ${fontSizeMedium};
  font-weight: 600;
  display: inline-block;

  ${media.phone`
    padding: 10px;
  `};
`;

export const Container = styled.div``;

const CPanel: VFC<CPanelProps> = ({
  withBorder,
  className,
  id,
  children,
  title,
  extras,
}) => {
  const WrapComp = withBorder ? BorderedBlock : Block;

  return (
    <WrapComp className={className} data-id={id}>
      {title && (
        <HeaderWrapper>
          <HeaderTitle>{title}</HeaderTitle>
          {extras}
        </HeaderWrapper>
      )}
      <Container>{children}</Container>
    </WrapComp>
  );
};

export default CPanel;
