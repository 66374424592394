import { Action } from 'redux';

import { ActionTypes } from 'src/constants';

export type ChangeLocaleAction = Action<ActionTypes.UPDATE_LOCALE_REQUEST> & {
  locale: string;
};

export function changeLocale(locale: string): ChangeLocaleAction {
  return {
    type: ActionTypes.UPDATE_LOCALE_REQUEST,
    locale,
  };
}
