import 'styled-components/macro';

import { VFC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useRouteMatch } from 'react-router';
import { Checkbox } from '@mui/material';
import styled from 'styled-components';

import { login, resetAccountErrorCode, signUp, updateErrorCode } from 'src/store/user/actions';
import { I18n } from 'src/components/I18n';
import {
  confirmationCodeRequired,
  emailValidation,
  passwordRequired,
  referralCodeRequired
} from 'src/components/shared/FormElements/utils';
import { userSelector } from 'src/store/user/selectors';
import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';
import { L10nLink, Link } from 'src/components/common/Link';
import { LoadingTypes } from 'src/constants';
import { PasswordField } from 'src/components/common/PasswordField';

import { AuthBlockProps, EAuth } from '..';
import { ContentForm, renderAlert } from '../ContentForm/ContentForm';
import { handleModal } from '../utils';

const validationSchema = yup.object().shape({
  email: emailValidation,
  password: passwordRequired,
  referralCode: referralCodeRequired,
  enableSubscriptions: yup.boolean().required(),
});

const confirmValidationSchema = yup.object().shape({
  confirmationCode: confirmationCodeRequired,
});

const CheckboxField = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Label = styled.label`
  cursor: pointer;
  :hover {
    color: rgb(0, 108, 228);
    transition: color 0.3 ease;
  }
`

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

export const SignUpSection: VFC<AuthBlockProps> = ({
  setActiveModal,
  onSubmit,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { errorCode, isAuthenticated, status } = useSelector(userSelector);

  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('invitationCode');
  const cvexRef = urlParams.get('cvexRef');
  const cvexSubRef = urlParams.get('cvexSubRef');
  const cvexCampaign = urlParams.get('cvexCampaign') === 'true';
  const nordicCampaign = urlParams.get('nordicCampaign') === 'true';

  const [initialValues, setInitialValues] = useState<{
    email: string;
    password: string;
  } | null>(null);

  useEffect(() => {
    if (isAuthenticated && onSubmit) onSubmit();
  }, [isAuthenticated]);

  useEffect(
    () => () => {
      dispatch(resetAccountErrorCode());
    },
    [dispatch]
  );

  const { handleSubmit, control, watch } = useForm<{
    email: string;
    password: string;
    referralCode: string;
    cvexRef: string;
    cvexSubRef: string;
    enableSubscriptions: boolean;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      enableSubscriptions: true,
      referralCode: referralCode || '',
      cvexRef: cvexRef || '',
      cvexSubRef: cvexSubRef || '',
    },
  });

  const { handleSubmit: handleConfirm, control: controlConfirm } = useForm<{
    confirmationCode: string;
  }>({
    resolver: yupResolver(confirmValidationSchema),
    defaultValues: {
      confirmationCode: '',
    },
  });

  const confirmation = async (code: string) => {
    if (!initialValues) return;

    try {
      const response = await fetch(`/api/customers/confirm-email?code=${code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (result.isSuccessful) {
        dispatch(login({
          email: initialValues.email,
          password: initialValues.password,
        }));

        if (cvexCampaign) {
          window.location.replace('https://t.me/cryptovalleyexchange_bot/app ');
        }
      } else {
        dispatch(updateErrorCode(result.errorCode));
        renderAlert(result.errorCode);
        console.error(`Error ${result.errorCode}: ${result.error}`);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };

  const enableSubscriptions = watch('enableSubscriptions');

  const intlFormatMessage = useIntlFormatMessage();

  const match = useRouteMatch<{ token: string }>({
    path: `/${EAuth.SIGN_UP}/inviteLink/:token`,
    exact: true,
  });

  const inviteLink = match?.params.token;

  return (
    <ContentForm
      {...rest}
      subscription={enableSubscriptions}
      loading={status === LoadingTypes.RUNNING}
      headerTextId="SIGN_UP_FORM.MODAL.HEADER_NAME"
      submitButtonI18nId="SIGN_UP_FORM.MODAL.BUTTON_NAME"
      alertCode={errorCode}
      cvexCampaign={cvexCampaign}
      nordicCampaign={nordicCampaign}
      onSubmit={errorCode === 0 ? handleConfirm((values) => confirmation(values.confirmationCode)) : handleSubmit((values) => {
        setInitialValues({
          email: values.email,
          password: values.password,
        });
        dispatch(signUp(inviteLink ? { ...values, inviteLink } : values));
      })}
      navigateBlock={!cvexCampaign && !nordicCampaign ?
        <Typography variant="body4">
          <I18n id="SIGN_UP_FORM.MODAL.FOOTER.TEXT" />{' '}
          <Link
            to={`/${EAuth.SIGN_IN}`}
            onClick={handleModal(setActiveModal, EAuth.SIGN_IN)}
            color="secondary"
            variant="body4"
          >
            <I18n id="SIGN_IN_FORM.NAME" />
          </Link>
        </Typography>
        : null
      }
      termsBlock={
        <TermsContainer>
          <Typography variant="body4" textAlign="center">
            <I18n id="SIGN_IN_UP_FORM.MODAL.TERMS_TEXT" />
            {" "}
            <L10nLink
              target="_blank"
              color="secondary"
              typography="body4"
              to="/privacy-policy"
            >
              <I18n id="SIGN_IN_UP_FORM.MODAL.PRIVACY_POLICY" />
            </L10nLink>,
            <br/>
            <I18n id="SIGN_IN_UP_FORM.MODAL.AND" />
            {" "}
            <L10nLink
              target="_blank"
              color="secondary"
              typography="body4"
              to="/terms-and-conditions"
            >
              <I18n id="SIGN_IN_UP_FORM.MODAL.TERMS_AND_CONDITIONS" />
            </L10nLink>
          </Typography>

          {errorCode !== 0 && (
            <Controller
              name="enableSubscriptions"
              control={control}
              render={({ field }) => (
                <CheckboxField>
                  <Checkbox {...field} id="enableSubscriptions" defaultChecked />
                  <Label htmlFor="enableSubscriptions">
                    <I18n id="SIGN_UP_FORM.SUBSCRIPTION.LABEL" />
                  </Label>
                </CheckboxField>
              )}
            />
          )}
        </TermsContainer>
      }
    >
      {errorCode !== 0 && (
        <>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Invalid email address',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder={intlFormatMessage(
                  'SIGN_IN_FORM.EMAIL_FIELD.PLACEHOLDER'
                )}
                color="focusSecondary"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                label={
                  <>
                    <I18n id="SIGN_IN_FORM.EMAIL_FIELD.LABEL" /> *
                  </>
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                placeholder={intlFormatMessage(
                  'SIGN_IN_FORM.MODAL.PASSWORD.PLACEHOLDER'
                )}
                color="focusSecondary"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                label={
                  <>
                    <I18n id="SIGN_IN_FORM.MODAL.PASSWORD.LABEL" /> *
                  </>
                }
              />
            )}
          />
          {!cvexCampaign && !nordicCampaign ? (
            <Controller
              name="referralCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder={intlFormatMessage(
                    'SIGN_UP_FORM.REFERRAL_CODE_FILED.PLACEHOLDER'
                  )}
                  color="focusSecondary"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  label={
                    <>
                      <I18n id="SIGN_IN_FORM.MODAL.REFERRAL_CODE.LABEL" /> *
                    </>
                  }
                />
              )}
            />
          ) : null}
        </>
      )}
      {errorCode === 0 && (
        <Controller
          name="confirmationCode"
          control={controlConfirm}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder={intlFormatMessage(
                'SIGN_UP_FORM.CONFIRMATION_CODE_FILLED.PLACEHOLDER'
              )}
              color="focusSecondary"
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              label={
                <>
                  <I18n id="SIGN_IN_FORM.MODAL.CONFIRMATION_CODE.LABEL" /> *
                </>
              }
            />
          )}
        />
      )}
    </ContentForm>
  );
};
