import { applyMiddleware, createStore, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import {
  persistStore,
  persistCombineReducers,
  PersistConfig,
} from 'redux-persist';
import { createReduxLocationActions } from 'redux-location-state';
import storage from 'redux-persist/lib/storage';

import listenLocationForSyncMiddleware from 'src/modules/listenLocationForSyncMiddleware';
import history from 'src/modules/history';
import rootEpic from 'src/store/epics';
import { ReduxState, getRootReducer } from 'src/store/reducers';
import {
  queryParamMapSetup,
  stateToParams,
} from 'src/configs/queryParamMapSetup';
import { mapLocationToState } from 'src/modules/mapLocationToState';
import { gtmMiddleware } from 'src/analytics/middleware';

import { searchParamsTransform } from './transforms';

declare const window: Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?(a: any): any;
  __REDUX_DEVTOOLS_EXTENSION__?(a?: any): any;
};

const epicMiddleware = createEpicMiddleware({
  dependencies: { history },
});

const PREFIX_STORAGE_KEY = 'persist:';
const STORAGE_KEY = 'store';
const PERSIST_STORAGE_VERSION = 0;

const persistConfig: PersistConfig<any> = {
  key: STORAGE_KEY, // key is required
  keyPrefix: PREFIX_STORAGE_KEY,
  version: PERSIST_STORAGE_VERSION,
  storage, // storage is now required
  whitelist: ['searchParams'],
  transforms: [searchParamsTransform],
  blacklist: ['router'],
};

const rootReducer = getRootReducer(history);

const reducer = persistCombineReducers(persistConfig, rootReducer);

const { locationMiddleware, reducersWithLocation } =
  createReduxLocationActions<ReduxState>(
    queryParamMapSetup,
    mapLocationToState,
    history,
    reducer,
    stateToParams
  );

const middleware = [
  epicMiddleware,
  routerMiddleware(history),
  listenLocationForSyncMiddleware(locationMiddleware),
  gtmMiddleware,
];

const __DEV__ = process.env.NODE_ENV === 'development';
/* istanbul ignore next */
// if (__DEV__) {
//   middleware.push(createLogger({ collapsed: true }));
// }

const composeWithApplyMiddlewares = () => {
  if (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION__) {
    return compose(
      // eslint-disable-next-line
      // @ts-ignore
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }
  // eslint-disable-next-line
  // @ts-ignore
  return compose(applyMiddleware(...middleware));
};

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeWithApplyMiddlewares()(createStore);

  const store = createStoreWithMiddleware<ReduxState>(
    reducersWithLocation,
    initialState as any
  );
  // @ts-expect-error
  epicMiddleware.run(rootEpic);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

export { store, persistor };
