import styled from "styled-components";

export const ModalTitle = styled.h2`
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
`

export const ModalContainer = styled.div`
  padding: 40px 24px 24px;
  border-radius: 16px;
  max-width: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  position: relative;
  border: 2px solid #a0a0a0;
  box-shadow: 2px 1px 16px 4px #a0a0a0;
`

export const ModalDescription = styled.p`
  font-size: 12px;
  color: grey;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const ModalActions = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
`

export const AppButton = styled.div`
  background: black;
  border: 1px solid #A6A6A6;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  color: white;
  padding: 4px 8px;
  display: flex;
  align-items: center;
`

export const AppButtonInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const AppButtonTitle = styled.span`
  font-size: 9px;
`

export const AppButtonStoreTitle = styled.span`
  font-size: 16px;
`
