import { useState } from 'react';

type TogglerOptions<O extends any[], C extends any[]> = {
  onOpen?: (...args: O) => void;
  onClose?: (...args: C) => void;
  defaultValue?: boolean;
};

const useToggler = <O extends any[], C extends any[]>({
  onClose,
  onOpen,
  defaultValue = false,
}: TogglerOptions<O, C> = {}) => {
  const [toggler, setToggler] = useState(defaultValue);

  return {
    toggler,
    handleOpen: onOpen
      ? (...args: O) => {
          setToggler(true);
          onOpen(...args);
        }
      : () => setToggler(true),
    handleClose: onClose
      ? (...args: C) => {
          setToggler(false);
          onClose(...args);
        }
      : () => setToggler(false),
  };
};

export default useToggler;
