import immutable from 'immutability-helper';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import _defaultsDeep from 'lodash/defaultsDeep';
import { Action } from 'redux';

import { createReducer } from 'src/modules/helpers';
import { ACCESS_TOKEN_COOKIE_NAME, AccountActionTypes, ID_TOKEN_COOKIE_NAME, LoadingTypes } from 'src/constants';
import { ErrorCode } from 'src/components/shared/FormElements/types';
import { ActionSuccess, Error, ErrorResponseAction } from 'src/models/actions';
import {
  AccountChangePasswordResponse,
  AccountLoginResponse,
  AccountRequestResetPasswordResponse,
  AccountResetPasswordResponse
} from 'src/models/customers/account';
import { Consents, CustomerDetails, CustomerResponse } from 'src/models/customers/common';
import { Nullable } from 'src/types';
import {
  FetchBookingsAction,
  ResetAccountErrorCode,
  UpdateErrorCodeAction,
  UpdateUserConsentsAction
} from 'src/store/user/actions';
import {
  BookingStatus,
  CustomerBookingsCancelResponse,
  CustomerHotelInfo,
  CustomersBookingResponse,
  CustomersGetResponse,
  Reservation
} from 'src/models/customers/manager';

const DEFAULT_ITEMS_LIMIT = 50;

interface Bookings {
  items?: Reservation[];
  isLoading: LoadingTypes;
  startIndex: number;
  limit: number;
  totalCount: number;
  errors: Error<ErrorData>[];
}

interface Favorites {
  items: CustomerHotelInfo[];
  isLoading: LoadingTypes;
  startIndex: number;
  limit: number;
  totalCount: number;
  errors: Error<ErrorData>[];
}

interface Settings {
  isLoading: LoadingTypes;
  errorCode?: Nullable<ErrorCode>;
  isSuccessful?: boolean;
  token?: string;
}

interface ResetPasswordLink {
  isLoading: LoadingTypes;
  errorCode?: Nullable<ErrorCode>;
  isSuccessful?: boolean;
}

interface ResetPassword {
  isLoading: LoadingTypes;
  errorCode?: Nullable<ErrorCode>;
  isSuccessful?: boolean;
  token?: string;
}

interface Unsubscribe {
  isLoading: LoadingTypes;
  errorCode?: ErrorCode;
  isSuccessful?: boolean;
  isSubscribed?: boolean;
  token?: string;
}

interface CancelBooking {
  isLoading: LoadingTypes;
  errorCode?: ErrorCode;
  isSuccessful?: boolean;
}

export interface UserState {
  isAuthenticated: boolean;
  status: LoadingTypes;
  info?: Partial<CustomerDetails>;
  favorites: Favorites;
  bookings: Bookings;
  accountSettings: Settings;
  resetPasswordLink: ResetPasswordLink;
  resetPassword: ResetPassword;
  cancelBooking: CancelBooking;
  errorCode?: Nullable<ErrorCode>;
  error?: Nullable<Error>;
  unsubscribe: Unsubscribe;
  gclid?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
}

export type ErrorData = CustomerResponse;

const initialAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

const getInitialState = (): UserState => ({
  isAuthenticated: Boolean(initialAccessToken),
  status: LoadingTypes.IDLE,
  info: {
    favorites: [],
  },
  favorites: {
    isLoading: LoadingTypes.IDLE,
    items: [],
    startIndex: 0,
    limit: DEFAULT_ITEMS_LIMIT,
    totalCount: 0,
    errors: [],
  },
  bookings: {
    isLoading: LoadingTypes.IDLE,
    items: [],
    startIndex: 0,
    limit: DEFAULT_ITEMS_LIMIT,
    totalCount: 0,
    errors: [],
  },
  resetPasswordLink: {
    isLoading: LoadingTypes.IDLE,
  },
  resetPassword: {
    isLoading: LoadingTypes.IDLE,
  },
  cancelBooking: {
    isLoading: LoadingTypes.IDLE,
  },
  accountSettings: {
    isLoading: LoadingTypes.IDLE,
  },
  unsubscribe: {
    isLoading: LoadingTypes.IDLE,
  },
  gclid: undefined,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  utm_campaign: undefined,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  utm_medium: undefined,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  utm_source: undefined,
});

const userState: UserState = getInitialState();

export const userReducer = createReducer(userState, {
  [AccountActionTypes.USER_LOGIN_REQUEST](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
      errorCode: { $set: null },
    });
  },
  [AccountActionTypes.USER_LOGIN_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.USER_LOGIN_SUCCESS,
      AccountLoginResponse
    >
  ) {
    const { payload } = action;
    const { customerInfo } = payload;

    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      isAuthenticated: { $set: true },
      info: { $set: customerInfo },
    });
  },
  [AccountActionTypes.USER_LOGIN_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.USER_LOGIN_FAILURE,
      ErrorData
    >
  ) {
    const isSuccessful = _get(action, ['error', 'data', 'isSuccessful']);
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      isAuthenticated: { $set: isSuccessful },
      errorCode: { $set: errorCode },
    });
  },
  [AccountActionTypes.USER_LOGOUT_REQUEST](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
    });
  },
  [AccountActionTypes.USER_LOGOUT_SUCCESS](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      isAuthenticated: { $set: false },
      info: { $set: userState.info },
    });
  },
  [AccountActionTypes.USER_LOGOUT_FAILURE](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
    });
  },
  [AccountActionTypes.UPDATE_ACCOUNT_USER_REQUEST](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
      error: { $set: null },
    });
  },
  [AccountActionTypes.UPDATE_ACCOUNT_USER_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.UPDATE_ACCOUNT_USER_SUCCESS,
      CustomerDetails
    >
  ) {
    const { payload } = action;
    const prevUser = _get(state, 'info', {});

    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      info: { $set: _defaultsDeep(payload, prevUser) },
    });
  },
  [AccountActionTypes.UPDATE_ACCOUNT_USER_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.UPDATE_ACCOUNT_USER_FAILURE,
      ErrorData
    >
  ) {
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      error: { $set: action.error },
    });
  },
  [AccountActionTypes.USER_SIGN_UP_REQUEST](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
    });
  },
  [AccountActionTypes.USER_SIGN_UP_SUCCESS](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      errorCode: { $set: ErrorCode.NO_ERROR },
    });
  },
  [AccountActionTypes.USER_UPDATE_ERROR_CODE](state: UserState, action: UpdateErrorCodeAction) {
    return immutable(state, {
      errorCode: { $set: action.payload },
    });
  },
  [AccountActionTypes.USER_SIGN_UP_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.USER_SIGN_UP_FAILURE,
      ErrorData
    >
  ) {
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      errorCode: { $set: errorCode },
    });
  },
  [AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_REQUEST](
    state: UserState,
    action: FetchBookingsAction &
      Action<AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_REQUEST>
  ) {
    return immutable(state, {
      bookings: {
        isLoading: { $set: LoadingTypes.RUNNING },
        items: { $set: [] },
        startIndex: { $set: action.params.startIndex },
        limit: { $set: action.params.limit },
        totalCount: { $set: 0 },
        errors: { $set: [] },
      },
    });
  },
  [AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_SUCCESS,
      CustomersBookingResponse
    >
  ) {
    const {
      payload: { items, totalCount },
    } = action;

    const processedBookings = items.filter(
      (item) => item.status !== BookingStatus.Processing
    );

    return immutable(state, {
      bookings: {
        isLoading: { $set: LoadingTypes.IDLE },
        items: { $set: processedBookings },
        totalCount: { $set: totalCount },
      },
    });
  },
  [AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_FAILURE,
      ErrorData
    >
  ) {
    return immutable(state, {
      bookings: {
        isLoading: { $set: LoadingTypes.IDLE },
        errors: { $push: [action.error] },
      },
    });
  },
  [AccountActionTypes.CANCEL_ACCOUNT_BOOKING_REQUEST](state: UserState) {
    return immutable(state, {
      cancelBooking: {
        isLoading: { $set: LoadingTypes.RUNNING },
        errorCode: { $set: undefined },
        isSuccessful: { $set: undefined },
      },
    });
  },
  [AccountActionTypes.CANCEL_ACCOUNT_BOOKING_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.CANCEL_ACCOUNT_BOOKING_SUCCESS,
      CustomerBookingsCancelResponse
    >
  ) {
    const { payload } = action;

    return immutable(state, {
      cancelBooking: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSuccessful: { $set: payload.isSuccessful },
      },
    });
  },
  [AccountActionTypes.CANCEL_ACCOUNT_BOOKING_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.CANCEL_ACCOUNT_BOOKING_FAILURE,
      ErrorData
    >
  ) {
    const isSuccessful = _get(action, ['error', 'data', 'isSuccessful']);
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      cancelBooking: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSuccessful: { $set: isSuccessful },
        errorCode: { $set: errorCode },
      },
    });
  },
  [AccountActionTypes.FETCH_USER_INFO_REQUEST](state: UserState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
    });
  },
  [AccountActionTypes.FETCH_USER_INFO_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.FETCH_USER_INFO_SUCCESS,
      CustomersGetResponse
    >
  ) {
    const { payload } = action;

    return immutable(state, {
      info: { $set: payload },
      status: { $set: LoadingTypes.IDLE },
    });
  },
  [AccountActionTypes.FETCH_USER_INFO_FAILURE](state: UserState) {
    return immutable(state, {
      info: { $set: userState.info },
      status: { $set: LoadingTypes.IDLE },
    });
  },
  [AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_REQUEST](state: UserState) {
    return immutable(state, {
      accountSettings: {
        isLoading: { $set: LoadingTypes.RUNNING },
        errorCode: { $set: null },
        isSuccessful: { $set: undefined },
      },
    });
  },
  [AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_SUCCESS,
      AccountChangePasswordResponse
    >
  ) {
    const {
      payload: { errorCode, isSuccessful },
    } = action;

    return immutable(state, {
      accountSettings: {
        isLoading: { $set: LoadingTypes.IDLE },
        errorCode: { $set: errorCode },
        isSuccessful: { $set: isSuccessful },
      },
    });
  },
  [AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_FAILURE,
      ErrorData
    >
  ) {
    const isSuccessful = _get(action, ['error', 'data', 'isSuccessful']);
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      accountSettings: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSuccessful: { $set: isSuccessful },
        errorCode: { $set: errorCode },
      },
    });
  },
  [AccountActionTypes.RESET_ACCOUNT_PASSWORD_REQUEST](state: UserState) {
    return immutable(state, {
      resetPassword: {
        isLoading: { $set: LoadingTypes.RUNNING },
        errorCode: { $set: null },
        isSuccessful: { $set: undefined },
      },
    });
  },
  [AccountActionTypes.RESET_ACCOUNT_PASSWORD_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.RESET_ACCOUNT_PASSWORD_SUCCESS,
      AccountResetPasswordResponse
    >
  ) {
    const {
      payload: { errorCode, isSuccessful },
    } = action;

    return immutable(state, {
      resetPassword: {
        isLoading: { $set: LoadingTypes.IDLE },
        errorCode: { $set: errorCode },
        isSuccessful: { $set: isSuccessful },
      },
    });
  },
  [AccountActionTypes.RESET_ACCOUNT_PASSWORD_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.RESET_ACCOUNT_PASSWORD_FAILURE,
      ErrorData
    >
  ) {
    const isSuccessful = _get(action, ['error', 'data', 'isSuccessful']);
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      resetPassword: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSuccessful: { $set: isSuccessful },
        errorCode: { $set: errorCode || action.error.status },
      },
    });
  },
  [AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_REQUEST](
    state: UserState,
    action: UpdateUserConsentsAction
  ) {
    const { params } = action;
    const prevUser = _get(state, 'info', {});
    const userWithNewConsents = {
      ...prevUser,
      consents: params,
    };
    return immutable(state, {
      info: { $set: userWithNewConsents },
    });
  },
  [AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_FAILURE](
    state: UserState,
    action: ErrorResponseAction<{ oldConsents: Consents }> &
      Action<AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_FAILURE>
  ) {
    const oldConsents = _get(action, ['error', 'data', 'oldConsents']);

    return immutable(state, {
      info: {
        consents: { $set: oldConsents },
      },
    });
  },
  [AccountActionTypes.SEND_RESET_PASSWORD_LINK_REQUEST](state: UserState) {
    return immutable(state, {
      resetPasswordLink: {
        isLoading: { $set: LoadingTypes.RUNNING },
        errorCode: { $set: null },
      },
    });
  },
  [AccountActionTypes.SEND_RESET_PASSWORD_LINK_SUCCESS](
    state: UserState,
    action: ActionSuccess<
      AccountActionTypes.SEND_RESET_PASSWORD_LINK_SUCCESS,
      AccountRequestResetPasswordResponse
    >
  ) {
    const {
      payload: { errorCode },
    } = action;

    return immutable(state, {
      resetPasswordLink: {
        isLoading: { $set: LoadingTypes.IDLE },
        errorCode: { $set: errorCode },
      },
    });
  },
  [AccountActionTypes.SEND_RESET_PASSWORD_LINK_FAILURE](
    state: UserState,
    action: ErrorResponseAction<
      AccountActionTypes.SEND_RESET_PASSWORD_LINK_FAILURE,
      ErrorData
    >
  ) {
    const { error } = action;
    const errorCode = _get(action, ['error', 'data', 'errorCode']);

    return immutable(state, {
      resetPasswordLink: {
        isLoading: { $set: LoadingTypes.IDLE },
        errorCode: { $set: errorCode || error.status },
      },
    });
  },
  [AccountActionTypes.CLEAN_UP_USER_META_INFO](state: UserState) {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    Cookies.remove(ID_TOKEN_COOKIE_NAME);

    return immutable(state, {
      isAuthenticated: { $set: false },
      info: { $set: userState.info },
      status: { $set: LoadingTypes.IDLE },
    });
  },
  [AccountActionTypes.UNSUBSCRIBE_USER_SUCCESS](state: UserState) {
    return immutable(state, {
      unsubscribe: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSubscribed: { $set: false },
      },
    });
  },
  [AccountActionTypes.SUBSCRIBE_USER_SUCCESS](state: UserState) {
    return immutable(state, {
      unsubscribe: {
        isLoading: { $set: LoadingTypes.IDLE },
        isSubscribed: { $set: true },
      },
    });
  },
  [AccountActionTypes.RESET_ERROR_CODE](
    state: UserState,
    action: ResetAccountErrorCode
  ) {
    const { key } = action;

    if (!key) {
      return immutable(state, {
        errorCode: { $set: null },
      });
    }

    return immutable(state, {
      [key]: {
        errorCode: { $set: null },
      },
    });
  },
});
