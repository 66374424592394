import { useCallback, useEffect, useState } from 'react';

import { scrollToElement } from 'src/modules/scrollToElement';

export type SectionId =
  | 'overview'
  | 'about-hotel'
  | 'rooms'
  | 'reviews'
  | 'facilities';

type SectionsRefs = Record<SectionId, HTMLDivElement | null>;

interface IReturnedValue {
  activeSectionId: SectionId;
  setSectionRef: (el: HTMLDivElement | null) => void;
  scrollToSection: (sectionId: SectionId) => void;
}

export const useSectionsNavigator = ({
  watchNodes = false,
}: {
  watchNodes?: boolean;
}): IReturnedValue => {
  const [sectionRefsMap, setSectionRefsMap] = useState<SectionsRefs>({
    overview: null,
    'about-hotel': null,
    rooms: null,
    reviews: null,
    facilities: null,
  });
  const [activeSectionId, setActiveSectionId] = useState<SectionId>('overview');
  const setSectionRef = useCallback(
    (el: HTMLDivElement | null) => {
      if (el) {
        const sectionId = el.getAttribute('data-id') as SectionId;
        setSectionRefsMap((prevState) => ({ ...prevState, [sectionId]: el }));
      }
    },
    [setSectionRefsMap]
  );

  useEffect(() => {
    if (watchNodes) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries.find(({ isIntersecting }) => isIntersecting);

          if (entry) {
            const sectionId = entry.target.getAttribute('data-id') as SectionId;
            setActiveSectionId(sectionId);
          }
        },
        {
          rootMargin: '-62px 0px -80% 0px', // 62px - StickyHeader height + manual tuning
        }
      );

      const nodes = Object.values(sectionRefsMap);

      nodes.forEach((node) => {
        if (node) {
          observer.observe(node);
        }
      });

      return () => observer.disconnect();
    }
  }, [sectionRefsMap, watchNodes]);

  const scrollToSection = useCallback(
    (sectionId: SectionId) => {
      const element = sectionRefsMap[sectionId]!;

      scrollToElement(element, {
        offset: -90, // StyledCustomDivider margin + StickyHeader height
        onEnd: () => setActiveSectionId(sectionId),
      });
    },
    [sectionRefsMap, setActiveSectionId]
  );

  return { setSectionRef, scrollToSection, activeSectionId };
};
