import Grid, { GridProps } from '@mui/material/Grid';
import { throttle } from 'lodash';
import 'styled-components/macro';
import { FC, useEffect, useRef } from 'react';

const MagicGrid: FC<
  Omit<GridProps, 'direction' | 'wrap' | 'ref' | 'container'>
> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;

    if (el) {
      const listener = throttle(() => {
        el.style.removeProperty('height');

        let count = 1;

        while (
          el.clientWidth +
            el.children[0].scrollWidth -
            el.children[0].clientWidth <
          el.scrollWidth
        ) {
          el.style.height = `calc(100% + ${el.children[0].clientHeight}px * ${count})`;

          count++;
        }
      }, 10);

      listener();

      window.addEventListener('resize', listener);
      window.addEventListener('orientationchange', listener);

      return () => {
        window.removeEventListener('resize', listener);
        window.removeEventListener('orientationchange', listener);
      };
    }
  }, []);

  return (
    <div
      css={`
        position: relative;
        height: 100%;
      `}
    >
      <Grid
        {...props}
        ref={ref}
        height="inherit"
        position="absolute"
        direction="column"
        wrap="wrap"
        container
      />
    </div>
  );
};

export default MagicGrid;
