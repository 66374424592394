import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import { CurrencyActionTypes } from 'src/constants';
import type { CurrencyType } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import {
  ChangeCurrencyAction,
  InitCurrencyAction,
} from 'src/store/currency/actions';

const { UPDATE_CURRENCY, INIT_CURRENCY } = CurrencyActionTypes;

export interface CurrencyState {
  currentCurrency: CurrencyType;
  currentRate: number;
}

const currencyState: CurrencyState = {
  currentCurrency: 'EUR',
  currentRate: 1,
};

export const currency = createReducer(currencyState, {
  [UPDATE_CURRENCY](state: CurrencyState, action: ChangeCurrencyAction) {
    return update(state, {
      currentCurrency: { $set: action.currency },
      currentRate: { $set: action.rate },
    });
  },
  [INIT_CURRENCY](state: CurrencyState, action: InitCurrencyAction) {
    return update(state, {
      currentCurrency: { $set: action.currency },
      currentRate: { $set: action.rate },
    });
  },
});
