import styled from 'styled-components/macro';

interface ArrowIconProps {
  rotate: number;
}

const arrowIconAttrs = { className: 'icon-arrow-down' };

export const ArrowIcon = styled.span.attrs(arrowIconAttrs)<ArrowIconProps>`
  display: inline-block;
  font-size: 12px;
  color: ${(p) => p.theme.custom.textColor};
  transform: rotate(${(p) => `${p.rotate}deg`});
  transition: transform 0.3s ease-in-out;
`;
