import _get from 'lodash/get';

import type {
  DefaultAppMessagesTypeKey,
  RoomBedType,
  RoomFoodType,
} from 'src/containers/ConnectedIntl/messages/defaultMessages';

export enum FoodType {
  NONE = 0,
  ROOM_ONLY = 1,
  BREAKFAST_INCLUDED = 2,
  LUNCH_INCLUDED = 3,
  DINNER_INCLUDED = 4,
  HALF_BOARD = 5,
  FULL_BOARD = 6,
  ALL_INCLUSIVE = 7,
  CONTINENTAL_BREAKFAST = 8,
  BREAKFAST_BUFFET = 9,
  SOME_MEAL = 10,
  ALL_INCLUSIVE_PREMIUM = 11,
  ALL_INCLUSIVE_SOFT = 12,
  AMERICAN_BREAKFAST = 13,
  BREAKFAST_HALF_BOARD = 14,
  ENGLISH_BREAKFAST = 15,
  IRISH_BREAKFAST = 16,
  LIGHT_BREAKFAST = 17,
  DISNEY_QUICK_SERVICE_BREAKFAST = 18,
  SCOTTISH_BREAKFAST = 19,
  DISNEY_DINING = 20,
  DISNEY_DELUXE_DINING = 21,
  DISNEY_QUICK_SERVICE_MEAL = 22,
  DISNEY_QUICK_SERVICE = 23,
  SELF_CATERING = 24,
  HALF_BOARD_FULL_BOARD = 25,
  DISNEY_FULL_BOARD_PLUS = 26,
  DISNEY_FULL_BOARD_PREMIUM = 27,
  DISNEY_FULL_BOARD_STANDARD = 28,
  FULL_BOARD_BEVERAGES_INCLUDED = 29,
  DISNEY_HALF_BOARD_HOTEL = 30,
  DISNEY_HALF_BOARD_PLUS = 31,
  DISNEY_HALF_BOARD_PREMIUM = 32,
  DISNEY_HALF_BOARD_STANDARD = 33,
  HALF_BOARD_WITH_BEVERAGES_INCLUDED = 34,
}

type TFoodTypeMapping = Record<
  FoodType,
  {
    i18nKey: RoomFoodType;
    iconType: FoodIconName;
  }
>;
const FoodTypeMapping: TFoodTypeMapping = {
  [FoodType.NONE]: {
    i18nKey: 'NONE',
    iconType: '',
  },
  [FoodType.ROOM_ONLY]: {
    i18nKey: 'ROOM_ONLY',
    iconType: '',
  },
  [FoodType.BREAKFAST_INCLUDED]: {
    i18nKey: 'BREAKFAST_INCLUDED',
    iconType: 'cup',
  },
  [FoodType.LUNCH_INCLUDED]: {
    i18nKey: 'LUNCH_INCLUDED',
    iconType: 'cutlery',
  },
  [FoodType.DINNER_INCLUDED]: {
    i18nKey: 'DINNER_INCLUDED',
    iconType: 'cutlery',
  },
  [FoodType.HALF_BOARD]: {
    i18nKey: 'HALF_BOARD',
    iconType: 'cutlery',
  },
  [FoodType.FULL_BOARD]: {
    i18nKey: 'FULL_BOARD',
    iconType: 'cutlery',
  },
  [FoodType.ALL_INCLUSIVE]: {
    i18nKey: 'ALL_INCLUSIVE',
    iconType: 'cutlery',
  },
  [FoodType.CONTINENTAL_BREAKFAST]: {
    i18nKey: 'CONTINENTAL_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.BREAKFAST_BUFFET]: {
    i18nKey: 'BREAKFAST_BUFFET',
    iconType: 'cup',
  },
  [FoodType.SOME_MEAL]: {
    i18nKey: 'SOME_MEAL',
    iconType: 'cutlery',
  },
  [FoodType.ALL_INCLUSIVE_PREMIUM]: {
    i18nKey: 'ALL_INCLUSIVE_PREMIUM',
    iconType: 'cutlery',
  },
  [FoodType.ALL_INCLUSIVE_SOFT]: {
    i18nKey: 'ALL_INCLUSIVE_SOFT',
    iconType: 'cutlery',
  },
  [FoodType.AMERICAN_BREAKFAST]: {
    i18nKey: 'AMERICAN_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.BREAKFAST_HALF_BOARD]: {
    i18nKey: 'BREAKFAST_HALF_BOARD',
    iconType: 'cup',
  },
  [FoodType.ENGLISH_BREAKFAST]: {
    i18nKey: 'ENGLISH_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.IRISH_BREAKFAST]: {
    i18nKey: 'IRISH_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.LIGHT_BREAKFAST]: {
    i18nKey: 'LIGHT_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.DISNEY_QUICK_SERVICE_BREAKFAST]: {
    i18nKey: 'DISNEY_QUICK_SERVICE_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.SCOTTISH_BREAKFAST]: {
    i18nKey: 'SCOTTISH_BREAKFAST',
    iconType: 'cup',
  },
  [FoodType.DISNEY_DINING]: {
    i18nKey: 'DISNEY_DINING',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_DELUXE_DINING]: {
    i18nKey: 'DISNEY_DELUXE_DINING',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_QUICK_SERVICE_MEAL]: {
    i18nKey: 'DISNEY_QUICK_SERVICE_MEAL',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_QUICK_SERVICE]: {
    i18nKey: 'DISNEY_QUICK_SERVICE',
    iconType: 'cutlery',
  },
  [FoodType.SELF_CATERING]: {
    i18nKey: 'SELF_CATERING',
    iconType: 'cutlery',
  },
  [FoodType.HALF_BOARD_FULL_BOARD]: {
    i18nKey: 'HALF_BOARD_FULL_BOARD',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_FULL_BOARD_PLUS]: {
    i18nKey: 'DISNEY_FULL_BOARD_PLUS',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_FULL_BOARD_PREMIUM]: {
    i18nKey: 'DISNEY_FULL_BOARD_PREMIUM',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_FULL_BOARD_STANDARD]: {
    i18nKey: 'DISNEY_FULL_BOARD_STANDARD',
    iconType: 'cutlery',
  },
  [FoodType.FULL_BOARD_BEVERAGES_INCLUDED]: {
    i18nKey: 'FULL_BOARD_BEVERAGES_INCLUDED',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_HALF_BOARD_HOTEL]: {
    i18nKey: 'DISNEY_HALF_BOARD_HOTEL',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_HALF_BOARD_PLUS]: {
    i18nKey: 'DISNEY_HALF_BOARD_PLUS',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_HALF_BOARD_PREMIUM]: {
    i18nKey: 'DISNEY_HALF_BOARD_PREMIUM',
    iconType: 'cutlery',
  },
  [FoodType.DISNEY_HALF_BOARD_STANDARD]: {
    i18nKey: 'DISNEY_HALF_BOARD_STANDARD',
    iconType: 'cutlery',
  },
  [FoodType.HALF_BOARD_WITH_BEVERAGES_INCLUDED]: {
    i18nKey: 'HALF_BOARD_WITH_BEVERAGES_INCLUDED',
    iconType: 'cutlery',
  },
};

export const getFoodTypeNameBy = (
  foodType: FoodType
): DefaultAppMessagesTypeKey => {
  const i18nKey = _get(
    FoodTypeMapping[foodType],
    'i18nKey',
    FoodTypeMapping[0].i18nKey
  );

  return `HOTEL_DETAILS_PAGE.ROOMS.FOOD_TYPE.${i18nKey}`;
};

export type FoodIconName = 'cup' | 'cutlery' | '';

export const getFoodIconName = (foodType: FoodType): FoodIconName => {
  const iconType = _get(
    FoodTypeMapping[foodType],
    'iconType',
    FoodTypeMapping[0].iconType
  );

  return iconType;
};

enum RefundabilityEnum {
  NON_REFUNDABLE = 0,
  REFUNDABLE = 1,
}

const RefundabilityNames: Record<RefundabilityEnum, DefaultAppMessagesTypeKey> =
  {
    [RefundabilityEnum.REFUNDABLE]:
      'HOTEL_DETAILS_PAGE.ROOMS.REFUNDABILITY_TYPE.REFUNDABLE',
    [RefundabilityEnum.NON_REFUNDABLE]:
      'HOTEL_DETAILS_PAGE.ROOMS.REFUNDABILITY_TYPE.NON_REFUNDABLE',
  };

export enum BedType {
  None = 0,
  Double = 1,
  Twins = 2,
}

const BedTypesMapping: Record<BedType, RoomBedType> = {
  [BedType.None]: 'NONE',
  [BedType.Double]: 'DOUBLE',
  [BedType.Twins]: 'TWINS',
};

export const getBedTypeNameBy = (bedType: BedType): DefaultAppMessagesTypeKey =>
  `GENERAL.BED_TYPES.${BedTypesMapping[bedType] || BedTypesMapping[0]}`;

export const getRefundabilityLabel = (refundable: boolean) =>
  refundable
    ? RefundabilityNames[RefundabilityEnum.REFUNDABLE]
    : RefundabilityNames[RefundabilityEnum.NON_REFUNDABLE];
