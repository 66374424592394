import unset from 'lodash/unset';
import {
  DeepMap,
  DeepPartial,
  get,
  Path,
  PathValue,
  set,
  UseFormReturn,
} from 'react-hook-form';

const areFieldsDirty = <T>(
  obj: Partial<Readonly<DeepMap<DeepPartial<T>, boolean>>>
) => {
  const arr = Object.values(obj);

  for (let i = arr.length; i--; ) {
    const value = arr[i];

    if (typeof value === 'boolean' ? value : areFieldsDirty(value)) {
      return true;
    }
  }

  return false;
};

const handleSetValue = <N extends Path<T>, T extends Record<string, unknown>>(
  methods: UseFormReturn<T, any>,
  name: N,
  newValue: PathValue<T, N>,
  isEqual: (a: PathValue<T, N>, b: PathValue<T, N>) => boolean
) => {
  const { control, setValue } = methods;

  if (isEqual(get(control._defaultValues, name), newValue)) {
    if (unset(control._formState.dirtyFields, name)) {
      if (!areFieldsDirty(control._formState.dirtyFields)) {
        control._formState.isDirty = false;

        control._subjects.state.next({ name });
      }
    }
  } else {
    set(control._formState.dirtyFields, name, true);

    if (!control._formState.isDirty) {
      control._formState.isDirty = true;

      control._subjects.state.next({ name });
    }
  }

  setValue(name, newValue);
};

export default handleSetValue;
