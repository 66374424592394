import addMonths from 'date-fns/addMonths';
import { VFC } from 'react';
import { ListChildComponentProps } from 'react-window';

import { Month, MonthProps } from '../Month';

import { GUTTER_SIZE } from '.';

type Props = Omit<MonthProps, 'date' | 'style'> & { from: Date };

export const ListedMonth: VFC<ListChildComponentProps<Props>> = ({
  style,
  index,
  data: { from, ...props },
}) => (
  <Month
    style={{
      ...style,
      top: (style.top as number) + GUTTER_SIZE,
    }}
    {...props}
    date={addMonths(from, index)}
  />
);
