import { createSelector } from 'reselect';

import { ReduxState } from 'src/store/reducers';

export const userSelector = (state: ReduxState) => state.user;

export const bookingsSelector = createSelector(
  userSelector,
  (user) => user.bookings
);

export const userInfoSelector = createSelector(
  userSelector,
  (user) => user.info ?? {}
);

export const resetPasswordLinkSelector = (state: ReduxState) =>
  state.user.resetPasswordLink;

export const resetPasswordSelector = (state: ReduxState) =>
  state.user.resetPassword;

export const accountSettingsSelector = (state: ReduxState) =>
  state.user.accountSettings;

export const cancelBookingSelector = (state: ReduxState) =>
  state.user.cancelBooking;
