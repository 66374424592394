import { css } from 'styled-components/macro';

import { media } from './mediaQuery';

export const fontSizeExtraLarge = css`
  font-size: 24px;
`;

export const fontSizeLarge = css`
  font-size: 20px;

  ${media.phone`
    font-size: 16px;
  `}
`;

export const fontSizeMedium = css`
  font-size: 16px;

  ${media.phone`
    font-size: 14px;
  `}
`;

export const fontSizeSmall = css`
  font-size: 12px;

  ${media.phone`
    font-size: 12px;
  `}
`;

export const fontSizeVerySmall = css`
  font-size: 10px;

  ${media.phone`
    font-size: 10px;
  `}
`;

export const fontSizeBase = css`
  ${media.phone`
    font-size: 14px;
  `}
`;
