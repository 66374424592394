import { useRef, useEffect } from 'react';
import lozad from 'lozad';

function useLozad<T extends Element>(options?: lozad.Option) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const { current } = ref;
    let observer: lozad.Observer;
    if (current) {
      observer = lozad(current, options);

      observer.observe();
    }

    return () => {
      if (observer && observer.observer) {
        observer.observer.disconnect();
      }
    };
  }, [options]);

  return ref;
}

export default useLozad;
