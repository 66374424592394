import { memo, VFC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { I18n } from 'src/components/I18n';
import { STATIC_FORM_VALUES } from 'src/components/pages/HotelDetails';
import { searchParamsSelector } from 'src/store/search/selectors';

interface RoomsConfig {
  rooms: number;
  adults: number;
  children: number;
}

interface Child {
  age: number;
}

interface Room {
  adults: number;
  children: Child[];
}

const Wrap = styled.div`
  width: 100%;
  margin-top: 5px;
  color: ${(p) => p.theme.custom.grayTextColor};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RoomSetupDisplay: VFC<{ isStatic?: boolean }> = memo((props) => {
  const { rooms: reduxRooms = [] } = useSelector(searchParamsSelector);
  const rooms = props.isStatic ? STATIC_FORM_VALUES.rooms : reduxRooms;
  const roomsConfig = rooms.reduce(
    (prev: RoomsConfig, currRoom: Room) => ({
      rooms: prev.rooms + 1,
      adults: prev.adults + currRoom.adults,
      children: prev.children + currRoom.children.length,
    }),
    { rooms: 0, adults: 0, children: 0 }
  );

  return (
    <Wrap>
      <I18n
        id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.SUMMARY"
        values={roomsConfig}
      />
    </Wrap>
  );
});
