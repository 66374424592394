import format from 'date-fns/format';
import { AxiosError } from 'axios';

import { appConfig } from 'src/modules/app-config';

import { apiCall } from './api';

export enum ErrorVariant {
  Endpoint,
  ErrorBoundary,
}

export type EndpointErrorInfo = {
  path: string;
  method: 'GET' | 'POST';
};

const logError = (error: any, errorInfo: any, variant: ErrorVariant) =>
  apiCall('/web/check', {
    method: 'POST',
    data: {
      error: error instanceof Error ? error.toString() : JSON.stringify(error),
      errorInfo: JSON.stringify(errorInfo),
      type: variant,
      meta: {
        appBuildVersion: appConfig('APP_BUILD_VERSION'),
        userAgent: window.navigator.userAgent,
        viewport: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
        timezoneOffset: format(new Date(), 'xxx'),
      },
    },
  });

export const logEndpointError = (info: EndpointErrorInfo, err: AxiosError) =>
  logError(
    err,
    {
      ...info,
      params: err.config?.params,
      response: err.response,
      status: err.response?.status,
      url: window.location.href,
    },
    ErrorVariant.Endpoint
  );
