import { createContext, PropsWithChildren, useContext } from 'react';
import {
  enGB,
  enUS,
  ru,
  de,
  fr,
  es,
  pt,
  ja,
  nb,
  da,
  fi,
  it,
  nl,
  sv,
  zhCN,
} from 'date-fns/locale';

export const datesLocales = {
  en: enUS,
  'en-GB': enGB,
  es,
  ru,
  pt,
  ja,
  nb,
  da,
  fi,
  it,
  fr,
  de,
  nl,
  sv,
  'zh-CN': zhCN,
};

// type Context = { locale: Locale | null; isLoading: boolean };

const CalendarContext = createContext<Locale>(null as any);

export const useCalendarLocale = () => useContext(CalendarContext);

// const localeToLocaleKey = (locale: string) => {
//   switch (locale) {
//     case 'en':
//       return 'en-US';
//     default:
//       return locale;
//   }
// };

type Props = { locale: string };

export const CalendarProvider = ({
  children,
  locale,
}: Required<PropsWithChildren<Props>>) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const localesRef = useRef(new Map<string, Locale>());
  // const currLoadRef = useRef<string>();

  // useEffect(() => {
  //   if (!localesRef.current.has(locale)) {
  //     void (async () => {
  //       setIsLoading(true);
  //       currLoadRef.current = locale;

  //       const localeToSet = (
  //         await import(`date-fns/locale/${localeToLocaleKey(locale)}/index.js`)
  //       ).default;

  //       localesRef.current.set(locale, localeToSet);

  //       if (currLoadRef.current === locale) {
  //         setIsLoading(false);
  //       }
  //     })();
  //   }
  // }, [locale]);

  // const value = useMemo<Context>(
  //   () => ({ isLoading, locale: localesRef.current.get(locale) || null }),
  //   [isLoading, locale]
  // );

  const value = datesLocales[locale as keyof typeof datesLocales];

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};
