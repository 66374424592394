import { FC } from 'react';
import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { media } from 'src/modules/mediaQuery';
import { RoomSetupDisplay } from 'src/components/Panels/MobileDetailsPanel/RoomSetupDisplay';
import { DatesDisplay } from 'src/components/Panels/MobileDetailsPanel/DatesDisplay';
import { CustomButton } from 'src/components/shared/CustomButton';

interface DetailsPanelContentProps {
  onChangeClick: () => void;
  isStatic: boolean;
}

const Wrap = styled.div`
  display: flex;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.custom.whiteMainColor};
  font-size: 12px;

  ${media.tablet`
    padding: 10px 30px;
  `}
`;

const ChangeButton = styled(CustomButton)`
  margin-left: 10px;
`;

const Icon = styled.div`
  color: ${(p) => p.theme.custom.primaryColor};
  font-size: 20px;
  margin-right: 10px;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const ContentTitle = styled.div`
  display: flex;
  font-weight: 600;
`;

export const HotelDetailsPortalContent: FC<DetailsPanelContentProps> = (
  props
) => {
  const { onChangeClick, isStatic } = props;

  return (
    <Wrap>
      <Icon className="icon-date" />
      <Content>
        <ContentTitle>
          <DatesDisplay isStatic={isStatic} />
        </ContentTitle>
        <RoomSetupDisplay isStatic={isStatic} />
      </Content>
      <ChangeButton btnType="link" onClick={onChangeClick}>
        <I18n id="SEARCH_PAGE.SEARCH_RESULTS.TOOLBAR.CHANGE_SEARCH_PARAMS" />
      </ChangeButton>
    </Wrap>
  );
};
