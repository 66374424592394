import _isString from 'lodash/isString';

const getTop = (element: Element, start: number): number =>
  element.getBoundingClientRect().top + start;
// ease in out function thanks to:
// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
const easeInOutCubic = (t: number): number =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

const position = (
  start: number,
  end: number,
  elapsed: number,
  duration: number
): number => {
  if (elapsed > duration) {
    return end;
  }

  return start + (end - start) * easeInOutCubic(elapsed / duration);
};

interface Options {
  timing?: number;
  offset?: number;
  onEnd?: () => void;
  goBottom?: boolean;
}

const smoothScroll = (el: Element, options: Options = {}): void => {
  const { onEnd, goBottom = false, offset, timing } = options;
  const duration = timing || 500;
  const start = window.pageYOffset;
  const end = getTop(el, start) + (offset || 0);
  const clock = Date.now();

  if (goBottom) {
    return el.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }

  const step = () => {
    const elapsed = Date.now() - clock;
    window.scroll(0, position(start, end, elapsed, duration));

    if (elapsed <= duration) {
      window.requestAnimationFrame(step);
    } else {
      if (onEnd) {
        onEnd();
      }
    }
  };

  step();
};

export const scrollToElement = (
  elem: string | HTMLElement,
  options: Options = {}
) => {
  if (!_isString(elem)) {
    smoothScroll(elem, options);
    return;
  }

  const htmlElem = document.querySelector(elem);

  if (htmlElem) {
    smoothScroll(htmlElem, options);
  }
};
