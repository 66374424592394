import { FC } from 'react';

interface MailToLinkProps {
  className?: string;
  email: string;
}

export const MailToLink: FC<MailToLinkProps> = ({ className, email }) => (
  <a className={className} href={`mailto:${email}`}>
    {email}
  </a>
);
