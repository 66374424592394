import { useSelector } from 'react-redux';

import { currencySelector } from 'src/store/currency/selectors';

const useFormatPrice = () => {
  const { currentRate, currentCurrency } = useSelector(currencySelector);

  const getFormatter = (currency: string) => {
    const validCurrency = currency.length <= 3 ? currency : 'USD';
    try {
      return new Intl.NumberFormat('en', {
        style: 'currency',
        currency: validCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    } catch (error) {
      if (error instanceof RangeError) {
        console.warn(`Invalid currency code: ${currency}, falling back to USD`);
        return new Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
      } else {
        throw error;
      }
    }
  };

  const formatter = getFormatter(currentCurrency);

  return (price: number) => formatter.format(Math.ceil(price * currentRate));
};

export default useFormatPrice;
