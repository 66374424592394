import isNil from 'lodash/isNil';
import { useRef } from 'react';

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  const prev = ref.current;

  ref.current = value;

  return prev;
};

export const useExistedPrevious = <T>(value: T) => {
  const ref = useRef<T>();

  const prev = ref.current;

  if (!isNil(value)) {
    ref.current = value;
  }

  return prev;
};

export default usePrevious;
