import React from 'react';

import './footer.css';
import Logo from 'src/components/common/Logo';

const FooterPanel = () => (
  <section>
    <div className="footer">
      <div className="footer-first-column footer-nav-section">
        <div className="footer-section-wrapper">
          <a href="https://entravel.com/">
            <Logo isContrast={true} />
          </a>
          <span>A truly revolutionary hotel booking concept</span>
        </div>
      </div>
      <div className="footer-middle-section-wrapper">
        <div className="footer-nav-section">
          <a
            href="https://entravel.com/contact"
            className="footer-nav-section-text-color"
          >
            Contact
          </a>
          <a
            href="https://entravel.com/privacy-policy"
            className="footer-nav-section-text-color"
          >
            Privacy policy
          </a>
          <a
            href="https://entravel.com/terms-and-conditions"
            className="footer-nav-section-text-color"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="footer-nav-section">
          <a href="/faq" className="footer-nav-section-text-color">
            FAQ & Docs
          </a>
        </div>
      </div>
      <div className="footer-social-wrapper">
        <a href="https://twitter.com/entravelhotels">
          <div className="footer-social-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clipPath="url(#clip0_284_19878)">
                <path
                  d="M10.3855 7.55152L16.8819 0H15.3424L9.70162 6.55688L5.19633 0H0L6.81289 9.91516L0 17.8341H1.53952L7.49636 10.9098L12.2543 17.8341H17.4506L10.3851 7.55152H10.3855ZM8.2769 10.0025L7.58661 9.01519L2.09423 1.15893H4.45885L8.89126 7.49918L9.58155 8.48651L15.3432 16.7279H12.9785L8.2769 10.0029V10.0025Z"
                  fill="#2D2D2D"
                />
              </g>
              <defs>
                <clipPath id="clip0_284_19878">
                  <rect width="17.4506" height="17.8432" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </a>
        <a href="https://t.me/entravel_official">
          <div className="footer-social-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <g clipPath="url(#clip0_284_19882)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7767 19.548C16.053 19.7436 16.4092 19.7926 16.7267 19.6724C17.0442 19.5514 17.2776 19.2802 17.348 18.9516C18.0937 15.4468 19.9027 6.57606 20.5815 3.388C20.633 3.14772 20.5472 2.89799 20.3584 2.73752C20.1696 2.57704 19.9079 2.5307 19.6745 2.61738C16.0762 3.94924 4.99482 8.10701 0.465463 9.78299C0.17798 9.88941 -0.00909832 10.1657 0.000341421 10.4687C0.0106393 10.7725 0.214881 11.0359 0.509229 11.1243C2.54049 11.7319 5.20679 12.5772 5.20679 12.5772C5.20679 12.5772 6.45283 16.3402 7.10246 18.2539C7.18398 18.4942 7.37192 18.683 7.61993 18.7482C7.86708 18.8125 8.13139 18.7447 8.31589 18.5705C9.35941 17.5854 10.9728 16.0621 10.9728 16.0621C10.9728 16.0621 14.0381 18.3097 15.7767 19.548ZM6.3284 12.1017L7.76925 16.8542L8.08934 13.8447C8.08934 13.8447 13.6562 8.82358 16.8297 5.96162C16.9224 5.87752 16.9352 5.73678 16.858 5.63809C16.7816 5.5394 16.6409 5.51623 16.5353 5.58317C12.8573 7.93195 6.3284 12.1017 6.3284 12.1017Z"
                  fill="#2D2D2D"
                />
              </g>
              <defs>
                <clipPath id="clip0_284_19882">
                  <rect width="20.5958" height="20.5958" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </a>
        <a href="https://discord.com/invite/nHTjZ9sVAu">
          <div className="footer-social-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
            >
              <g clipPath="url(#clip0_284_19886)">
                <path
                  d="M19.0695 1.42888C17.5904 0.751744 16.029 0.27136 14.4251 0C14.2057 0.392346 14.0071 0.796018 13.8302 1.20933C12.1218 0.951883 10.3844 0.951883 8.67597 1.20933C8.49901 0.796061 8.30043 0.392394 8.08105 0C6.47614 0.273651 4.91372 0.755176 3.4332 1.43242C0.493983 5.78104 -0.302791 10.0217 0.095596 14.2021C1.81687 15.4738 3.74347 16.441 5.79165 17.0616C6.25284 16.4413 6.66093 15.7833 7.0116 15.0944C6.34555 14.8457 5.7027 14.5388 5.09049 14.1773C5.25161 14.0604 5.4092 13.94 5.56147 13.8232C7.34288 14.6609 9.28719 15.0953 11.2558 15.0953C13.2243 15.0953 15.1686 14.6609 16.95 13.8232C17.1041 13.9489 17.2617 14.0693 17.421 14.1773C16.8076 14.5394 16.1636 14.8469 15.4964 15.0962C15.8466 15.7847 16.2547 16.4422 16.7163 17.0616C18.7662 16.4435 20.6943 15.4768 22.4159 14.2038C22.8833 9.3559 21.6174 5.15425 19.0695 1.42888ZM7.51622 11.6311C6.40605 11.6311 5.48887 10.6237 5.48887 9.38423C5.48887 8.14481 6.37418 7.12848 7.51268 7.12848C8.65118 7.12848 9.56128 8.14481 9.5418 9.38423C9.52232 10.6237 8.64764 11.6311 7.51622 11.6311ZM14.9953 11.6311C13.8833 11.6311 12.9697 10.6237 12.9697 9.38423C12.9697 8.14481 13.855 7.12848 14.9953 7.12848C16.1356 7.12848 17.0386 8.14481 17.0191 9.38423C16.9996 10.6237 16.1267 11.6311 14.9953 11.6311Z"
                  fill="#2D2D2D"
                />
              </g>
              <defs>
                <clipPath id="clip0_284_19886">
                  <rect width="22.5115" height="17.0616" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </a>
      </div>
    </div>
  </section>
);

export default FooterPanel;
