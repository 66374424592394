import { createSelector } from 'reselect';
import _keyBy from 'lodash/keyBy';

import { ReduxState } from 'src/store/reducers';

export const settingsSelector = (state: ReduxState) => state.settings;

const hotelFacilitiesSelector = createSelector(
  settingsSelector,
  (settings) => settings.facilities ?? []
);

export const hotelFacilitiesMapSelector = createSelector(
  hotelFacilitiesSelector,
  (hotelFacilities) => _keyBy(hotelFacilities, 'id')
);

const hotelFacilityGroupsSelector = createSelector(
  settingsSelector,
  (settings) => settings.hotelFacilityGroups ?? []
);

export const hotelFacilityGroupsMapSelector = createSelector(
  hotelFacilityGroupsSelector,
  (hotelFacilityGroups) => _keyBy(hotelFacilityGroups, 'id')
);

export const countriesSelector = (state: ReduxState) =>
  state.settings.countries;

export const currenciesSelector = (state: ReduxState) =>
  state.settings.currencies;

export const localesSelector = (state: ReduxState) => state.settings.locales;
