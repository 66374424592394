import { VFC } from 'react';

const GoogleIcon: VFC = () => (
  <svg
    width="61"
    height="20"
    viewBox="0 0 61 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94346 0.207843C5.17541 0.409406 3.57416 1.18753 2.27315 2.48128C1.13416 3.61566 0.409788 4.97972 0.109553 6.55472C-0.0143528 7.20159 -5.58868e-05 8.51409 0.138147 9.15159C0.362132 10.1922 0.73385 11.0969 1.27237 11.8985C2.54479 13.8016 4.42244 14.9875 6.7433 15.3485C7.3676 15.4469 8.73533 15.4235 9.39776 15.3063C12.0141 14.8282 13.9489 13.2344 14.7448 10.8907C15.0212 10.0657 15.0831 9.64378 15.0784 8.47659C15.0784 7.88128 15.0546 7.33284 15.026 7.25784L14.9831 7.11722H11.4327H7.88705V8.14847V9.17972L10.4081 9.19378L12.9243 9.20316L12.891 9.47034C12.8481 9.82191 12.667 10.4313 12.5049 10.7782C11.7806 12.3344 10.003 13.3282 7.92994 13.3282C5.79018 13.3282 3.96494 12.2641 2.9594 10.4219C2.28268 9.17503 2.14448 7.49691 2.60198 6.05316C3.21674 4.13128 4.88471 2.68284 6.88151 2.33597C7.47244 2.23753 8.44463 2.24222 8.98315 2.35472C9.85049 2.53284 10.7274 2.93597 11.3469 3.44691C11.528 3.59691 11.69 3.71409 11.7091 3.71409C11.771 3.70472 13.1531 2.27972 13.1531 2.22816C13.1531 2.17191 12.5526 1.68441 12.1761 1.43597C10.6988 0.465655 8.73533 0.00159359 6.94346 0.207843Z"
      fill="currentColor"
    />
    <path
      d="M48.6665 7.89999L48.6808 15.1328L49.7674 15.1422L50.8492 15.1516V7.91405V0.671865H49.7531H48.657L48.6665 7.89999Z"
      fill="currentColor"
    />
    <path
      d="M20.4206 5.6219C19.9202 5.68284 19.5199 5.75784 19.2102 5.86096C17.0752 6.5594 15.7408 8.47659 15.8361 10.7032C15.9457 13.2297 17.8234 15.175 20.3587 15.386C22.4651 15.5594 24.2998 14.6125 25.2482 12.8594C26.0965 11.2891 26.0012 9.20315 25.0147 7.74534C24.7097 7.30471 24.114 6.71409 23.7041 6.45628C22.7367 5.8469 21.4119 5.5094 20.4206 5.6219ZM21.6597 7.66096C22.3126 7.85784 22.9845 8.42034 23.2943 9.02503C23.7613 9.92971 23.7518 11.125 23.28 12.011C22.2077 14.0172 19.315 13.9516 18.3142 11.8985C17.8996 11.0407 17.9139 9.94378 18.3428 9.08596C18.9623 7.85784 20.3491 7.26253 21.6597 7.66096Z"
      fill="currentColor"
    />
    <path
      d="M31.0956 5.64532C28.913 5.87032 27.1354 7.55313 26.7828 9.73282C26.7017 10.2297 26.7446 11.2281 26.8733 11.6969C27.2355 13.0563 28.0313 14.1063 29.1989 14.7625C29.8661 15.1375 30.4666 15.3156 31.2815 15.3859C33.1877 15.55 34.9129 14.7531 35.9327 13.2391C36.6905 12.1188 36.9335 10.4969 36.5475 9.10938C36.4188 8.64063 36.0471 7.89063 35.7755 7.53438C34.9749 6.49844 33.6834 5.79532 32.2966 5.64532C31.7867 5.58907 31.6485 5.58907 31.0956 5.64532ZM32.5015 7.6375C33.2116 7.83438 33.874 8.3875 34.2076 9.0625C34.8986 10.45 34.5174 12.1469 33.3355 12.9813C31.9725 13.9328 30.071 13.4828 29.2942 12.025C28.851 11.2 28.8034 10.1406 29.1608 9.25469C29.685 7.96563 31.1338 7.26719 32.5015 7.6375Z"
      fill="currentColor"
    />
    <path
      d="M41.9231 5.64532C41.4132 5.69689 41.0367 5.8047 40.5125 6.04376C38.8302 6.8172 37.7437 8.44376 37.6626 10.3281C37.6007 11.7578 38.0963 13.0281 39.0971 14.0078C40.1503 15.0391 41.5371 15.5453 42.8858 15.3906C43.6769 15.3016 44.2487 15.0485 44.8254 14.5328L45.1304 14.2563V14.7953C45.1304 15.7516 45.0017 16.3188 44.6776 16.8203C43.7436 18.2594 41.4418 18.2735 40.3505 16.8485C40.2313 16.6891 40.0931 16.4781 40.0502 16.375C40.0026 16.2719 39.9454 16.1875 39.9263 16.1875C39.9025 16.1875 39.4688 16.361 38.9589 16.5672L38.0296 16.9516L38.225 17.3078C38.8874 18.5172 39.9978 19.3891 41.294 19.7078C41.8469 19.8438 43.0955 19.8578 43.634 19.736C45.7976 19.2485 46.989 17.7438 47.1748 15.2735C47.2082 14.8844 47.2273 12.8406 47.2273 10.2672V5.92189H46.1788H45.1304V6.32032V6.71876L44.7873 6.43751C44.0248 5.8047 43.0144 5.52814 41.9231 5.64532ZM43.248 7.61876C43.6101 7.70782 44.0962 7.99845 44.4251 8.3172C45.0208 8.90314 45.3067 9.72814 45.2638 10.7219C45.2257 11.5469 44.9874 12.1281 44.4727 12.6766C43.7817 13.4125 42.738 13.675 41.7801 13.3563C40.6316 12.9766 39.9263 12.011 39.8548 10.7266C39.7405 8.68283 41.4084 7.15939 43.248 7.61876Z"
      fill="currentColor"
    />
    <path
      d="M55.8149 5.65002C54.4186 5.8422 53.089 6.78908 52.4361 8.05471C51.8356 9.20783 51.6974 10.7313 52.0691 12.0063C52.6267 13.9094 54.2232 15.1891 56.2867 15.3953C57.6878 15.5313 58.9888 15.1188 60.0754 14.186C60.3232 13.9703 60.8569 13.3844 60.8951 13.286C60.9046 13.2578 59.5416 12.3438 59.2557 12.1891C59.2271 12.1703 59.0984 12.2828 58.9745 12.4422C58.4265 13.1313 57.6783 13.4735 56.7347 13.4735C55.9102 13.4735 55.3288 13.2391 54.838 12.7188C54.5901 12.4563 54.2947 12.0297 54.3328 11.9969C54.3423 11.9922 54.6426 11.8656 54.9952 11.725C56.058 11.2938 60.9761 9.28752 60.9856 9.27814C61.019 9.25002 60.4614 8.05939 60.2803 7.75002C59.9848 7.26252 59.2319 6.51721 58.7124 6.20783C57.8737 5.70627 56.83 5.5047 55.8149 5.65002ZM57.2112 7.57189C57.4543 7.63283 57.7355 7.77346 57.9642 7.94689C58.1501 8.09221 58.4551 8.49064 58.4122 8.52814C58.3979 8.5422 57.6306 8.86564 56.7013 9.24533C55.7768 9.6297 54.7855 10.0375 54.5044 10.1547C53.9134 10.3985 53.9134 10.3985 53.9658 9.93908C54.0516 9.12346 54.5377 8.36877 55.2859 7.89064C55.8769 7.51564 56.5393 7.40314 57.2112 7.57189Z"
      fill="currentColor"
    />
  </svg>
);

export default GoogleIcon;
