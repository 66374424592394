import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import {
  CheckoutActionTypes,
  CreditCardTypes,
  LoadingTypes,
} from 'src/constants';
import {
  CheckoutBookAction,
  CheckoutBookFormFields,
  CheckoutBookSuccessAction,
  GetCheckoutInfoAction,
  SetPackageAction,
  UpdateCheckoutPackagePriceSuccessAction,
  UpdateCryptoNetworkSuccessAction,
  UpdateSelectedPaymentMethodSuccessAction,
} from 'src/store/checkout/actions';
import { ActionSuccess, Error, ErrorResponseAction } from 'src/models/actions';
import {
  CheckoutCheckResponse,
  CheckoutCreateResponse,
  CheckoutResponse,
} from 'src/models/checkout';
import { StripePaymentSecretResponse } from 'src/models/payments';
import { Nullable } from 'src/types';
import { allowedToConvertCurrenciesPayAtHotel } from 'src/configs';

export interface CheckoutState {
  isLoading: LoadingTypes;
  isCheckingFinished: boolean;
  isValidating: LoadingTypes;
  isLoadingStripePaymentSecret: LoadingTypes;
  checkoutId?: number;
  packageId?: string;
  info?: CheckoutResponse;
  error: Error[];
  providerType?: number;
  offerKey?: Nullable<string>;
  validPrice?: number;
  refundabilityChanged: boolean;
  creditCardFee?: number;
  stripePaymentSecret?: string;
  formFields?: CheckoutBookFormFields;
  selectedPaymentMethod?: number;
  selectedCryptoNetwork?: number;
}

const checkoutInitialState: CheckoutState = {
  isLoading: LoadingTypes.IDLE,
  isValidating: LoadingTypes.IDLE,
  isLoadingStripePaymentSecret: LoadingTypes.IDLE,
  isCheckingFinished: false,
  checkoutId: undefined,
  info: undefined,
  offerKey: undefined,
  validPrice: undefined,
  refundabilityChanged: false,
  creditCardFee: undefined,
  stripePaymentSecret: undefined,
  error: [],
  selectedPaymentMethod: 1,
  selectedCryptoNetwork: undefined,
};

export const checkoutReducer = createReducer(checkoutInitialState, {
  [CheckoutActionTypes.CREATE_CHECKOUT_REQUEST]: (state: CheckoutState) =>
    update(state, {
      isLoading: { $set: LoadingTypes.RUNNING },
      checkoutId: { $set: undefined },
      info: { $set: undefined },
      error: { $set: [] },
    }),
  [CheckoutActionTypes.CREATE_CHECKOUT_SUCCESS]: (
    state: CheckoutState,
    action: ActionSuccess<
      CheckoutActionTypes.CREATE_CHECKOUT_SUCCESS,
      CheckoutCreateResponse
    >
  ) => {
    const { payload } = action;

    return update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
      checkoutId: { $set: payload.checkoutId },
    });
  },
  [CheckoutActionTypes.CREATE_CHECKOUT_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.CREATE_CHECKOUT_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
      error: { $push: [error] },
    });
  },

  [CheckoutActionTypes.FETCH_CHECKOUT_REQUEST]: (
    state: CheckoutState,
    action: GetCheckoutInfoAction
  ) =>
    action.silent
      ? state
      : update(state, {
          isLoading: { $set: LoadingTypes.RUNNING },
          info: { $set: undefined },
          error: { $set: [] },
        }),
  [CheckoutActionTypes.FETCH_CHECKOUT_SUCCESS]: (
    state: CheckoutState,
    action: ActionSuccess<
      CheckoutActionTypes.FETCH_CHECKOUT_SUCCESS,
      CheckoutResponse
    >
  ) => {
    const { payload } = action;
    const { creditCardType, cancellationPolicies } = payload.internalPackage;
    const payAtHotel =
      creditCardType === CreditCardTypes.Guarantee ||
      creditCardType === CreditCardTypes.NotRequired;

    return update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
      info: {
        $set: {
          ...payload,
          internalPackage: {
            ...payload.internalPackage,
            cancellationPolicies: cancellationPolicies
              ? cancellationPolicies
              : state.info?.internalPackage.cancellationPolicies,
          },
        },
      },
      ...(payAtHotel
        ? {
            chargeCurrencies: {
              $set: allowedToConvertCurrenciesPayAtHotel,
            },
          }
        : {}),
    });
  },
  [CheckoutActionTypes.FETCH_CHECKOUT_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.FETCH_CHECKOUT_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isLoading: { $set: LoadingTypes.IDLE },
      error: { $push: [error] },
    });
  },
  [CheckoutActionTypes.CHECK_CHECKOUT_REQUEST]: (state: CheckoutState) =>
    update(state, {
      isCheckingFinished: { $set: false },
      info: {
        internalPackage: {
          cancellationPolicies: {
            $set: undefined,
          },
        },
      },
    }),
  [CheckoutActionTypes.CHECK_CHECKOUT_SUCCESS]: (
    state: CheckoutState,
    action: ActionSuccess<
      CheckoutActionTypes.CHECK_CHECKOUT_SUCCESS,
      CheckoutCheckResponse
    >
  ) => {
    const { payload } = action;
    const { validPrice, isFinished, taxAndFees } = payload;

    const additionalConfig =
      validPrice === 0 && isFinished && taxAndFees.length > 0
        ? { taxAndFees: { $set: payload.taxAndFees } }
        : {};

    return update(state, {
      isCheckingFinished: { $set: payload.isFinished },
      info: {
        internalPackage: {
          cancellationPolicies: { $set: payload.cancellation },
          traderInformation: { $set: payload.traderInformation },
          ...additionalConfig,
        },
      },
      validPrice: { $set: payload.validPrice },
      refundabilityChanged: { $set: payload.refundabilityChanged },
      creditCardFee: { $set: payload.creditCardFee },
      providerType: { $set: payload.providerType },
    });
  },
  [CheckoutActionTypes.CHECK_CHECKOUT_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.CHECK_CHECKOUT_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isCheckingFinished: { $set: true },
      error: { $push: [error] },
    });
  },
  [CheckoutActionTypes.SET_CHECKOUT_PACKAGE]: (
    state: CheckoutState,
    action: SetPackageAction
  ) =>
    update(state, {
      packageId: { $set: action.payload.packageId },
      checkoutId: { $set: undefined },
    }),
  [CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_SUCCESS]: (
    state: CheckoutState,
    action: UpdateCheckoutPackagePriceSuccessAction
  ) => {
    const refundability =
      state.info?.internalPackage.cancellationPolicies?.refundability;

    const additionalConfig =
      state.refundabilityChanged && refundability
        ? { refundability: { $set: refundability } }
        : {};

    return update(state, {
      info: {
        internalPackage: {
          salePrice: { $set: action.payload.salePrice },
          creditCardFee: { $set: action.payload.creditCardFee },
          taxAndFees: { $set: action.payload.taxAndFees },
          ...additionalConfig,
        },
      },
    });
  },
  [CheckoutActionTypes.BOOK_CHECKOUT_REQUEST]: (
    state: CheckoutState,
    action: CheckoutBookAction
  ) => {
    const { formFields } = action.params;

    let additionalConfig = {};

    if (formFields) {
      additionalConfig = {
        formFields: { $set: formFields },
      };
    }

    return update(state, {
      isValidating: { $set: LoadingTypes.RUNNING },
      error: { $set: [] },
      offerKey: { $set: undefined },
      validPrice: { $set: undefined },
      creditCardFee: { $set: undefined },
      providerType: { $set: undefined },
      ...additionalConfig,
    });
  },
  [CheckoutActionTypes.BOOK_CHECKOUT_SUCCESS]: (
    state: CheckoutState,
    action: CheckoutBookSuccessAction
  ) => {
    const { payload } = action;

    return update(state, {
      isValidating: { $set: LoadingTypes.IDLE },
      offerKey: { $set: payload.offerKey },
    });
  },
  [CheckoutActionTypes.BOOK_CHECKOUT_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.BOOK_CHECKOUT_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isValidating: { $set: LoadingTypes.IDLE },
      error: { $push: [error] },
    });
  },

  [CheckoutActionTypes.STRIPE_PAYMENT_SECRET_REQUEST]: (state: CheckoutState) =>
    update(state, {
      isLoadingStripePaymentSecret: { $set: LoadingTypes.RUNNING },
      stripePaymentSecret: { $set: undefined },
    }),
  [CheckoutActionTypes.STRIPE_PAYMENT_SECRET_SUCCESS]: (
    state: CheckoutState,
    action: ActionSuccess<
      CheckoutActionTypes.STRIPE_PAYMENT_SECRET_SUCCESS,
      StripePaymentSecretResponse
    >
  ) => {
    const { payload } = action;

    return update(state, {
      isLoadingStripePaymentSecret: { $set: LoadingTypes.IDLE },
      stripePaymentSecret: { $set: payload.clientSecret },
    });
  },
  [CheckoutActionTypes.STRIPE_PAYMENT_SECRET_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.STRIPE_PAYMENT_SECRET_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      isLoadingStripePaymentSecret: { $set: LoadingTypes.IDLE },
      stripePaymentSecret: { $set: undefined },
      error: { $push: [error] },
    });
  },

  [CheckoutActionTypes.BOOK_PAY_AT_HOTEL_FAILURE]: (
    state: CheckoutState,
    action: ErrorResponseAction<CheckoutActionTypes.BOOK_PAY_AT_HOTEL_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      error: { $push: [error] },
    });
  },

  [CheckoutActionTypes.SET_PAYMENT_METHOD]: (
    state: CheckoutState,
    action: UpdateSelectedPaymentMethodSuccessAction
  ) => update(state, {
      selectedPaymentMethod: { $set: action.payload.paymentMethod },
    }),

  [CheckoutActionTypes.SET_CRYPTO_NETWORK]: (
    state: CheckoutState,
    action: UpdateCryptoNetworkSuccessAction
  ) => update(state, {
      selectedCryptoNetwork: { $set: action.payload.cryptoNetwork },
    })
});
