import { VFC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import LogoIcon from 'src/icons/Logo';

// TODO: remove important after GlobalStyle refactoring
const StyledLogoLink = styled(Link)`
  color: inherit !important;
`;

interface ILogo {
  onClick?: () => void;
  isContrast?: boolean;
}

const Logo: VFC<ILogo> = ({ onClick, isContrast }) => (
  <StyledLogoLink to="/" title="Entravel" onClick={onClick}>
    <LogoIcon isContrast={isContrast} />
  </StyledLogoLink>
);

export default Logo;
