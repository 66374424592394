import { VFC } from 'react';

const WegoIcon: VFC = () => (
  <svg
    width="51"
    height="21"
    viewBox="0 0 51 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.3367 0.0988951C40.9839 0.311833 40.2887 0.606188 39.7438 1.1949C39.3493 1.62077 39.1363 2.02786 38.961 2.72304C38.5038 4.53928 38.5977 8.34084 39.1426 9.78131C39.4119 10.5078 39.9881 11.1654 40.6144 11.4723C40.9463 11.6414 41.5977 11.8293 42.2114 11.942C42.9254 12.0735 45.4806 12.086 46.3449 11.9671C47.5536 11.798 48.3115 11.4911 48.8438 10.9587C49.7832 10.0256 50.0901 8.52247 49.9774 5.35345C49.8772 2.66041 49.5327 1.53936 48.5995 0.844177C48.1549 0.524771 47.7227 0.34941 46.9399 0.174049C46.1696 0.00495148 43.2448 -0.0451508 42.3367 0.0988951Z"
      fill="#A3D108"
    />
    <path
      d="M42.3367 0.0988951C40.9839 0.311832 40.2887 0.606188 39.7438 1.1949C39.3493 1.62077 39.1363 2.02786 38.961 2.72304C38.5038 4.53928 38.5977 8.34084 39.1426 9.78131C39.4119 10.5078 39.9881 11.1654 40.6144 11.4723C40.9463 11.6414 41.5977 11.8293 42.2114 11.942C42.9254 12.0735 45.4806 12.086 46.3449 11.9671C47.5536 11.798 48.3115 11.4911 48.8438 10.9587C49.7832 10.0256 50.0901 8.52247 49.9774 5.35345C49.8772 2.66041 49.5327 1.53936 48.5996 0.844177C48.1549 0.524771 47.7227 0.34941 46.9399 0.174049C46.1696 0.00495148 43.2448 -0.0451517 42.3367 0.0988951ZM46.7082 1.18237C47.9795 1.50804 48.5056 2.02786 48.8375 3.2867C48.9565 3.75015 48.9941 4.1134 49.0442 5.35345C49.1256 7.43273 48.9941 8.6853 48.5996 9.4619C48.3929 9.86272 47.9232 10.3262 47.5161 10.5329C46.8084 10.8898 46.1946 10.9775 44.4034 10.9838C42.9442 10.99 42.7563 10.9775 42.2427 10.846C40.4829 10.4076 39.9192 9.59342 39.7063 7.201C39.5372 5.28456 39.725 3.39317 40.1697 2.52263C40.4328 1.99028 41.0152 1.50804 41.6039 1.31389C42.368 1.06964 43.0318 1.00701 44.6226 1.0258C45.9691 1.04459 46.2572 1.06338 46.7082 1.18237Z"
      fill="#3A870B"
    />
    <path
      d="M45.9254 8.93472C46.0566 9.17528 46.3822 9.22217 46.576 9.02841C46.6788 8.92555 46.7196 8.77595 46.6831 8.63516L45.7726 5.12358L47.2959 3.60035C47.5257 3.37048 47.5257 2.99937 47.2959 2.7695C47.066 2.53963 46.6949 2.53963 46.465 2.7695L44.9418 4.29273L41.5821 3.42169C41.3472 3.36079 41.0976 3.42876 40.926 3.60035C40.7877 3.7387 40.8211 3.97116 40.9929 4.06485L43.6955 5.539L42.1723 7.06223L41.2687 6.83633C41.142 6.80466 41.008 6.84178 40.9156 6.93412C40.769 7.08074 40.7707 7.31898 40.9193 7.46354L42 8.51442L42.6031 9.14347C42.7467 9.29322 42.9852 9.29573 43.1319 9.14904C43.2238 9.05712 43.2608 8.92371 43.2293 8.7976L43.0031 7.89308L44.5264 6.36985L45.9254 8.93472Z"
      fill="white"
    />
    <path
      d="M27.8255 4.25744C27.719 4.29502 27.5562 4.40775 27.4685 4.51422C27.3245 4.68332 27.3057 4.75848 27.3057 5.20314C27.3057 6.08621 27.5123 6.26157 28.5332 6.26157C29.435 6.26157 29.5854 6.13631 29.648 5.32213C29.6918 4.83989 29.5979 4.53927 29.3599 4.36391C29.2284 4.26371 29.0718 4.23866 28.6083 4.21987C28.2889 4.20734 27.9382 4.22613 27.8255 4.25744Z"
      fill="white"
    />
    <path
      d="M15.0309 6.38682C13.0706 6.51834 12.2189 7.10079 11.8556 8.55377C11.7241 9.09238 11.7053 11.2593 11.8306 11.8919C12.0873 13.1883 12.7136 13.8209 14.0601 14.134C14.4735 14.2279 14.7929 14.2467 15.9077 14.2342C17.8304 14.2217 18.6571 14.0463 19.2521 13.514C19.6091 13.2071 19.8157 12.8125 19.9159 12.2739C19.9535 12.0485 19.9974 11.8167 20.0099 11.7541C20.0287 11.6539 19.9285 11.6476 18.632 11.6476H17.2417L17.1978 11.8919C17.1477 12.2238 16.9411 12.3741 16.415 12.4618C15.914 12.5432 15.1749 12.4681 14.9244 12.3052C14.6927 12.1487 14.5424 11.7478 14.5173 11.2092L14.4985 10.7395L17.2417 10.7207L19.9786 10.7082V10.0256C19.9786 9.25522 19.8533 8.44104 19.6529 7.9087C19.465 7.40767 18.9515 6.89411 18.4442 6.70622C17.6613 6.41187 16.4025 6.29287 15.0309 6.38682ZM16.8095 8.09658C17.0475 8.25316 17.1603 8.45983 17.2041 8.82308L17.2417 9.14248H15.8826H14.5299V8.96712C14.5299 8.50367 14.7616 8.13416 15.1311 8.01517C15.438 7.91496 16.6217 7.97759 16.8095 8.09658Z"
      fill="white"
    />
    <path
      d="M31.7524 6.48703C30.4811 6.70623 29.9299 7.24484 29.6794 8.52247C29.5667 9.07987 29.5917 11.5662 29.717 12.1612C29.9675 13.3762 30.5437 13.9273 31.8151 14.1904C32.4789 14.3219 34.8526 14.2843 35.3536 14.134C36.1678 13.8898 36.5811 13.5641 36.8567 12.9566C37.1135 12.3741 37.1949 11.7666 37.2011 10.3951C37.2011 9.10492 37.151 8.62268 36.9506 7.94628C36.7126 7.15716 36.0801 6.65613 35.0968 6.48077C34.4392 6.36178 32.4601 6.36804 31.7524 6.48703ZM34.1949 8.23438C34.5582 8.42226 34.6521 8.76046 34.6835 9.98798C34.7148 11.2844 34.6271 12.0046 34.4079 12.2739C34.2137 12.4994 33.7565 12.6121 33.2179 12.5683C32.7608 12.537 32.4601 12.3867 32.3036 12.1111C32.1407 11.8293 32.028 10.3011 32.1157 9.48069C32.2159 8.535 32.3662 8.27195 32.8985 8.13417C33.1992 8.05902 33.9632 8.11538 34.1949 8.23438Z"
      fill="white"
    />
    <path
      d="M22.9595 6.54966C21.5191 6.83149 20.9805 7.72081 20.9805 9.80009C20.9805 10.5454 21.0055 10.8585 21.1057 11.2343C21.5066 12.8125 22.3646 13.2823 24.8635 13.2823C27.4563 13.2885 28.2955 12.7249 28.5648 10.8022C28.6525 10.1759 28.5961 8.86692 28.4646 8.35963C28.1828 7.28868 27.7381 6.81896 26.7799 6.57471C26.3478 6.46824 26.0972 6.44945 24.8384 6.45571C23.8864 6.46198 23.2414 6.49329 22.9595 6.54966ZM25.5962 8.178C25.6902 8.2469 25.8342 8.43478 25.9156 8.59135C26.0471 8.84813 26.0597 8.96086 26.0597 9.85646C26.0659 10.7646 26.0534 10.8523 25.9219 11.1278C25.7027 11.56 25.4647 11.679 24.8008 11.679C24.2184 11.679 23.9553 11.5787 23.7236 11.2656C23.5608 11.0527 23.4668 10.3262 23.4981 9.48695C23.5232 8.66025 23.6547 8.35337 24.0743 8.14043C24.4313 7.9588 25.3269 7.98386 25.5962 8.178Z"
      fill="white"
    />
    <path
      d="M0 6.90664C0 8.04648 0.645077 11.6101 1.12732 13.1006L1.32147 13.7144H2.94355C3.85167 13.7144 4.57816 13.6893 4.59695 13.658C4.622 13.6267 4.80363 12.8 5.01657 11.8293C5.34224 10.2949 5.50507 9.68735 5.51133 9.92534C5.51133 10.0005 6.25035 13.4451 6.30046 13.6017C6.33177 13.7081 6.46329 13.7144 7.96012 13.7144H9.58847L9.71373 13.4138C10.0519 12.6372 10.8348 8.63519 10.9412 7.18847L10.9788 6.61854L9.83898 6.65612C9.20643 6.67491 8.68035 6.70622 8.66156 6.72501C8.64277 6.73754 8.58014 7.15715 8.51752 7.65192C8.41105 8.4473 8.3171 9.12369 7.98517 11.2656C7.93507 11.585 7.88496 11.8606 7.87244 11.8794C7.85991 11.8919 7.69708 11.2155 7.51546 10.3825C7.25241 9.1738 6.62613 6.7939 6.55097 6.71875C6.50713 6.66865 4.92889 6.63733 4.67211 6.68117L4.35896 6.73128L4.1836 7.32625C4.0834 7.65192 3.80783 8.75419 3.56358 9.76877C3.32559 10.7834 3.10639 11.6602 3.0876 11.7103C3.06881 11.7666 3.02497 11.6852 2.99366 11.5224C2.92476 11.1403 2.46131 7.99638 2.37989 7.29493C2.34232 6.98805 2.27969 6.71249 2.23585 6.68743C2.19201 6.66238 1.67219 6.63733 1.08348 6.63733H0V6.90664Z"
      fill="white"
    />
    <path
      d="M23.0163 14.1591C20.8682 14.2843 20.0665 14.6601 19.7471 15.706C19.5843 16.2258 19.5843 18.1673 19.7471 18.6621C20.1542 19.8959 21.2064 20.2904 24.1249 20.2967C26.5173 20.3029 27.7573 20.065 28.3335 19.5013C28.7782 19.0566 28.9723 18.2049 28.9222 16.8959C28.8659 15.5306 28.6028 14.9607 27.8513 14.5912C27.3002 14.3219 26.7302 14.2217 25.3461 14.1528C24.6885 14.1215 24.0998 14.0964 24.0497 14.1027C23.9996 14.1027 23.5362 14.134 23.0163 14.1591ZM25.8409 16.1193C26.3357 16.2759 26.4484 16.4387 26.4985 17.0838C26.5862 18.1673 26.2856 18.3802 24.5821 18.4554C23.58 18.493 22.9099 18.4178 22.484 18.2049C22.1082 18.0233 21.983 17.7665 21.983 17.1903C21.983 16.4638 22.2147 16.2195 23.0727 16.0567C23.674 15.944 25.4025 15.9816 25.8409 16.1193Z"
      fill="white"
    />
  </svg>
);

export default WegoIcon;
