import { FC, ComponentType, ReactHTML } from 'react';
import { FormattedNumber } from 'react-intl';

import { FormattedNumberType } from 'src/types';

type HTMLTags = keyof ReactHTML;
export type TagComponentType = ComponentType | HTMLTags;

interface FormattedPriceProps extends Omit<FormattedNumberType, 'style'> {
  tagComponent?: TagComponentType;
}

export const FormattedPrice: FC<FormattedPriceProps> = ({
  tagComponent: TagComponent,
  ...rest
}) => {
  // eslint-disable-next-line react/style-prop-object
  const c = (
    <span>
      <FormattedNumber style="currency" {...rest} />
    </span>
  );
  return TagComponent ? <TagComponent>{c}</TagComponent> : c;
};
