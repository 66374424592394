import { useState } from 'react';

import { useWithScroll, ScrollDirection } from 'src/hooks/useWithScroll';

export const useIsPageTopOrShouldCollapse = () => {
  const [isPageTop, setIsPageTop] = useState(window.pageYOffset === 0);
  const [isCollapsed, setDetailsCollapsed] = useState(false);

  const handleScroll = (offset: number, direction: ScrollDirection) => {
    const correctOffset = Math.max(offset, 0);
    const isPageTopNow = correctOffset === 0;

    if (isPageTopNow !== isPageTop) {
      setIsPageTop(isPageTopNow);

      if (isPageTopNow && isCollapsed) {
        setDetailsCollapsed(false);
      }
    }

    if (correctOffset !== 0 && direction !== ScrollDirection.none) {
      const isNextCollapsed = direction === ScrollDirection.down;
      if (isNextCollapsed !== isCollapsed) {
        setDetailsCollapsed(isNextCollapsed);
      }
    }
  };
  useWithScroll(handleScroll);

  return { isPageTop, isCollapsed };
};
