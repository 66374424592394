import { VFC } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const changeOpacity = keyframes`
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 0.1;
  }
`;

const Dot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  animation: ${changeOpacity};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const LeftDot = styled(Dot)`
  margin-right: 10px;
  background: ${(p) => p.theme.custom.primaryColor};
`;

const RightDot = styled(Dot)<{ whiteRightDot?: boolean }>`
  background-color: ${(p) =>
    p.whiteRightDot
      ? p.theme.custom.whiteMainColor
      : p.theme.custom.darkMainColor};
  animation-delay: 0.5s;
`;

interface LoaderProps {
  className?: string;
  whiteRightDot?: boolean;
}

const Loader: VFC<LoaderProps> = ({ className, whiteRightDot }) => (
  <Wrapper className={className}>
    <LeftDot />
    <RightDot whiteRightDot={whiteRightDot} />
  </Wrapper>
);

export default Loader;
