import { memo, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { intlSelector } from 'src/store/intl/selectors';

export const ConnectedIntl = memo<Required<PropsWithChildren<{}>>>(
  ({ children }) => {
    const { currentLocale, messages } = useSelector(intlSelector);

    return (
      <IntlProvider messages={messages[currentLocale]} locale={currentLocale}>
        {children}
      </IntlProvider>
    );
  }
);
