import { FC, memo } from 'react';

import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { StyledPanel } from '../common';

import { Facilities } from './Facilities';

interface HotelFacilitiesProps {
  hotelFacilities: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
  sectionRef?: (el: HTMLDivElement | null) => void;
  className?: string;
}

export const HotelFacilities: FC<HotelFacilitiesProps> = memo((props) => {
  const { hotelFacilities, sectionRef } = props;

  if (!hotelFacilities?.length) {
    return null;
  }

  return (
    <div ref={sectionRef} data-id="facilities">
      <StyledPanel title={<I18n id="HOTEL_DETAILS_PAGE.FACILITIES.TITLE" />}>
        <Facilities data={hotelFacilities} />
      </StyledPanel>
    </div>
  );
});
