import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import _isFunction from 'lodash/isFunction';

import FavoriteButton from 'src/components/FavoriteButton';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { breakPoints } from 'src/configs';

import { getPreviewThumbnails } from './utils';
import { HotelPreviewProps } from './type';
import { ImagesCount } from './ImagesCount';
import { NoImagePlaceholder } from './NoImagePlaceholder';
import { ImageComponent } from './common';
import { HotelPreviewMapButton } from './HotelPreviewMapButton';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const StyledNoImagePlaceholder = styled(NoImagePlaceholder)``;

const StyledImageComponent = styled(ImageComponent)`
  height: 100%;
  padding-bottom: 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  float: left;
  border-left: 5px solid ${({ theme }) => theme.custom.whiteMainColor};
  border-top: 5px solid ${({ theme }) => theme.custom.whiteMainColor};
  height: 50%;

  &:first-child {
    height: 100%;
    width: 58%;
    border-left: 0;
    border-top: 0;
  }

  &:nth-child(2) {
    width: 42%;
    border-top: 0;
  }

  &:nth-child(3),
  &:nth-child(4) {
    width: 21%;
  }

  &:not(:first-child) {
    ${StyledNoImagePlaceholder} {
      .icon-room {
        font-size: 35px;
      }
    }
  }
`;

export const HotelPreviewLarge: FC<HotelPreviewProps> = (props) => {
  const {
    images,
    className,
    toggleModal,
    id,
    hotelId,
    trackingId,
    imagesCount,
    onMapButtonClick,
  } = props;
  const isTabletDesktop = useMatchMedia(`(min-width: ${breakPoints.md}px)`);
  const previewThumbnails = useMemo(
    () => getPreviewThumbnails(images, isTabletDesktop ? 3 : 1),
    [images, isTabletDesktop]
  );

  const handleImageClick = (index: number) => () => {
    if (previewThumbnails[index].url && _isFunction(toggleModal)) {
      toggleModal(index);
    }
  };

  return (
    <Wrapper className={className} data-id={id}>
      {previewThumbnails.map((image, index, arr) => {
        const isFirst = index === 0;
        const isLast = index === arr.length - 1;
        const withFavoriteButton = isFirst && Boolean(hotelId);

        return (
          <ImageWrapper key={image.id}>
            <StyledImageComponent
              image={image}
              onClick={handleImageClick(index)}
              trackingId={trackingId}
              placeholder={<StyledNoImagePlaceholder showText={isFirst} />}
            >
              {isLast && image.url && (
                <ImagesCount imagesCount={imagesCount ?? images.length} />
              )}
            </StyledImageComponent>
            {/* {withFavoriteButton && <FavoriteButton hotelId={hotelId!} />} */}
          </ImageWrapper>
        );
      })}
      <ImageWrapper>
        <StyledImageComponent
          image={{
            url: '/assets/i/map-btn-background.svg',
            id: 'hotel-preview-map-button',
          }}
        >
          <HotelPreviewMapButton onClick={onMapButtonClick} />
        </StyledImageComponent>
      </ImageWrapper>
    </Wrapper>
  );
};
