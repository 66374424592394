import { VFC } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { SearchParamsState } from 'src/store/search/reducers';
import { CalendarProvider } from 'src/components/Calendar/CalendarProvider';
import { intlSelector } from 'src/store/intl/selectors';

import { FieldsName, SearchPanelInputProps } from '../..';

import { getInputFormat } from './utils';
import { DateRangeInput } from './DateRangeInput';

export const DatesChooser: VFC<SearchPanelInputProps> = (props) => {
  const { currentLocale } = useSelector(intlSelector);

  const inputFormat = getInputFormat(currentLocale);

  return (
    <CalendarProvider locale={currentLocale}>
      <Controller<SearchParamsState, FieldsName.DATES>
        render={({ field }) => (
          <DateRangeInput {...field} inputFormat={inputFormat} {...props} />
        )}
        name={FieldsName.DATES}
      />
    </CalendarProvider>
  );
};
