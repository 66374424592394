import { FC } from 'react';

import { toI18nKey } from 'src/modules/helpers';
import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { ItemWrapper, Item } from './common';

interface FacilitiesItemProps {
  facilities: NonNullable<HotelInfoSelectorResult>['facilityGroups'][0]['facilities'];
}

export const FacilitiesItem: FC<FacilitiesItemProps> = ({ facilities }) => (
  <>
    {facilities.map((facilitySubItem) => (
      <ItemWrapper key={facilitySubItem.name}>
        <Item>
          <I18n id={toI18nKey(facilitySubItem.name, 'FACILITIES')} />
        </Item>
      </ItemWrapper>
    ))}
  </>
);
