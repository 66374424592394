import styled from 'styled-components/macro';
import { ComponentProps } from 'react';

type Props = Omit<ComponentProps<'button'>, 'children'>;

export const CCloseButton = styled.button<Props>`
  -webkit-appearance: none;
  padding: 0;
  border: 0;

  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.custom.primaryColor};
  cursor: pointer;
  transition: opacity 0.3s ${(p) => p.theme.custom.defaultEasing};

  &::before {
    content: '\\e904';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'icon' !important;
    font-size: 8px;
    color: ${(p) => p.theme.custom.reverseTextColor};
  }

  &:hover {
    opacity: 0.8;
  }
`;
