import { FC, memo } from 'react';
import _isArray from 'lodash/isArray';
import { useSelector } from 'react-redux';

import {
  FormattedPrice,
  TagComponentType,
} from 'src/components/FormattedPrice';
import { convertSumFromBaseCurrency } from 'src/modules/helpers';
import { currencySelector } from 'src/store/currency/selectors';

interface PriceDisplayProps {
  valuesInEur: number | number[];
  roundValue?: boolean;
  preventConvertExcept?: string[];
  currencyData?: {
    rate: number;
    code: string;
  };
  tagComponent?: TagComponentType;
}

export const PriceDisplay: FC<PriceDisplayProps> = memo(
  ({
    valuesInEur,
    tagComponent,
    roundValue,
    preventConvertExcept,
    currencyData,
  }) => {
    const values = _isArray(valuesInEur) ? valuesInEur : [valuesInEur];

    const { currentRate, currentCurrency } = useSelector(currencySelector);

    let [currencyRate, currencyCode] = currencyData
      ? [currencyData.rate, currencyData.code]
      : [currentRate, currentCurrency];

    if (preventConvertExcept && !preventConvertExcept.includes(currencyCode)) {
      currencyCode = 'EUR';
      currencyRate = 1;
    }

    const precision = roundValue ? 0 : 2;

    const value = convertSumFromBaseCurrency(
      values,
      currencyRate,
      precision,
      roundValue ? 'ceil' : 'nearest'
    );

    const propsForRounding = roundValue
      ? {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }
      : {};

    return (
      <FormattedPrice
        value={value}
        currency={currencyCode}
        tagComponent={tagComponent}
        {...propsForRounding}
      />
    );
  }
);
