import { VFC } from 'react';

const TrivagoIcon: VFC = () => (
  <svg
    width="58"
    height="18"
    viewBox="0 0 58 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1387 4.05H22.1887L24.5524 10.275H24.6287L26.7637 4.05H29.6612L26.0012 13.2H22.9512L19.1387 4.05Z"
      fill="currentColor"
    />
    <path
      d="M7.93 6.3H5.10875V9.375C5.10875 9.9 5.185 10.575 5.6425 10.875C6.1 11.175 6.78625 11.1 7.32 11.025C7.625 11.025 7.7775 10.95 7.93 10.8V13.125C7.54875 13.2 7.24375 13.275 6.8625 13.35C4.88 13.575 1.9825 13.2 1.9825 10.65V6.3H0V4.05H1.9825V1.35H5.10875V4.05H7.93V6.3ZM15.9362 4.05H18.7575V13.2H15.9362V4.05ZM15.7838 1.575C15.7838 0.749997 16.47 0.0749969 17.3088 0.0749969C18.1475 0.0749969 18.8337 0.749997 18.8337 1.575C18.8337 2.4 18.1475 3.075 17.3088 3.075C16.5462 3.075 15.7838 2.4 15.7838 1.575Z"
      fill="currentColor"
    />
    <path
      d="M51.1638 8.625C51.1638 9.825 51.9263 10.95 53.2225 10.95C54.595 10.95 55.2813 9.9 55.2813 8.625C55.2813 7.425 54.5188 6.3 53.2225 6.3C51.85 6.3 51.1638 7.35 51.1638 8.625ZM48.5713 8.625C48.5713 6.675 49.6388 4.8 51.545 4.125C53.4513 3.45 55.7388 3.975 56.9588 5.55C58.1025 7.05 58.255 9.3 57.34 11.025C56.425 12.825 54.29 13.65 52.3075 13.275C49.9438 12.9 48.5713 10.875 48.5713 8.625Z"
      fill="currentColor"
    />
    <path
      d="M45.5212 3.975V5.25H45.4449C45.3687 5.175 45.2924 5.1 45.1399 4.875C43.3099 3.3 40.1074 3.6 38.9637 5.85C38.3537 6.975 38.2774 8.4 38.4299 9.675C38.5824 10.8 39.0399 12.075 40.0312 12.75C41.1749 13.5 42.6999 13.65 43.9962 13.125C44.6062 12.9 44.9874 12.45 45.1399 12.225V13.125C45.1399 13.8 44.9874 14.325 44.6062 14.7C43.7674 15.525 42.2424 15.45 41.1749 15.225C40.6412 15.075 40.0312 14.85 39.4974 14.475L38.2012 16.5C38.9637 17.025 39.6499 17.325 40.4124 17.55C42.4712 18.075 45.2924 18.075 46.8174 16.5C47.7324 15.6 48.0374 14.325 48.0374 12.525V3.975H45.5212ZM44.8349 10.275C44.0724 11.175 42.5474 11.175 41.7849 10.275C41.0224 9.375 41.0224 7.8 41.7849 6.9C42.5474 6 44.0724 6 44.8349 6.9C45.5974 7.8 45.5974 9.375 44.8349 10.275C44.4537 10.725 45.2162 9.825 44.8349 10.275Z"
      fill="currentColor"
    />
    <path
      d="M8.69246 4.05H11.59V5.55C11.895 4.95 12.2762 4.575 12.6575 4.275C13.5725 3.675 15.0212 3.9 15.1737 3.975V6.525C14.5637 6.375 13.3437 6.15 12.505 6.675C11.9712 6.975 11.5137 7.425 11.5137 9.45V13.275H8.61621V4.05H8.69246Z"
      fill="currentColor"
    />
    <path
      d="M32.3301 9.525C31.7963 9.9 31.3388 10.575 31.8726 11.1C32.4063 11.625 33.3213 11.55 33.9313 11.325C34.6176 11.1 35.1513 10.575 35.1513 9.75V9.15C35.2276 9.225 33.0926 9.075 32.3301 9.525ZM35.2276 12.075C34.8463 12.525 34.4651 12.9 33.9313 13.125C31.8726 13.95 28.9751 13.125 28.9751 10.65C28.9751 8.025 31.8726 7.5 33.9313 7.425H35.2276C35.2276 6.9 35.0751 6.525 34.6938 6.225C34.0076 5.625 32.9401 5.7 32.1013 6.075C31.7201 6.3 31.3388 6.525 31.0338 6.9L29.5088 5.325C30.0426 4.875 30.6526 4.5 31.4151 4.2C32.6351 3.75 34.1601 3.675 35.3801 4.05C36.6001 4.35 37.3626 5.325 37.6676 6.525C37.8201 7.125 37.8201 7.8 37.8201 8.55V13.2H35.2276V12.075Z"
      fill="currentColor"
    />
  </svg>
);

export default TrivagoIcon;
