import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import {
  Room,
  HotelInfo,
  HotelRoomsResponse,
  HotelResponse,
} from 'src/models/hotels';
import { Nullable } from 'src/types';
import {
  ActionSuccess,
  ActionFailure,
  ErrorResponseAction,
  Error,
} from 'src/models/actions';
import { HotelAction } from 'src/store/hotel/actions';

import { ActionTypes, LoadingTypes } from '../../constants';
import { CtDomainMarketingPricePresentation } from '../../models/hotels/index';

export interface HotelState {
  guaranteedOfferRoom?: HotelRoomsResponse['hotelDetails']['guaranteedOfferRoom'];
  status: LoadingTypes;
  detailsStatus: LoadingTypes;
  rooms: Room[];
  totalNights?: number;
  isFinished: boolean;
  hasImages: boolean;
  info?: HotelInfo;
  hotelId: number;
  error: Nullable<Error>;
  isRoomsSearchStarted: boolean;
  pricePresentation: CtDomainMarketingPricePresentation;
}

export const hotelsInitialState: HotelState = {
  status: LoadingTypes.IDLE,
  detailsStatus: LoadingTypes.IDLE,
  rooms: [],
  isFinished: false,
  hasImages: false,
  hotelId: 0,
  error: null,
  isRoomsSearchStarted: false,
  pricePresentation: CtDomainMarketingPricePresentation.IncludedTaxes,
};

export const hotel = createReducer(hotelsInitialState, {
  [ActionTypes.FETCH_HOTEL_ROOMS_REQUEST]: (state: HotelState) => {
    const updateConfig: any = {
      status: { $set: LoadingTypes.RUNNING },
      rooms: { $set: [] },
      isFinished: { $set: false },
      isRoomsSearchStarted: { $set: true },
      error: { $set: undefined },
    };

    return update(state, updateConfig);
  },

  [ActionTypes.FETCH_HOTEL_ROOMS_SUCCESS]: (
    state: HotelState,
    action: ActionSuccess<
      ActionTypes.FETCH_HOTEL_ROOMS_SUCCESS,
      HotelRoomsResponse
    >
  ) => {
    const { payload } = action;
    const {
      hotelDetails: { rooms, guaranteedOfferRoom },
      isFinished,
      hasImages,
      pricePresentation,
      totalNights,
    } = payload;

    return update(state, {
      status: { $set: LoadingTypes.IDLE },
      rooms: { $set: rooms },
      guaranteedOfferRoom: { $set: guaranteedOfferRoom },
      totalNights: { $set: totalNights },
      isFinished: { $set: isFinished },
      hasImages: { $set: hasImages },
      pricePresentation: { $set: pricePresentation },
      error: { $set: null },
    });
  },

  [ActionTypes.FETCH_HOTEL_ROOMS_FAILURE]: (
    state: HotelState,
    action: ErrorResponseAction<ActionTypes.FETCH_HOTEL_ROOMS_FAILURE>
  ) => {
    const { error } = action;

    return update(state, {
      status: { $set: LoadingTypes.IDLE },
      error: { $set: error },
      isFinished: { $set: true },
    });
  },

  [ActionTypes.FETCH_HOTEL_REQUEST]: (state: HotelState, action: HotelAction) =>
    action.silent
      ? update(state, {
          detailsStatus: { $set: LoadingTypes.RUNNING },
        })
      : update(state, {
          detailsStatus: { $set: LoadingTypes.RUNNING },
          status: { $set: LoadingTypes.RUNNING },
          info: { $set: undefined },
        }),

  [ActionTypes.FETCH_HOTEL_SUCCESS]: (
    state: HotelState,
    action: ActionSuccess<ActionTypes.FETCH_HOTEL_SUCCESS, HotelResponse>
  ) => {
    const { payload } = action;
    return update(state, {
      detailsStatus: { $set: LoadingTypes.IDLE },
      status: { $set: LoadingTypes.IDLE },
      hotelId: { $set: payload.id },
      info: {
        $set: {
          ...payload,
          images: payload.images.map((imageId) => ({
            id: imageId,
            url: imageId,
          })),
        },
      },
      error: { $set: null },
    });
  },

  [ActionTypes.FETCH_HOTEL_FAILURE]: (
    state: HotelState,
    action: ActionFailure<ActionTypes.FETCH_HOTEL_FAILURE, Error>
  ) => {
    const { error } = action;

    return update(state, {
      detailsStatus: { $set: LoadingTypes.IDLE },
      error: { $set: error },
    });
  },
});
