const colors = {
  greyMainColor: '#757588',
  greySecondaryColor: '#EEEFF2',
  greyAlternativeColor: '#D0D0DB',
  greyLightColor: '#EBECF4',
  greyUltraLightColor: '#FAFAFA',
  whiteMainColor: '#FFF',
  greenMainColor: '#048D3B',
  greenSecondaryColor: '#12A84E',
  blackMainColor: '#23232C',
  blueMainColor: '#2539EB',
  blackSecondaryColor: '#3C3C48',
  orangeMainColor: '#FC5110',
  orangeSecondaryColor: '#DD3E02',
  orangeTr: '#FEDCCF',
  orangeAlternativeColor: '#F46311',
  redMainColor: '#FF1B1E',
  redLightColor: '#FFE8E9',
  darkMainColor: '#171723',

  new_greenMainColor: '#05A8A8',
  new_orangeSecondaryColor: '#006ce4',
  new_orangeMainColor: '#006ce4',
  new_grayAdditionalColor: '#F8F8F8',
  new_greySecondaryColor: '#E4E4E7',
  new_greyMainColor: '#C4C4C4',
  new_blackAdditionalColor: '#797979',
  new_blackSecondaryColor: '#767E83',
  new_blackMainColor: '#000000',
  new_whiteMainColor: '#FFF',
  new_redMainColor: '#FF1B1E',
} as const;

export default colors;
