import { FC, useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import _random from 'lodash/random';

import usePrevious from 'src/hooks/usePrevious';

const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: ${(p) => p.theme.custom.greyAlternativeColor};
`;

const gradientAnimation = keyframes`
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
`;

const ProgressLine = styled.div<{ percent: number; isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #D0D0DB;
  width: ${(p) => p.percent * 100}%;

  ${(p) =>
    p.isActive &&
    css`
      transition: width 1s ${p.theme.custom.defaultEasing};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #009962;
        border-radius: ${p.theme.custom.borderRadiusBase};
        opacity: 0;
        animation: ${gradientAnimation} 1.5s ${p.theme.custom.defaultEasing}
          infinite;
      }
    `}
`;

const isProgressActive = (percent: number) => percent !== 0 && percent !== 1;

interface ProgressProps {
  className?: string;
  isFinished?: boolean;
}

const MAX_PERCENT_VALUE = 1;

export const Progress: FC<ProgressProps> = (props) => {
  const { className, isFinished } = props;
  const [percent, setPercent] = useState(isFinished ? 1 : 0);
  const prevIsFinished = usePrevious(isFinished);
  const isActive = isProgressActive(percent);

  useEffect(() => {
    const increment = () => {
      const randomPercent = _random(0, (MAX_PERCENT_VALUE - percent) / 3, true);
      const nextPercent = percent + randomPercent;
      setPercent(nextPercent);
    };
    let interval: NodeJS.Timeout;

    if (isFinished) {
      setPercent(1);
    } else {
      interval = setInterval(increment, 1000);

      if (prevIsFinished !== isFinished) {
        setPercent(0);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [isFinished, percent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProgressContainer className={className}>
      <ProgressLine percent={percent} isActive={isActive} />
    </ProgressContainer>
  );
};
