import { lazy } from 'react';

const CheckoutPage = lazy(() => import('./Checkout'));
const ConfirmationPage = lazy(() => import('./Confirmation'));
const ContactPage = lazy(() => import('./Contact'));
const HomePage = lazy(() => import('./Home'));
const NoMatchPage = lazy(() => import('./NoMatch'));
const NotAuthenticatedPage = lazy(() => import('./NotAuthenticated'));
const SearchPage = lazy(() => import('./Search'));
const HotelDetailsPage = lazy(() => import('./HotelDetails'));
const StaticPage = lazy(() => import('./Static'));
const AboutUsPage = lazy(() => import('./AboutUs'));
const ImpressumPage = lazy(() => import('./Impressum'));
const ThankYouPage = lazy(() => import('./ThankYou'));
const UnsubscribePage = lazy(() => import('./Unsubscribe'));
const FrequentlyAskedQuestionsPage = lazy(
  () => import('./FrequentlyAskedQuestions')
);
const AccountPage = lazy(() => import('./Account'));
const AuthPage = lazy(() => import('./Auth'));
const ResetPasswordPage = lazy(() => import('./ResetPassword'));
const SubscriptionPage = lazy(() => import('./Subscription'));
const AffiliateDashboardPage = lazy(() => import('./affiliate-dashboard'));

export {
  CheckoutPage,
  ConfirmationPage,
  ContactPage,
  HomePage,
  NoMatchPage,
  NotAuthenticatedPage,
  SearchPage,
  HotelDetailsPage,
  StaticPage,
  AboutUsPage,
  ImpressumPage,
  FrequentlyAskedQuestionsPage,
  AccountPage,
  ResetPasswordPage,
  ThankYouPage,
  UnsubscribePage,
  AuthPage,
  SubscriptionPage,
  AffiliateDashboardPage
};
