import { mergeMap, catchError, skip, switchMap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { of, iif, merge, Observable, EMPTY, concat } from 'rxjs';
import { AxiosError } from 'axios';
import _get from 'lodash/get';
import _join from 'lodash/join';
import _isNil from 'lodash/isNil';
import format from 'date-fns/format';
import { parse } from 'query-string';

import { ActionTypes, TrackingActionTypes } from 'src/constants';
import { observableApi$ } from 'src/modules/api';
import roomsConverter from 'src/modules/roomsConverter';
import {
  HotelAction,
  StopHotelPollingAction,
  stopHotelRoomsPolling,
  HotelRoomsAction,
  getHotelRooms,
} from 'src/store/hotel/actions';
import {
  HotelRoomsRequest,
  HotelRoomsResponse,
  HotelRequest,
  HotelResponse,
} from 'src/models/hotels';
import {
  SearchByHotelIdRequest,
  SearchByHotelIdResponse,
} from 'src/models/search';
import { ReduxState } from 'src/store/reducers';
import { mapResponse, mapErrorResponse } from 'src/store/epicHelpers';
import { pollingOnResolved, PollingData } from 'src/modules/pollingOnResolved';
import { logEndpointError } from 'src/modules/logError';

import { setDeprecatedParams } from '../deprecated/actions';
import { SearchParamsState } from '../search/reducers';

const POLLING_DELAY = 2000;

const HotelAPI = {
  search$: (params: SearchByHotelIdRequest) =>
    observableApi$<SearchByHotelIdResponse>(`/search/byhotelId`, {
      method: 'get',
      params,
    }),

  hotelRooms$: (params: HotelRoomsRequest) =>
    observableApi$<HotelRoomsResponse>(`/v2/hotel/${params.hotelId}/rooms`, {
      method: 'get',
      params,
    }),

  hotel$: ({ hotelId, ...params }: HotelRequest) =>
    observableApi$<HotelResponse>(`/hotel/${hotelId}`, {
      method: 'get',
      params,
    }),
};

const mapQueryToData = (
  data: SearchParamsState,
  hotelId: number,
  es?: string
): SearchByHotelIdRequest => {
  const { dates, rooms, businessTrip } = data;

  return {
    // the first param (0) is for BACK-END
    k: _join(
      [
        0,
        ...dates.map((item) => format(item!, 'yyyyMMdd')),
        roomsConverter.serialize(rooms),
      ],
      ':'
    ),
    'd.hids': hotelId,
    'd.lat': 0,
    'd.lng': 0,
    es,
    tfb: businessTrip,
    utcOffset: format(new Date(), 'xxx'),
  };
};

const hotelRoomsErrorHandler = (
  errorResponse: AxiosError,
  params?: HotelRoomsRequest
) => {
  const { response } = errorResponse;

  if (response) {
    const {
      config: { params },
    } = errorResponse;
    const { status } = response;
    const hotelId = params.hotelId;
    const srId = params.searchId;

    // customer was changed, and we need to init new search
    if (status === 404 && srId) {
      return concat(
        of(setDeprecatedParams({ es: undefined })),
        of(getHotelRooms({ hotelId }))
      );
    }
  }

  void logEndpointError(
    { path: '/hotel/{hotelId}/rooms', method: 'GET' },
    errorResponse
  );

  // handle other errors
  return mapErrorResponse(ActionTypes.FETCH_HOTEL_ROOMS_FAILURE, errorResponse);
};

const searchHotelRooms =
  (
    action$: Observable<HotelRoomsAction | StopHotelPollingAction>,
    state$: StateObservable<ReduxState>
  ) =>
  (hotelParams: HotelRoomsRequest, searchParams: SearchParamsState) => {
    const hotel$ = HotelAPI.hotelRooms$(hotelParams);
    const responseOperatorFunction = mergeMap(
      (data: PollingData<HotelRoomsResponse>) => {
        const { pollingCount, response } = data;
        const { isFinished } = response;
        // Track hotel details
        const trackHotelDetails$ = of({
          type: TrackingActionTypes.TRACK_HOTEL_DETAILS,
          payload: {
            checkIn: searchParams.dates[0],
            checkOut: searchParams.dates[1],
            country: state$.value.hotel.info?.country,
            countryCode: state$.value.hotel.info?.countryCode,
            city: state$.value.hotel.info?.city,
            adultsCount: searchParams.rooms.reduce(
              (count, room) => count + room.adults,
              0
            ),
            childrenCount: searchParams.rooms.reduce(
              (count, room) => count + room.children.length,
              0
            ),
            hotelId: hotelParams.hotelId,
          },
        });

        const tradable = state$.value.hotel.info?.tradable ?? true;

        const pollingRule$ = iif(
          () => isFinished || !tradable,
          of(stopHotelRoomsPolling()),
          EMPTY
        );

        const observablesForEmit$ = merge(
          trackHotelDetails$,
          mapResponse(ActionTypes.FETCH_HOTEL_ROOMS_SUCCESS, response),
          pollingRule$
        );

        return iif(
          () => pollingCount > 0,
          observablesForEmit$.pipe(skip(1)),
          observablesForEmit$
        );
      }
    );

    const errorOperatorFunction = catchError((error) =>
      merge(
        hotelRoomsErrorHandler(error, hotelParams),
        of(stopHotelRoomsPolling())
      )
    );

    const takeUntilOperator = action$.pipe(
      ofType(ActionTypes.STOP_HOTEL_ROOMS_POLLING)
    );

    return pollingOnResolved(
      hotel$,
      //@ts-expect-error
      responseOperatorFunction,
      errorOperatorFunction,
      takeUntilOperator,
      POLLING_DELAY
    );
  };

export function handleHotelRoomsRequest(
  action$: Observable<HotelRoomsAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_HOTEL_ROOMS_REQUEST),
    switchMap(({ hotelId, srId }) => {
      const { searchParams, deprecated } = state$.value;
      const hotelParams: HotelRoomsRequest = {
        hotelId,
        searchId: srId!,
        es: deprecated.es,
      };

      const params = parse(window.location.search);

      const searchByHotelIdParams = {
        ...mapQueryToData(searchParams, hotelId, deprecated.es),
        pos: (params.pos || params.trv_pos) as string,
        Residency: deprecated.Residency?.toUpperCase(),
      };

      return iif(
        () => _isNil(srId),
        HotelAPI.search$(searchByHotelIdParams).pipe(
          mergeMap((response: SearchByHotelIdResponse) => {
            const { searchId } = response;
            const es = _get(response, 'hotels.0.es');

            return of({
              actions: [of(setDeprecatedParams({ searchId, es }))],
              hotelParams: { ...hotelParams, searchId, es },
            });
          })
        ),
        of({ actions: [], hotelParams })
      ).pipe(
        mergeMap((response) => {
          const { hotelParams, actions } = response;
          const pollingHotelInfo$ = searchHotelRooms(action$, state$)(
            hotelParams,
            searchParams
          );

          return merge(...actions, pollingHotelInfo$);
        }),

        catchError((err) => hotelRoomsErrorHandler(err))
      );
    })
  );
}

export function handleHotelRequest(
  action$: Observable<HotelAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_HOTEL_REQUEST),
    mergeMap(({ hotelId, searchId}) => {
      const { intl } = state$.value;
      const hotelParams: HotelRequest = {
        hotelId,
        searchId,
        locale: intl.currentLocale,
      };

      return HotelAPI.hotel$(hotelParams).pipe(
        mergeMap((response) =>
          mapResponse(ActionTypes.FETCH_HOTEL_SUCCESS, response)
        ),

        catchError((response) => {
          void logEndpointError(
            { path: '/hotel/{hotelId}', method: 'GET' },
            response
          );

          return mapErrorResponse(ActionTypes.FETCH_HOTEL_FAILURE, response);
        })
      );
    })
  );
}
