import { FC, useCallback } from 'react';
import _isFunction from 'lodash/isFunction';

import { getPreviewThumbnails } from 'src/components/HotelPreview/utils';

import { HotelPreviewProps } from './type';
import { ImageComponent } from './common';

export const HotelPreviewSmall: FC<HotelPreviewProps> = (props) => {
  const {
    images,
    className,
    id,
    toggleModal,
    trackingId,
    placeholder,
    children,
  } = props;
  const [image] = getPreviewThumbnails(images, 1);
  const handleImageClick = useCallback(() => {
    if (_isFunction(toggleModal) && image.url) {
      toggleModal(0);
    }
  }, [toggleModal, image.url]);

  const imageProps = {
    className,
    onClick: handleImageClick,
    id,
    trackingId,
    image,
    placeholder,
    children,
  };

  return <ImageComponent {...imageProps} />;
};
