import { useSelector } from 'react-redux';

import {
  defaultUrlMapper,
  L10nUrlMapperType,
} from 'src/modules/l10nUrlMappers';
import { intlSelector } from 'src/store/intl/selectors';
import { currencySelector } from 'src/store/currency/selectors';

export const useUrlWithCurrencyAndLocale = (
  url: string,
  urlMapper: L10nUrlMapperType = defaultUrlMapper
) => {
  const { currentCurrency } = useSelector(currencySelector);
  const { currentLocale } = useSelector(intlSelector);

  return urlMapper(url, currentLocale, currentCurrency);
};
