import { VFC } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import { I18n } from 'src/components/I18n';
import { ErrorPage } from 'src/components/common/ErrorPage';
import { ConnectedIntl } from 'src/containers/ConnectedIntl';

const PageWrapper = styled.div`
  background: ${(p) => p.theme.custom.backgroundMain};
  height: 100vh;
`;

const ErrorBoundaryFallback: VFC = () => {
  const dispatch = useDispatch();

  return (
    <ConnectedIntl>
      <PageWrapper>
        <ErrorPage
          header={<I18n id="ERROR_FALLBACK.HEADER" />}
          text={<I18n id="ERROR_FALLBACK.TEXT" />}
          buttonText={<I18n id="ERROR_FALLBACK.BTN" />}
          onBtnClick={() => {
            dispatch(replace('/'));
          }}
        />
      </PageWrapper>
    </ConnectedIntl>
  );
};

export default ErrorBoundaryFallback;
