import { forwardRef, RefObject, useRef } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import clamp from 'lodash/clamp';

import { modalSpacing } from 'src/components/common/CModal';
import { CPopover, CPopoverProps } from 'src/components/common/CPopover';
import useMatchMedia from 'src/hooks/useMatchMedia';
import useMergedRef from 'src/hooks/useMergedRef';

const POPOVER_MARGIN_SPACING = 4;

const StyledArrow = styled.div`
  display: none;
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 0;
  height: 0;
  border-left: ${({ theme }) => theme.spacing(3)} solid transparent;
  border-right: ${({ theme }) => theme.spacing(3)} solid transparent;
  border-bottom: ${({ theme }) => theme.spacing(POPOVER_MARGIN_SPACING)} solid
    ${({ theme }) => theme.palette.divider};
  transform: translateX(-50%);

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    border-left: inherit;
    border-right: inherit;
    border-bottom: inherit;
    position: inherit;
    border-bottom-color: ${({ theme }) => theme.palette.background.default};
    transform: translate(-50%, 2px);
  }
`;

/**
 * this component prevents safari bug with parent block width;
 */
const StyledContainer = styled.div`
  position: absolute;
`;

type Props = Omit<CPopoverProps, 'modifiers' | 'placement'> & {
  arrowTargetRef: RefObject<HTMLElement>;
};

export const SearchPanelPopover = forwardRef<HTMLDivElement, Props>(
  ({ children, arrowTargetRef, ...props }, outerRef) => {
    const theme = useTheme();

    const isMobile = useMatchMedia(theme.breakpoints.down('md'));

    const isDesktop = useMatchMedia(theme.breakpoints.up('lg'));

    const spacing = modalSpacing.md;

    const arrowRef = useRef<HTMLDivElement>(null);

    const innerRef = useRef<HTMLDivElement>(null);

    const ref = useMergedRef(innerRef, outerRef);

    return (
      <StyledContainer>
        <CPopover
          {...props}
          placement={isMobile ? 'bottom' : 'bottom-start'}
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [
                  isDesktop ? -parseInt(theme.spacing(spacing)) : 0,
                  parseInt(
                    theme.spacing(
                      POPOVER_MARGIN_SPACING + (isDesktop ? spacing : 1)
                    )
                  ),
                ],
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'arrowPosition',
              phase: 'write',
              enabled: true,
              fn: () => {
                const popperEl = innerRef.current;

                const arrowEl = arrowRef.current;

                const targetEl = arrowTargetRef?.current;

                if (popperEl && arrowEl && targetEl) {
                  arrowEl.style.display = 'block';

                  const popperRect = popperEl.getBoundingClientRect();

                  const left =
                    targetEl.getBoundingClientRect().left +
                    targetEl.clientWidth / 2 -
                    popperRect.left;

                  const boundary = 48;

                  arrowEl.style.left = `${clamp(
                    left,
                    boundary,
                    popperRect.width - boundary
                  )}px`;
                }
              },
            },
          ]}
          ref={ref}
        >
          {children}
          <StyledArrow ref={arrowRef} />
        </CPopover>
      </StyledContainer>
    );
  }
);
