import { useState } from 'react';

import { new_Error } from 'src/models/actions';
import { apiCall } from 'src/modules/api';

import useCustomer from './swr/useCustomer';

const useFavorite = (
  hotelId: number,
  onFavorite?: (isFavorite: boolean) => void,
  disable?: boolean
) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data, mutate } = useCustomer(disable);

  const isFavorite = data?.favorites.has(hotelId);

  return {
    isFavorite,
    handleFavorite: async () => {
      if (!isLoading) {
        setIsLoading(true);

        const update = (isFavorite?: boolean) => {
          void mutate((prevData) => {
            if (prevData) {
              const favorites = new Set(prevData.favorites);

              if (isFavorite) {
                favorites.delete(hotelId);
              } else {
                favorites.add(hotelId);
              }

              return { ...prevData, favorites };
            }

            return prevData;
          }, false);

          if (onFavorite) {
            onFavorite(!isFavorite);
          }
        };

        update(isFavorite);

        try {
          // handling case when it has 200 status with errorCode
          const res = await apiCall<new_Error['data']>(
            `/customers/favorites/${hotelId}`,
            {
              method: isFavorite ? 'DELETE' : 'POST',
            }
          );

          if (res.errorCode) {
            throw res;
          }
        } catch (err) {
          update(!isFavorite);
        }

        setIsLoading(false);
      }
    },
  };
};

export default useFavorite;
