import {
  StripePaymentMethodType,
  TPaymentMethodTypes,
} from 'src/models/payments';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

import card from '../assets/card.svg';
// import ideal from '../assets/ideal.png';
// import ideal2x from '../assets/ideal2x.png';
// import przelewy24 from '../assets/przelewy24.png';
// import przelewy242x from '../assets/przelewy242x.png';
// import alipay from '../assets/alipay.svg';
// import webmoney from '../assets/webmoney.svg';

export interface PaymentMethod {
  i18nId: DefaultAppMessagesTypeKey;
  value: TPaymentMethodTypes;
  logoSrc: string;
  logoSrcSet?: string;
  countries?: string[];
}

const paymentMethods: PaymentMethod[] = [
  {
    i18nId: 'CHECKOUT_PAGE.PAYMENT_PANEL.PAYMENT_METHODS.CREDIT_CARDS',
    value: StripePaymentMethodType.StripeCard,
    logoSrc: card,
  },
];

export const getPaymentMethods = () => paymentMethods;

export const getPaymentCardType = () => StripePaymentMethodType.StripeCard;

export const isCreditCard = (value: TPaymentMethodTypes) => {
  const methodTypes: TPaymentMethodTypes[] = [
    StripePaymentMethodType.StripeCard,
  ];

  return methodTypes.includes(value);
};
