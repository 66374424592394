import styled, { css } from 'styled-components/macro';
import darken from 'polished/lib/color/darken';
import Typography from '@mui/material/Typography';

import { POINTER_FINE } from 'src/configs/muiTheme';

import { EDayStatus } from '.';

type Props = {
  status: EDayStatus;
  disabled?: boolean;
};

export const StyledDay = styled(Typography)`
  border-radius: 50%;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: ${({ theme }) =>
    theme.transitions.create('background', { duration: 100 })};
`;

const getHalfGradient = (first: string, second: string) =>
  `linear-gradient(90deg, ${first} 0%, ${first} 50%, ${second} 50%, ${second} 100%)`;

const inRangeMixin = css`
  &::before {
    background: ${({ theme }) => theme.palette.divider};
  }
`;

const selectedMixin = css<Props>`
  ${StyledDay} {
    background: ${({ theme }) => theme.palette.buttonSecondary.main};
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.buttonSecondary.main)};
  }
  &:disabled ${StyledDay} {
    background: ${({ theme }) => theme.palette.text.disabled};
  }
  ${POINTER_FINE} {
    &:hover ${StyledDay} {
      background: ${({ theme }) => theme.palette.buttonSecondary.light};
    }
  }
  &::before {
    background: ${({ status, theme }) => {
      const colors: [string, string] = ['transparent', theme.palette.divider];

      if (status === EDayStatus.END) colors.reverse();

      return getHalfGradient(...colors);
    }};
  }
`;

export const DAY_SIZE = 38;

export const StyledDayContainer = styled.button<Props>`
  position: relative;
  background: transparent;
  width: ${DAY_SIZE}px;
  height: ${DAY_SIZE}px;
  border: none;
  cursor: pointer;

  &:disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
    pointer-events: none;
  }

  ${POINTER_FINE} {
    &:hover ${StyledDay} {
      background: ${({ theme }) => darken(0.1, theme.palette.divider)};
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: transparent;
    transition: ${({ theme }) =>
      theme.transitions.create('background', { duration: 300 })};
  }

  ${({ status }) => {
    switch (status) {
      case EDayStatus.BETWEEN:
        return inRangeMixin;

      case EDayStatus.START:
      case EDayStatus.END:
        return selectedMixin;
    }
  }}
`;
