import { Palette } from '@mui/material';
import pick from 'lodash/pick';

import { ArrayElement, ThemeProps } from 'src/types';

type O = { [key: string]: O | {} | string };

const pickedPaletteKeys = (<T extends (keyof Palette)[]>(...args: T) => args)(
  'primary',
  'secondary',
  'common',
  'text',
  'background'
);

type PickedPalette = Pick<Palette, ArrayElement<typeof pickedPaletteKeys>>;

const combineVariableName = <F extends string, S extends string>(
  first: F,
  second: S
) => `${first}-${second}` as const;

export const getThemeColorVariableName = <
  C extends keyof PickedPalette,
  V extends keyof PickedPalette[C]
>(
  color: C,
  variant: V extends string ? V : never
) => `var(--${combineVariableName(color, variant)})` as const;

const createCssVariable = <T extends O>(obj: T, parentKey?: string): string =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (typeof value === 'object') return acc + createCssVariable(value, key);

    return `${acc}--${
      parentKey ? combineVariableName(parentKey, key) : key
    }:${value};`;
  }, '');

const themeColorVariablesMixin = ({ theme }: ThemeProps) =>
  createCssVariable(pick<PickedPalette>(theme.palette, pickedPaletteKeys));

export default themeColorVariablesMixin;
