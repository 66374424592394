import { useDispatch, useSelector } from 'react-redux';

import { I18n } from 'src/components/I18n';
import type { CurrencyType } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { getCurrencySymbol } from 'src/modules/getCurrencySymbol';
import { changeCurrency } from 'src/store/currency/actions';
import { currenciesSelector } from 'src/store/settings/selectors';

import { SectionWrapper } from './Section';

const addCurrencySymbol = (currency: string) => {
  const symbol = getCurrencySymbol(currency);

  if (symbol && symbol.length === 1) return ` - ${symbol}`;

  return undefined;
};

const CurrencySection: SectionWrapper<CurrencyType> = ({
  handleClose,
  value,
  Section,
}) => {
  const currencies = useSelector(currenciesSelector);

  const dispatch = useDispatch();

  const handleCurrency = (currency: CurrencyType) => {
    dispatch(changeCurrency({ currency, rate: currencies[currency]! }));

    handleClose();
  };

  return (
    <Section
      value={value}
      values={Object.keys(currencies) as CurrencyType[]}
      onChange={handleCurrency}
      getLabel={(currency) => (
        <>
          <I18n id={`GENERAL.CURRENCY.${currency}`} />
          {addCurrencySymbol(currency)}
        </>
      )}
    />
  );
};

export default CurrencySection;
