import { FC, useCallback, useState, ComponentType } from 'react';
import _isNil from 'lodash/isNil';

import { Image } from 'src/types';
import { HotelPreviewProps } from 'src/components/HotelPreview/type';
import { useToggle } from 'src/hooks/useToggle';

import { GalleryPortal } from './GalleryPortal';

interface WithGalleryModal {
  toggleModal?: () => void;
  images: Image[];
}

export const withGalleryModal = <T extends WithGalleryModal>(
  Comp: ComponentType<T>
) => {
  const DecoratedComponent: FC<T & HotelPreviewProps> = (props) => {
    const [isModalOpen, toggleModal, closeModal] = useToggle(false);
    const [initialIndex, setInitialIndex] = useState<number | undefined>();

    const toggleModalWithInitialIndex = useCallback(
      (nextInitialIndex?: number) => {
        toggleModal();

        if (!_isNil(nextInitialIndex)) {
          setInitialIndex(nextInitialIndex);
        }
      },
      [toggleModal, setInitialIndex]
    );

    return (
      <>
        <Comp {...props} toggleModal={toggleModalWithInitialIndex} />
        {isModalOpen && (
          <GalleryPortal
            initialIndex={initialIndex}
            hotelId={props.hotelId}
            closeModal={closeModal}
            images={props.images}
          />
        )}
      </>
    );
  };

  return DecoratedComponent;
};
