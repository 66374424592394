import Stack from '@mui/material/Stack';
import { VFC } from 'react';
import styled from 'styled-components/macro';
import Typography from '@mui/material/Typography';

import MinusIcon from 'src/icons/Minus';
import PlusIcon from 'src/icons/Plus';

import { Button } from './WrappedButtons';

type NumberStepperProps = {
  value: number;
  onChange: (v: number) => void;
  onFocus?: () => void;
  step?: number;
  min?: number;
  max?: number;
};

const StyledText = styled.div`
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    display: block;
`;

const StyledButton = styled(Button)`
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(3)};
  line-height: 1;
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const NumberStepper: VFC<NumberStepperProps> = ({
  value,
  onChange,
  step = 1,
  min,
  max,
}) => (
  <Stack direction="row" alignItems="center" spacing={8}>
    <StyledButton
      onClick={() => onChange(value - step)}
      variant="contained"
      color="buttonSecondary"
      disabled={typeof min !== 'undefined' && value <= min}
    >
      <MinusIcon />
    </StyledButton>
    <Typography variant="label1" color="inherit">
      {value}
    </Typography>
    <StyledButton
      onClick={() => onChange(value + step)}
      variant="contained"
      color="buttonSecondary"
      disabled={typeof max !== 'undefined' && value >= max}
    >
      <PlusIcon />
    </StyledButton>
  </Stack>
);
