import { useRef, useLayoutEffect } from 'react';
import _debounce from 'lodash/debounce';

export enum ScrollDirection {
  down = 'down',
  up = 'up',
  none = 'none',
}

type handleScrollFn = (yOffset: number, direction: ScrollDirection) => void;

const isScrollPositionSame = (a: number, b: number) => Math.abs(a - b) < 3; // magic number
export const useWithScroll = (
  handleScroll: handleScrollFn,
  debounceTime = 10
) => {
  const pageY = useRef(window.pageYOffset);
  const scrollDirection = useRef(ScrollDirection.none);

  useLayoutEffect(() => {
    const onScroll = () => {
      const newPageYoffset = window.pageYOffset;
      const nextDirection = newPageYoffset > pageY.current ? 'down' : 'up';

      const newDirection = isScrollPositionSame(newPageYoffset, pageY.current)
        ? ScrollDirection.none
        : ScrollDirection[nextDirection];

      pageY.current = newPageYoffset;
      scrollDirection.current = newDirection;

      handleScroll(newPageYoffset, newDirection);
    };
    const onScrollDebounced = _debounce(onScroll, debounceTime);

    window.addEventListener('scroll', onScrollDebounced);
    return () => {
      onScrollDebounced.cancel();
      window.removeEventListener('scroll', onScrollDebounced);
    };
  }, [handleScroll, debounceTime]);
};
