import { History } from 'history';
import { Action, Reducer, ReducersMapObject } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import { UserState, userReducer } from 'src/store/user/reducers';
import { IntlState, intlReducer } from 'src/store/intl/reducers';
import { SearchParamsState, searchParams } from 'src/store/search/reducers';
import { CurrencyState, currency } from 'src/store/currency/reducers';
import { hotel, HotelState } from 'src/store/hotel/reducers';
import { CheckoutState, checkoutReducer } from 'src/store/checkout/reducers';
import { settings, SettingsState } from 'src/store/settings/reducers';
import {
  ConfirmationState,
  confirmationReducer,
} from 'src/store/confirmation/reducers';
import { PopUpStateType, popUpReducer } from 'src/store/popUp/reducers';
import { GeneralState, generalReducer } from 'src/store/contactForm/reducers';

import { deprecated, DeprecatedState } from './deprecated/reducers';

export interface ReduxState {
  intl: IntlState;
  user: UserState;
  searchParams: SearchParamsState;
  currency: CurrencyState;
  hotel: HotelState;
  popUp: PopUpStateType;
  checkout: CheckoutState;
  confirmation: ConfirmationState;
  settings: SettingsState;
  general: GeneralState;
  router: RouterState;
  deprecated: DeprecatedState;
}

export const getRootReducer = (
  history: History
): ReducersMapObject<ReduxState> => ({
  router: connectRouter(history) as Reducer<RouterState, Action>,
  intl: intlReducer,
  user: userReducer,
  checkout: checkoutReducer,
  confirmation: confirmationReducer,
  popUp: popUpReducer,
  general: generalReducer,
  searchParams,
  hotel,
  settings,
  currency,
  deprecated,
});
