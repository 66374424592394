import { appConfig } from 'src/modules/app-config';

import { TopDestination } from './types';

const imgPath = `/assets/img`;

const topDestinations: TopDestination[] = [
  {
    imgUrl: `${imgPath}/paris.jpg`,
    dest: {
      placeId: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ',
      q: 'Paris, France',
    },
    city: 'Paris',
    description:
      "Paris's blend of elegance and charm is bound to mesmerize all those eager to explore its vibrant culture, iconic architecture, exquisite cuisine, lively nightlife, and storied history.",
    pos: { lat: 48.856614, lng: 2.352222 },
  },
  {
    imgUrl: `${imgPath}/london.jpg`,
    dest: {
      placeId: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
      q: 'London, The United Kingdom',
    },
    city: 'London',
    description:
      "London's amalgamation of sophistication and vitality is sure to captivate all those keen to delve into its diverse culture, cutting-edge architecture, delectable cuisine, vibrant nightlife, and rich history.",
    pos: {
      lat: 51.507351,
      lng: -0.127758,
    },
  },
  {
    imgUrl: `${imgPath}/barcelona.jpg`,
    dest: {
      q: 'Barcelona, Spain',
      placeId: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
    },
    city: 'Barcelona',
    description:
      "Barcelona's fusion of vibrancy and allure is bound to enchant all those eager to explore its dynamic culture, avant-garde architecture, exquisite cuisine, bustling nightlife, and palpable history.",
    pos: {
      lat: 41.385064,
      lng: 2.173403,
    },
  },
  {
    imgUrl: `${imgPath}/amsterdam.jpg`,
    dest: {
      q: 'Amsterdam, Netherlands',
      placeId: 'ChIJVXealLU_xkcRja_At0z9AGY',
    },
    city: 'Amsterdam',
    description:
      "Amsterdam's unique blend of charm and character is sure to captivate all who seek to delve into its dynamic culture, innovative architecture, delectable cuisine, lively nightlife, and palpable history.",
    pos: {
      lat: 52.366697,
      lng: 4.89454,
    },
  },
  {
    imgUrl: `${imgPath}/berlin.jpg`,
    dest: {
      q: 'Berlin, Germany',
      placeId: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ',
    },
    city: 'Berlin',
    description:
      "Berlin's combo of glamour and grit is bound to mesmerise all those keen to explore its vibrant culture, cutting-edge architecture, fabulous food, intense parties and tangible history.",
    pos: {
      lat: 52.520007,
      lng: 13.404954,
    },
  },
  {
    imgUrl: `${imgPath}/rome.jpg`,
    dest: {
      q: 'Rome, Italy',
      placeId: 'ChIJw0rXGxGKJRMRAIE4sppPCQM',
    },
    city: 'Rome',
    description:
      "Rome's fusion of grandeur and authenticity is certain to enchant anyone eager to immerse themselves in its rich culture, timeless architecture, tantalizing cuisine, lively festivities, and tangible history.",
    pos: {
      lat: 41.902701,
      lng: 12.496235,
    },
  },
  {
    imgUrl: `${imgPath}/las-vegas.jpg`,
    dest: {
      q: 'Las Vegas, The United States',
      placeId: 'ChIJ0X31pIK3voARo3mz1ebVzDo',
    },
    city: 'Las Vegas',
    description:
      "Las Vegas' blend of excitement and allure is sure to captivate all those eager to experience its vibrant entertainment, extravagant architecture, delectable cuisine, pulsating nightlife, and electrifying atmosphere.",
    pos: {
      lat: 36.169941,
      lng: -115.13983,
    },
  },
  {
    imgUrl: `${imgPath}/new-york.jpg`,
    dest: {
      q: 'New York, The United States',
      placeId: 'ChIJOwg_06VPwokRYv534QaPC8g',
    },
    city: 'New York',
    description:
      "New York's fusion of energy and diversity is bound to mesmerize all those eager to explore its dynamic culture, iconic architecture, world-renowned cuisine, vibrant nightlife, and rich history.",
    pos: {
      lat: 40.712775,
      lng: -74.005973,
    },
  },
  {
    imgUrl: `${imgPath}/cancun.jpg`,
    dest: {
      q: 'Cancun, Mexico',
      placeId: 'ChIJ21P2rgUrTI8Ris1fYjy3Ms4',
    },
    city: 'Cancun',
    description:
      "Cancún's blend of tropical paradise and vibrant culture is sure to captivate all those eager to experience its pristine beaches, turquoise waters, exhilarating nightlife, exquisite cuisine, and rich Mayan heritage.",
    pos: {
      lat: 21.161908,
      lng: -86.851528,
    },
  },
  {
    imgUrl: `${imgPath}/dubai.jpg`,
    dest: {
      q: 'Dubai, United Arab Emirates',
      placeId: 'ChIJRcbZaklDXz4RYlEphFBu5r0',
    },
    city: 'Dubai',
    description:
      "Dubai's juxtaposition of luxury and vitality is certain to captivate all those eager to delve into its dynamic culture, innovative architecture, exquisite cuisine, electrifying nightlife, and palpable history.",
    pos: {
      lat: 25.204849,
      lng: 55.270783,
    },
  },
  {
    imgUrl: `${imgPath}/miami.jpg`,
    dest: {
      q: 'Miami, The United States',
      placeId: 'ChIJEcHIDqKw2YgRZU-t3XHylv8',
    },
    city: 'Miami',
    description:
      "Miami's blend of glamour and energy is bound to captivate all those eager to explore its vibrant culture, iconic architecture, delectable cuisine, pulsating nightlife, and sun-kissed beaches.",
    pos: {
      lat: 25.76168,
      lng: -80.19179,
    },
  },
  {
    imgUrl: `${imgPath}/athens.jpg`,
    dest: {
      q: 'Athens, Greece',
      placeId: 'ChIJ8UNwBh-9oRQR3Y1mdkU1Nic',
    },
    city: 'Athens',
    description:
      "Athens' mix of ancient grandeur and contemporary charm is sure to captivate all those eager to explore its rich history, iconic architecture, delectable cuisine, lively culture, and Mediterranean ambiance.",
    pos: {
      lat: 37.98381,
      lng: 23.727539,
    },
  },
];

export default topDestinations;
