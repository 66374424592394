import _snakeCase from 'lodash/snakeCase';
import Cookies from 'js-cookie';
import { Action } from 'redux';
import addDays from 'date-fns/addDays';

import { TaxAndFee } from 'src/models/checkout';
import { DEFAULT_TOKEN_EXPIRATION } from 'src/constants';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

export type KeyValueType<T = any> = Record<string, T>;

// TODO add typing for actions
/**
 * Generate reducer.
 *
 * @param {object} initialState
 * @param {object} handlers
 * @returns {function}
 */
export function createReducer<
  State extends Record<string, any>,
  Actions extends Action<string>
>(
  initialState: State,
  handlers: {
    [key: string]: (state: State, action: any) => State;
  }
) {
  return function reducer(state: State = initialState, action: Actions): State {
    if ({}.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
}

interface Options {
  parentElement?: HTMLBodyElement | HTMLHeadElement;
  shouldRemove?: boolean;
}

export const loadScript = (src: string, options: Options = {}) =>
  new Promise<void>((resolve, reject) => {
    const { parentElement = document.body, shouldRemove = true } = options;

    const scriptElement = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e: ErrorEvent | null) => {
        reject(e);
      },
    } as HTMLScriptElement);

    scriptElement.onload = () => {
      if (shouldRemove) {
        parentElement.removeChild(scriptElement);
      }

      resolve();
    };

    parentElement.appendChild(scriptElement);
  });

export const getComputedCssProperties = (
  el: HTMLElement,
  properties: any[]
) => {
  const computedStyles = window.getComputedStyle(el);

  return properties.map((cssProperty) => {
    const cssPropertyValue = computedStyles[cssProperty];
    const parsedValue = parseFloat(cssPropertyValue);
    return !isNaN(parsedValue) ? parsedValue : cssPropertyValue;
  });
};

/**
 * customCeil is a copy of `ceil` for Number from mathjs package
 * https://github.com/josdejong/mathjs
 *
 * EPSILON is from mathjs package too
 * https://github.com/josdejong/mathjs/blob/develop/src/core/config.js#L4
 */
const EPSILON = 1e-11;
const customCeil = (x: number) => {
  const roundedX = Math.round(x);
  if (Math.abs(x - roundedX) < EPSILON) {
    return roundedX;
  }

  return Math.ceil(x);
};

/**
 * customFloor is a copy of `floor` for Number from mathjs package
 * https://github.com/josdejong/mathjs
 */
const customFloor = (x: number) => {
  const roundedX = Math.round(x);
  if (Math.abs(x - roundedX) < EPSILON) {
    return roundedX;
  }

  return Math.floor(x);
};

const convertFromBaseCurrency = (
  value: number,
  baseCurrencyRate: number,
  precision = 2,
  roundingType?: 'ceil' | 'floor' | 'nearest'
) => {
  const roundingFn =
    (roundingType === 'ceil' && customCeil) ||
    (roundingType === 'floor' && customFloor) ||
    Math.round;

  return (
    roundingFn(value * baseCurrencyRate * 10 ** precision) / 10 ** precision
  );
};

export const convertSumFromBaseCurrency = (
  values: number[],
  baseCurrencyRate: number,
  precision = 2,
  roundingType?: 'ceil' | 'floor' | 'nearest'
) =>
  parseFloat(
    values
      .reduce(
        (acc, value) =>
          acc +
          convertFromBaseCurrency(
            value,
            baseCurrencyRate,
            precision,
            roundingType
          ),
        0
      )
      .toFixed(precision)
  );

export const toI18nKey = (str: string, prefix?: string) => {
  const formattedKey = _snakeCase(str).toUpperCase();
  return (
    prefix ? `${prefix}.${formattedKey}` : formattedKey
  ) as DefaultAppMessagesTypeKey;
};

type AddressData = {
  address?: string;
  city?: string;
  country?: string;
  zipCode?: string;
};

export const getHotelAddress = (data: AddressData) =>
  (['address', 'city', 'country', 'zipCode'] as (keyof AddressData)[])
    .reduce<string[]>((acc, key) => {
      const item = data[key];

      if (item) {
        acc.push(item);
      }

      return acc;
    }, [])
    .join(', ');

export const getHotelCityDescription = (city?: string, country?: string) =>
  [city, country].filter(Boolean).join(', ');

export const saveCookie = (
  name: string,
  value: string,
  expiration?: number | Date
) => {
  const expireDate =
    expiration || addDays(new Date(), DEFAULT_TOKEN_EXPIRATION);
  Cookies.set(name, value, { expires: expireDate });
};

export const getTransactionTotalPrice = (
  salePrice: number,
  creditCardFee: number
): number => Math.round((salePrice + creditCardFee) * 100) / 100;

export const getIncludedTaxes = (taxAndFees: TaxAndFee[]): number =>
  taxAndFees?.reduce(
    (acc, { value, isIncludedInPrice }) =>
      isIncludedInPrice ? acc + value : acc,
    0
  );
