import { FC, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';
import { useWithScroll } from 'src/hooks/useWithScroll';

const Wrap = styled.div`
  position: relative;
  width: calc(100% + 60px);
  margin: 0 -30px;

  ${media.extraSmall`
    width: calc(100% + 40px);
    margin: 0 -20px;
  `}
`;

const StickyContainer = styled.div<{ isFixed: boolean }>`
  position: ${(p) => (p.isFixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

export const StickyWrapper: FC<any> = (props) => {
  const { children, className } = props;
  const wrapRef = useRef<HTMLDivElement>(null);
  const [isFixed, setFixed] = useState(false);

  const handleScroll = () => {
    if (wrapRef && wrapRef.current) {
      const { top } = wrapRef.current.getBoundingClientRect();
      if (top < 0 && !isFixed) {
        wrapRef.current.style.minHeight = `${wrapRef.current.scrollHeight}px`;
        setFixed(true);
      }
      if (top >= 0 && isFixed) {
        setFixed(false);
        wrapRef.current.style.minHeight = 'auto';
      }
    }
  };
  useWithScroll(handleScroll);

  return (
    <Wrap ref={wrapRef} className={className}>
      <StickyContainer isFixed={isFixed}>{children}</StickyContainer>
    </Wrap>
  );
};
