import { FC } from 'react';
import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { TabletDesktop } from 'src/components/shared/CResponsive';

const NoImagePlaceholderText = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(50%, 50%);
  color: ${(p) => p.theme.custom.greySecondaryColor};
  font-weight: 500;
  z-index: 2;
  > span {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
`;

const Icon = styled.div.attrs({ className: 'icon-room' })`
  font-size: 45px;
  color: ${(p) => p.theme.custom.grayTextColor};
`;

interface NoImagePlaceholderProps {
  className?: string;
  showText?: boolean;
}

export const NoImagePlaceholder: FC<NoImagePlaceholderProps> = ({
  className,
  showText = true,
}) => (
  <NoImagePlaceholderText className={className}>
    <Icon />
    {showText && (
      <TabletDesktop>
        <I18n id="HOTEL_DETAILS_PAGE.HOTEL_NO_IMAGE" tagName="span" />
      </TabletDesktop>
    )}
  </NoImagePlaceholderText>
);
