import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

interface CollapsedProps {
  collapsed: boolean;
}

const StyledAnimateHeight = styled(AnimateHeight)`
  position: relative;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const Collapsed = ({
  children,
  height = 0,
  collapsed,
  ...rest
}: PropsWithChildren<CollapsedProps & AnimateHeightProps>) => (
  <StyledAnimateHeight
    {...rest}
    height={collapsed ? height : 'auto'}
    duration={300}
  >
    {children}
  </StyledAnimateHeight>
);

export default Collapsed;
