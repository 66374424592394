import { FC } from 'react';
import styled, { useTheme } from 'styled-components/macro';

import useMatchMedia from 'src/hooks/useMatchMedia';

import CDrawer from '../common/CDrawer';
import CModalContainer, {
  CModalContainerProps,
} from '../common/CModalContainer';
import { I18n } from '../I18n';

import CloseButton from './CloseButton';

const StyledWrapper = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  mask-image: radial-gradient(white, black);
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const StyledModalContainer = styled.div`
  height: 90vh;
  width: 90vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledDrawerContainer = styled.div`
  margin-left: calc(-1 * var(--padding));
  width: calc(100% + var(--padding) * 2);
  height: 100%;
  margin-right: calc(-1 * var(--padding));
  margin-bottom: calc(-1 * var(--padding));
`;

const CGoogleMapModal: FC<Required<CModalContainerProps>> = ({
  onClose,
  isOpen,
  children,
}) => {
  const theme = useTheme();

  const isMobile = useMatchMedia(theme.breakpoints.down('sm'));

  return isMobile ? (
    <CDrawer
      open={isOpen}
      onClose={onClose}
      header={<I18n id="SEARCH_PAGE.SEARCH_RESULTS.MOBILE_MAP_TITLE" />}
    >
      <StyledDrawerContainer>{children}</StyledDrawerContainer>
    </CDrawer>
  ) : (
    <CModalContainer onClose={onClose} isOpen={isOpen}>
      <StyledModalContainer>
        <StyledWrapper>{children}</StyledWrapper>
        <CloseButton onClick={onClose} />
      </StyledModalContainer>
    </CModalContainer>
  );
};

export default CGoogleMapModal;
