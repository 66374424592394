import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { IconButton } from './WrappedButtons';

type PasswordFiledProps = Omit<TextFieldProps, 'type'>;

export const PasswordField = forwardRef<HTMLDivElement, PasswordFiledProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        ref={ref}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
