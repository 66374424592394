import { useEffect, useReducer, Reducer } from 'react';
import { AxiosError } from 'axios';
import { map } from 'rxjs/operators';

import { LoadingTypes } from 'src/constants';
import { HotelImagesRequest, HotelImagesResponse } from 'src/models/hotels';
import { observableApi$ } from 'src/modules/api';
import { Image } from 'src/types';
import { logEndpointError } from 'src/modules/logError';

enum ActionType {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

interface HotelImagesReducerState {
  status?: LoadingTypes;
  images?: Image[];
  error?: null | AxiosError;
}

interface HotelImagesReducerAction {
  type: keyof typeof ActionType;
  images?: Image[];
  error?: null | AxiosError;
}

const hotelImagesReducer: Reducer<
  HotelImagesReducerState,
  HotelImagesReducerAction
> = (state, action) =>
  ({
    [ActionType.REQUEST]: () => ({
      ...state,
      status: LoadingTypes.RUNNING,
    }),
    [ActionType.SUCCESS]: () => ({
      ...state,
      status: LoadingTypes.IDLE,
      images: action.images?.length ? action.images : state.images,
    }),
    [ActionType.FAILURE]: () => ({
      ...state,
      status: LoadingTypes.IDLE,
      error: action.error,
    }),
  }[action.type]());

const getHotelImages = (params: HotelImagesRequest) =>
  observableApi$<HotelImagesResponse>(`hotel/${params.hotelId}/images`);

export const useFetchHotelImages = (
  isModalOpen = false,
  hotelId?: number,
  defaultImages?: Image[]
) => {
  const [state, dispatch] = useReducer(hotelImagesReducer, {
    status: LoadingTypes.IDLE,
    images: defaultImages,
    error: null,
  });

  useEffect(() => {
    if (!isModalOpen || !hotelId) {
      return;
    }

    dispatch({ type: ActionType.REQUEST });

    const imagesSubscription$ = getHotelImages({ hotelId })
      .pipe(
        map((response) =>
          (response ?? []).map((imageId) => ({
            id: imageId,
            url: imageId,
          }))
        )
      )
      .subscribe(
        (images) => dispatch({ type: ActionType.SUCCESS, images }),
        (error: AxiosError) => {
          void logEndpointError(
            { path: '/hotel/{hotelId}/images', method: 'GET' },
            error
          );
          return dispatch({ type: ActionType.FAILURE, error });
        }
      );

    return () => imagesSubscription$.unsubscribe();
  }, [isModalOpen, hotelId]);

  return state;
};
