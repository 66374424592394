import { useIntl } from 'react-intl';

import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { defaultMessages } from 'src/containers/ConnectedIntl/messages/messages';

type ValuesType = Record<string, any>;

export type IntlFormatMessageType = (
  messageId: DefaultAppMessagesTypeKey,
  values?: ValuesType
) => string;

export const useIntlFormatMessage = (): IntlFormatMessageType => {
  const intl = useIntl();

  return (messageId, values) =>
    defaultMessages[messageId]
      ? intl.formatMessage(defaultMessages[messageId], values)
      : messageId;
};
