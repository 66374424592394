import { parse, stringifyUrl } from 'query-string';

import {
  stringifyClientOptions,
  parseClientOptions,
} from './queryStringOptions';

export type L10nUrlMapperType = (
  originUrl: string,
  locale: string,
  currency: string
) => string;

export const defaultUrlMapper: L10nUrlMapperType = (
  originUrl,
  locale,
  currency
) => {
  const [url, query] = originUrl.split('?');

  return stringifyUrl(
    {
      url,
      query: {
        ...parse(query, parseClientOptions),
        currency,
        locale,
      },
    },
    stringifyClientOptions
  );
};
