import { VFC, useMemo } from 'react';
import styled from 'styled-components/macro';
import _isFunction from 'lodash/isFunction';

import FavoriteButton from 'src/components/FavoriteButton';

import { getPreviewThumbnails } from './utils';
import { HotelPreviewProps } from './type';
import { ImagesCount } from './ImagesCount';
import { ImageComponent } from './common';
import { HotelPreviewMapButton } from './HotelPreviewMapButton';
import { NoImagePlaceholder } from './NoImagePlaceholder';

const Wrapper = styled.div`
  position: relative;
`;

// we have to set z-index otherwise hotel details page will be opened instead of gallery.
// look at src/components/HotelItem/LinkToHotelPage.tsx, line 15
const StyledImageComponent = styled(ImageComponent)`
  z-index: 2;
`;

const ImageRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;

  ${StyledImageComponent} {
    width: 25%;
    padding-bottom: 25%;
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledNoImagePlaceholder = styled(NoImagePlaceholder)`
  .icon-room {
    font-size: 35px;
  }
`;

export const HotelPreviewMedium: VFC<HotelPreviewProps> = ({
  className,
  images,
  imagesCount,
  toggleModal,
  id,
  hotelId,
  trackingId,
  showMapButton,
  onMapButtonClick,
}) => {
  const previewThumbnails = useMemo(
    () => getPreviewThumbnails(images, showMapButton ? 4 : 5),
    [images, showMapButton]
  );

  const [mainImage, ...additionalImages] = previewThumbnails;

  const handleImageClick = (index: number) => () => {
    if (previewThumbnails[index].url && _isFunction(toggleModal) && hotelId) {
      toggleModal(index);
    }
  };

  return (
    <Wrapper className={className} data-id={id}>
      {/* {Boolean(hotelId) && <FavoriteButton hotelId={hotelId!} />} */}
      <StyledImageComponent
        image={mainImage}
        onClick={handleImageClick(0)}
        trackingId={trackingId}
      />
      <ImageRow>
        {additionalImages.map((image, index, arr) => {
          const isLast = index === arr.length - 1;

          return (
            <StyledImageComponent
              key={`${index}_${image.id}`}
              image={image}
              onClick={handleImageClick(index + 1)}
              trackingId={trackingId}
              placeholder={<StyledNoImagePlaceholder showText={false} />}
            >
              {isLast && image.url && (
                <ImagesCount imagesCount={imagesCount ?? images.length} />
              )}
            </StyledImageComponent>
          );
        })}
        {showMapButton && (
          <StyledImageComponent
            image={{
              url: '/assets/i/map-btn-background-small.svg',
              id: 'hotel-preview-map-button',
            }}
          >
            <HotelPreviewMapButton onClick={onMapButtonClick} />
          </StyledImageComponent>
        )}
      </ImageRow>
    </Wrapper>
  );
};
