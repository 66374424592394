/* eslint-disable @typescript-eslint/naming-convention */

import { pathToRegexp } from 'path-to-regexp';
import { RLSSetupObject, RLSQueryParams } from 'redux-location-state';
import format from 'date-fns/format';
import { parse, stringifyUrl } from 'query-string';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import roomConverter from 'src/modules/roomsConverter';
import { datesUrlParser } from 'src/modules/datesUrlParser';
import {
  arraySerializeDelimiter,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
} from 'src/configs';
import { Nullable } from 'src/types';
import { Dates, Room } from 'src/store/search/reducers';
import {
  stringifyClientOptions,
  parseClientOptions,
} from 'src/modules/queryStringOptions';

enum RLSQueryParamType {
  object = 'object',
  date = 'date',
  number = 'number',
  array = 'array',
  bool = 'bool',
}

interface GlobalStateFromUrl {
  intl: {
    currentLocale: Nullable<string>;
  };

  currency: {
    currentCurrency: Nullable<string>;
  };

  user: {
    gclid: Nullable<string>;
    utm_campaign: Nullable<string>;
    utm_medium: Nullable<string>;
    utm_source: Nullable<string>;
  };
}

interface HotelStateFromUrl {
  searchParams: {
    destination: {
      position: {
        lat: Nullable<string | number>;
        lng: Nullable<string | number>;
      };

      placeId: Nullable<string>;
    };

    dates: Nullable<Dates>;
    rooms: Nullable<Room[]>;
    es: Nullable<string>; // encoded string
    k: Nullable<string>; // "hotelId:checkIn:checkOut:rooms:country"
  };

  offers: {
    searchId: Nullable<number>;
  };
}

export type StateShapeFromQuery = GlobalStateFromUrl & HotelStateFromUrl;

export const typeHandles = {
  number: {
    serialize(paramValue: any) {
      return paramValue.toString();
    },
    parse(paramValue: any) {
      return parseFloat(paramValue);
    },
  },
  array: {
    serialize(paramValue: any, options: any) {
      return (
        options.keepOrder ? [...paramValue] : [...paramValue].sort()
      ).join(options.delimiter || ',');
    },
    parse(paramValue: any, options: any) {
      return decodeURIComponent(paramValue).split(options.delimiter || ',');
    },
  },
  bool: {
    serialize(paramValue: any) {
      return paramValue.toString();
    },
    parse(paramValue: any) {
      return paramValue === 'true';
    },
  },
};

function _toConsumableArray(arr: any) {
  if (Array.isArray(arr)) {
    // eslint-disable-next-line no-var
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function getMatchingDeclaredPath(initialState: any, location: any) {
  const allPathItems = location.pathname.split('/');
  const initialStateKeys = Object.keys(initialState);
  //find the matched object
  const matchedItem = initialStateKeys.filter(function (item) {
    // make a copy to match against
    const pathToMatchAgainst = [].concat(
      _toConsumableArray(allPathItems) as any
    );
    // take declared path and split
    const initialDeclareditemSplit = item.split('/');
    // make a copy to destroy
    const reducedInitialItem = [].concat(
      _toConsumableArray(initialDeclareditemSplit) as any
    );
    let deleted = 0;
    //destructive, but since its in a filter it should be fine
    initialDeclareditemSplit.forEach(function (split, index) {
      //if the item has a * remove that query from both the match and the item to match
      if (split === '*') {
        pathToMatchAgainst.splice(index - deleted, 1);
        reducedInitialItem.splice(index - deleted, 1);
        deleted++;
      }
    });
    // match the final strings sans wildcards against each other
    return pathToMatchAgainst.join('/') === reducedInitialItem.join('/');
  });
  return matchedItem[0];
}

function createObjectFromConfig(initialState: any, location: any) {
  if (!initialState) {
    return;
  }
  const declaredPath = getMatchingDeclaredPath(initialState, location);
  return initialState.global
    ? Object.assign({}, initialState.global, initialState[declaredPath] || {})
    : initialState[declaredPath];
}

export function stateToParams(
  initialState: any,
  currentState: any,
  location: any
) {
  const pathConfig = createObjectFromConfig(initialState, location);
  const query = parse(location.search, parseClientOptions);
  if (!pathConfig) {
    return { location: { ...location } };
  }
  let shouldPush = false;
  //check the original config for values
  const newQueryParams = Object.keys(pathConfig).reduce((prev, curr) => {
    const {
      stateKey,
      options = {},
      initialState: initialValue,
      type,
    } = pathConfig[curr];
    let currentItemState = get(currentState, stateKey);
    let isDefault;
    //check if the date is the same as the one in initial value
    if (type === 'date') {
      isDefault =
        currentItemState.toISOString().substring(0, 10) ===
        (initialValue && initialValue.toISOString().substring(0, 10));
    } else {
      //if an empty object, make currentItemState undefined
      if (
        currentItemState &&
        typeof currentItemState === 'object' &&
        !Object.keys(currentItemState).length
      ) {
        currentItemState = undefined;
      }
      // check if the item is default
      isDefault =
        typeof currentItemState === 'object'
          ? isEqual(initialValue, currentItemState)
          : currentItemState === initialValue;
    }
    // if it is default or doesn't exist don't make a query parameter
    if (
      ((typeof currentItemState === 'undefined' && !options.serialize) ||
        isDefault) &&
      !options.setAsEmptyItem
    ) {
      if (isDefault) {
        prev[curr] = undefined;
      }
      return prev;
    }
    // otherwise, check if there is a serialize function
    if (options.serialize) {
      const itemState = options.serialize(currentItemState);
      // short circuit if specialized serializer returns specifically undefined
      if (typeof itemState === 'undefined') {
        return prev;
      }
      currentItemState = itemState;
    } else if (type) {
      currentItemState = typeHandles[
        type as keyof typeof typeHandles
      ].serialize(currentItemState, options);
    }
    // add new params to reduced object
    prev[curr] = currentItemState;
    //check if a shouldPush property has changed
    if (currentItemState !== query[curr] && options.shouldPush) {
      shouldPush = true;
    }
    return prev;
  }, {} as any);

  return {
    location: {
      ...location,
      search: stringifyUrl(
        { url: '', query: { ...query, ...newQueryParams } },
        stringifyClientOptions
      ),
    },
    shouldPush,
  };
}

export const queryParamMapSetup: RLSSetupObject = {
  global: {
    locale: {
      stateKey: 'intl.currentLocale',
      initialState: DEFAULT_LOCALE,
      options: { shouldPush: true },
    },
    currency: {
      stateKey: 'currency.currentCurrency',
      initialState: DEFAULT_CURRENCY,
      options: { shouldPush: true },
    },
    gclid: {
      stateKey: 'user.gclid',
      options: { shouldPush: true },
    },

    utm_campaign: {
      stateKey: 'user.utm_campaign',
      options: { shouldPush: true },
    },

    utm_medium: {
      stateKey: 'user.utm_medium',
      options: { shouldPush: true },
    },

    utm_source: {
      stateKey: 'user.utm_source',
      options: { shouldPush: true },
    },
  },
  '/hotel/*': {
    s_dates: {
      stateKey: 'searchParams.dates',
      type: RLSQueryParamType.array,
      options: {
        shouldPush: false,
        parse: datesUrlParser,
        serialize: (dates: [Date, Date]) =>
          dates.map((item) => format(item, 'yyyyMMdd')).join(','),
        delimiter: arraySerializeDelimiter,
      },
    },
    s_room: {
      stateKey: 'searchParams.rooms',
      options: {
        shouldPush: false,
        serialize: roomConverter.serialize,
        parse: roomConverter.parse,
      },
    },
    s_tfb: {
      stateKey: 'searchParams.businessTrip',
      type: RLSQueryParamType.bool,
      options: {
        shouldPush: false,
      },
    },
    es: {
      stateKey: 'deprecated.es',
      options: {
        shouldPush: false,
      },
    },
    searchId: {
      stateKey: 'deprecated.searchId',
      type: RLSQueryParamType.number,
      options: {
        shouldPush: false,
      },
    },
  },
  '/checkout': {
    checkout: {
      stateKey: 'checkout.checkoutId',
    },
    hotel: {
      stateKey: 'hotel.hotelId',
    },
    package: {
      stateKey: 'checkout.packageId',
    },
    search: {
      stateKey: 'deprecated.searchId',
    },
    offerKey: {
      stateKey: 'checkout.offerKey',
    },
    es: {
      stateKey: 'deprecated.es',
    },
  },
  '/confirmation': {
    offerKey: {
      stateKey: 'checkout.offerKey',
    },
  },
  '/reset-password': {
    token: {
      stateKey: 'user.resetPassword.token',
    },
  },
  '/unsubscribe': {
    token: {
      stateKey: 'user.unsubscribe.token',
    },
  },
};

export const getQueryParamsByPathname = (pathname: string): RLSQueryParams =>
  Object.keys(queryParamMapSetup).reduce((queryParams, pathKey) => {
    const formattedPathKey = pathKey.replace(/\/\*/g, '');
    const regExp = pathToRegexp(formattedPathKey, [], { end: false });

    return regExp.test(pathname) || pathKey === 'global'
      ? { ...queryParams, ...queryParamMapSetup[pathKey] }
      : queryParams;
  }, {});
