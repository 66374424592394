import { VFC } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { I18n } from 'src/components/I18n';
import { NumberStepper } from 'src/components/common/NumberStepper';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

import { FieldsName } from '../../..';

import { RoomChooserFormItem } from './RoomChooserFormItem';

import { FormSectionProps, MAX_GUESTS } from '.';

type Props = FormSectionProps & { labelId: DefaultAppMessagesTypeKey };

export const Adults: VFC<Props> = ({ index, width, labelId }) => {
  const children: number = useWatch({
    name: `${FieldsName.ROOMS}.${index}.children.length`,
  });

  return (
    <RoomChooserFormItem label={<I18n id={labelId} />} width={width}>
      <Controller
        render={({ field: { ref, ...field } }) => (
          <NumberStepper min={1} max={MAX_GUESTS - children} {...field} />
        )}
        name={`${FieldsName.ROOMS}.${index}.adults`}
      />
    </RoomChooserFormItem>
  );
};
