import { FC } from 'react';
import _isArray from 'lodash/isArray';

import { withGalleryModal } from 'src/hocs/withGalleryModal';

import { HotelPreviewProps, HotelPreviewSize } from './type';
import { HotelPreviewSmall } from './HotelPreviewSmall';
import { HotelPreviewMedium } from './HotelPreviewMedium';
import { HotelPreviewLarge } from './HotelPreviewLarge';

export type Props = Omit<HotelPreviewProps, 'size'> &
  Required<Pick<HotelPreviewProps, 'size'>> & {
    withGallery?: boolean;
  };

const previewComponentToSizeMap = {
  [HotelPreviewSize.SMALL]: HotelPreviewSmall,
  [HotelPreviewSize.MEDIUM]: HotelPreviewMedium,
  [HotelPreviewSize.LARGE]: HotelPreviewLarge,
};

const previewComponentWithGalleryToSizeMap = {
  [HotelPreviewSize.SMALL]: withGalleryModal(HotelPreviewSmall),
  [HotelPreviewSize.MEDIUM]: withGalleryModal(HotelPreviewMedium),
  [HotelPreviewSize.LARGE]: withGalleryModal(HotelPreviewLarge),
};

export const HotelPreview: FC<Props> = ({ withGallery, ...props }) => {
  const { size, images } = props;

  if (!_isArray(images)) {
    return null;
  }

  const PreviewComponent = getPreviewComponent(size, withGallery);

  return <PreviewComponent {...props} images={images} />;
};

const getPreviewComponent = (size: HotelPreviewSize, withGallery = false) =>
  withGallery
    ? previewComponentWithGalleryToSizeMap[size]
    : previewComponentToSizeMap[size];
