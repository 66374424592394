import { VFC, memo, ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';
import { CustomButton } from 'src/components/shared/CustomButton';
import MainContainer from 'src/components/common/MainContainer';

const StyledMainContainer = styled(MainContainer)`
  padding: 75px 30px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 360px;

  ${media.phone`
  padding: 0px;
    height: 100vh;
    max-width: 295px;
  `}
`;

const Image = styled.img.attrs({
  alt: 'Not Authenticated',
  src: '/assets/i/403_image.png',
})`
  ${media.phone`
max-width: 225px;
`}
`;

const Header = styled.div`
  margin: 10px 0;
  color: ${(props) => props.theme.custom.darkMainColor};
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  line-height: 1.2;
  font-family: ${(p) => p.theme.custom.fontFamily};

  ${media.phone`
    font-size: 22px;
  `}
`;

const Text = styled.div`
  margin-bottom: 40px;
  color: ${(props) => props.theme.custom.darkMainColor};
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  font-family: ${(p) => p.theme.custom.fontFamily};

  ${media.phone`
    margin-bottom: 50px;
  `}
`;

interface ErrorPageProps {
  header: ReactNode;
  text: ReactNode;
  buttonText: ReactNode;
  onBtnClick: (e: SyntheticEvent) => void;
}

const StyledCustomButton = styled(CustomButton)`
  font-family: ${(p) => p.theme.custom.fontFamily};
  min-width: 216px;
`;

export const ErrorPage: VFC<ErrorPageProps> = memo(
  ({ header, text, buttonText, onBtnClick }) => (
    <StyledMainContainer size="sm">
      <Image />
      <Header>{header}</Header>
      <Text>{text}</Text>
      <StyledCustomButton btnType="primary" onClick={onBtnClick}>
        {buttonText}
      </StyledCustomButton>
    </StyledMainContainer>
  )
);
