import { of, Observable } from 'rxjs';
import { AxiosError } from 'axios';
import _isUndefined from 'lodash/isUndefined';
import _merge from 'lodash/merge';

import { ActionSuccess, ErrorResponseAction } from 'src/models/actions';

type MapResponse<T, OP> = ActionSuccess<any, T, OP>;

export const mapResponse = <T, OP = unknown>(
  type: string,
  response: T,
  options?: OP
): Observable<MapResponse<T, OP>> => {
  const action = {
    type,
    payload: response,
  };

  return !_isUndefined(options)
    ? of({ ...action, options })
    : of({ ...action });
};

export const mapErrorResponse = <T = unknown>(
  type: string,
  errorResponse: AxiosError<T>
): Observable<ErrorResponseAction<any, T>> => {
  const { response, message } = errorResponse;
  const actionError = {
    type,
    error: {
      message,
    },
  };

  if (response) {
    const { status, statusText, data } = response;

    return of({
      ...actionError,
      error: _merge(actionError.error, {
        status,
        statusText,
        data,
        // @ts-expect-error
        errors: data?.errors,
      }),
    });
  }

  return of(actionError);
};
