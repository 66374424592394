import { useEffect, useState } from 'react';
import { useIntl, IntlFormatters } from 'react-intl';
import { useTheme } from 'styled-components/macro';

import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { TypographyVariant } from 'src/configs/muiTheme';

export type IntlFormatRecord = Record<
  string | number,
  Parameters<IntlFormatters['formatMessage']>[0] & {
    id: DefaultAppMessagesTypeKey;
  } & {
    values?: Parameters<IntlFormatters['formatMessage']>[1];
  }
>;

export const useMaxTypographyWidth = (
  variant: TypographyVariant,
  obj: IntlFormatRecord
) => {
  const [maxLabelWidth, setMaxLabelWidth] = useState<'auto' | `${number}px`>(
    'auto'
  );

  const { formatMessage, locale } = useIntl();

  const theme = useTheme();

  useEffect(() => {
    const ctx = document.createElement('canvas').getContext('2d');

    if (ctx) {
      const t = theme.typography[variant];

      ctx.font = `${t.fontWeight} ${t.fontSize} ${theme.typography.fontFamily}`;

      setMaxLabelWidth(
        `${
          Math.floor(
            Math.max(
              ...Object.values(obj).map(
                (item) =>
                  ctx.measureText(formatMessage(item, item.values)).width
              )
            )
          ) + 1
        }px`
      );
    }
  }, [variant, obj, locale, theme]);

  return maxLabelWidth;
};
