import styled, { css } from 'styled-components/macro';
import { FC } from 'react';

const withPaddingMixin = css`
  padding-top: ${({ theme }) => theme.spacing(4)};
`;

const Wrapper = styled.div<DrawerStickyBottomContainerProps>`
  ${({ withPadding }) => withPadding && withPaddingMixin}
  margin-left: calc(var(--padding) * -1);
  margin-right: calc(var(--padding) * -1);
  margin-top: auto;
  bottom: 0;
  position: sticky;
  z-index: 1;
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(6)};
  box-shadow: ${({ theme }) => theme.customShadows.widget};
  transform: translateY(var(--padding));
  margin-top: calc(var(--padding) * -1);
  width: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  display: flex;
`;

export type DrawerStickyBottomContainerProps = { withPadding?: boolean };

const DrawerStickyBottomContainer: FC<DrawerStickyBottomContainerProps> = ({
  children,
  withPadding,
}) => (
  <Wrapper withPadding={withPadding}>
    <Container>{children}</Container>
  </Wrapper>
);

export default DrawerStickyBottomContainer;
