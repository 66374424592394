import differenceInMonths from 'date-fns/differenceInMonths';
import startOfMonth from 'date-fns/startOfMonth';
import { useEffect, useRef, VFC, RefObject, useState, forwardRef } from 'react';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components/macro';
import { addScrollableTarget } from 'scroll-lock';

import { useAdaptiveEffect } from 'src/hooks/useAdaptiveEffect';
import useMergedRef from 'src/hooks/useMergedRef';

import { CalendarProps } from '..';
import { useCalendarLocale } from '../CalendarProvider';

import { ListedMonth } from './ListedMonth';
import { handleMonthHeight } from './utils';

export const GUTTER_SIZE = 20;

const StyledInfinityListContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export type VerticalCalendarProps = {
  parentRef: RefObject<HTMLElement>;
};

const VerticalCalendarInner = forwardRef<
  HTMLDivElement,
  CalendarProps & { height: number }
>(({ value, onChange, height }, outerRef) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const ref = useMergedRef(outerRef, innerRef);

  const locale = useCalendarLocale();

  const from = new Date();

  const instanceRef = useRef<VariableSizeList>(null);

  useEffect(() => {
    const start = value[0];

    if (start) {
      const diff = differenceInMonths(startOfMonth(start), startOfMonth(from));

      if (diff) instanceRef.current?.scrollToItem(diff, 'start');
    }

    const el = innerRef.current;

    if (el) {
      addScrollableTarget(el);
    }
  }, []);

  return (
    <VariableSizeList
      itemCount={100000}
      itemData={{ from, value, onChange }}
      ref={instanceRef}
      height={height}
      width="100%"
      children={ListedMonth}
      itemSize={handleMonthHeight(from, locale)}
      outerElementType={StyledInfinityListContainer}
      outerRef={ref}
    />
  );
});

type Props = VerticalCalendarProps & CalendarProps;

export const VerticalCalendar: VFC<Props> = ({
  value,
  onChange,
  parentRef,
}) => {
  const [height, setHeight] = useState(0);

  const selfRef = useRef<HTMLDivElement>(null);

  useAdaptiveEffect(() => {
    const parent = parentRef.current;

    if (parent) {
      const prevHeight = selfRef.current?.clientHeight || 0;

      setHeight(
        prevHeight +
          parent.clientHeight -
          Array.from(parent.children).reduce(
            (sum, item) => sum + item.clientHeight,
            0
          )
      );
    }
  }, []);

  if (height === 0) return null;

  return (
    <VerticalCalendarInner
      height={height}
      value={value}
      onChange={onChange}
      ref={selfRef}
    />
  );
};
