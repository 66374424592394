import { Action } from 'redux';

import { AccountActionTypes } from 'src/constants';
import { ActionRequest, StateAction } from 'src/models/actions';
import {
  AccountLoginRequest,
  AccountRegisterRequest,
  AccountChangePasswordRequest,
  AccountResetPasswordRequest,
  AccountRequestResetPassword,
} from 'src/models/customers/account';
import {
  CustomersPatchRequest,
  CustomerBookingsCancelRequest,
} from 'src/models/customers/manager';
import {
  FavoritePostRequest,
  FavoriteDeleteRequest,
} from 'src/models/customers/favorite';
import { Consents } from 'src/models/customers/common';
import { UserState } from 'src/store/user/reducers';

interface Pagination {
  startIndex: number;
  limit: number;
  isNewSearchStarted?: boolean;
}

export type FetchBookingsAction = ActionRequest<
  AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_REQUEST,
  Pagination
>;

export function fetchBookings(params: Pagination): FetchBookingsAction {
  return {
    type: AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_REQUEST,
    params,
  };
}

export type CancelBookingAction = ActionRequest<
  AccountActionTypes.CANCEL_ACCOUNT_BOOKING_REQUEST,
  CustomerBookingsCancelRequest
>;

export function cancelBooking(
  params: CustomerBookingsCancelRequest
): CancelBookingAction {
  return {
    type: AccountActionTypes.CANCEL_ACCOUNT_BOOKING_REQUEST,
    params,
  };
}

export type LoginAction = ActionRequest<
  AccountActionTypes.USER_LOGIN_REQUEST,
  AccountLoginRequest
>;

export function login(credential: AccountLoginRequest): LoginAction {
  return {
    type: AccountActionTypes.USER_LOGIN_REQUEST,
    params: credential,
  };
}

export type logOutAction =
  ActionRequest<AccountActionTypes.USER_LOGOUT_REQUEST>;

export function logOut(): logOutAction {
  return {
    type: AccountActionTypes.USER_LOGOUT_REQUEST,
  };
}

type CustomerRequestParams = Partial<CustomersPatchRequest>;

export type UpdateUserInfoAction = ActionRequest<
  AccountActionTypes.UPDATE_ACCOUNT_USER_REQUEST,
  CustomerRequestParams
>;

export function updateUserInfo(
  userInfo: CustomerRequestParams
): UpdateUserInfoAction {
  return {
    type: AccountActionTypes.UPDATE_ACCOUNT_USER_REQUEST,
    params: userInfo,
  };
}

export type SignUpAction = ActionRequest<
  AccountActionTypes.USER_SIGN_UP_REQUEST,
  AccountRegisterRequest
>;

export function signUp(credential: AccountRegisterRequest): SignUpAction {
  return {
    type: AccountActionTypes.USER_SIGN_UP_REQUEST,
    params: credential,
  };
}

type FavoriteChangeRequest = FavoriteDeleteRequest & FavoritePostRequest;

export interface FavoriteStatus extends FavoriteChangeRequest {
  isFavorite: boolean;
}

export function getUser(): Action<AccountActionTypes.FETCH_USER_INFO_REQUEST> {
  return {
    type: AccountActionTypes.FETCH_USER_INFO_REQUEST,
  };
}

export type SendResetPasswordLink = ActionRequest<
  AccountActionTypes.SEND_RESET_PASSWORD_LINK_REQUEST,
  AccountRequestResetPassword
>;

export function sendResetPasswordLink(
  params: AccountRequestResetPassword
): SendResetPasswordLink {
  return {
    type: AccountActionTypes.SEND_RESET_PASSWORD_LINK_REQUEST,
    params,
  };
}

export type ResetUserPasswordAction = ActionRequest<
  AccountActionTypes.RESET_ACCOUNT_PASSWORD_REQUEST,
  AccountResetPasswordRequest
>;

export function resetUserPassword(
  userCredentials: AccountResetPasswordRequest
): ResetUserPasswordAction {
  return {
    type: AccountActionTypes.RESET_ACCOUNT_PASSWORD_REQUEST,
    params: userCredentials,
  };
}

export type ChangeUserPasswordAction = ActionRequest<
  AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_REQUEST,
  AccountChangePasswordRequest
>;

export function changeUserPassword(
  userCredentials: AccountChangePasswordRequest
): ChangeUserPasswordAction {
  return {
    type: AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_REQUEST,
    params: userCredentials,
  };
}

export type UpdateUserConsentsAction = ActionRequest<
  AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_REQUEST,
  Consents
>;

export function updateUserConsents(
  consents: Consents
): UpdateUserConsentsAction {
  return {
    type: AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_REQUEST,
    params: consents,
  };
}

export function cleanUpUserMetaInfo() {
  return {
    type: AccountActionTypes.CLEAN_UP_USER_META_INFO,
  };
}

export type UnsubscribeUserAction =
  ActionRequest<AccountActionTypes.UNSUBSCRIBE_USER_REQUEST>;

export function unsubscribeUserByToken(): UnsubscribeUserAction {
  return {
    type: AccountActionTypes.UNSUBSCRIBE_USER_REQUEST,
  };
}

export type SubscribeUserAction =
  ActionRequest<AccountActionTypes.SUBSCRIBE_USER_REQUEST>;

export function subscribeUserByToken(): SubscribeUserAction {
  return {
    type: AccountActionTypes.SUBSCRIBE_USER_REQUEST,
  };
}

type Key = keyof Pick<
  UserState,
  | 'resetPasswordLink'
  | 'resetPassword'
  | 'accountSettings'
  | 'unsubscribe'
  | 'cancelBooking'
>;

export type ResetAccountErrorCode = StateAction<
  AccountActionTypes.RESET_ERROR_CODE,
  { key?: Key }
>;

export function resetAccountErrorCode(key?: Key): ResetAccountErrorCode {
  return {
    type: AccountActionTypes.RESET_ERROR_CODE,
    key,
  };
}

export function updateErrorCode(errorCode: number): UpdateErrorCodeAction {
  return {
    type: AccountActionTypes.USER_UPDATE_ERROR_CODE,
    payload: errorCode,
  };
}

export interface UpdateErrorCodeAction {
  type: typeof AccountActionTypes.USER_UPDATE_ERROR_CODE;
  payload: number;
}
