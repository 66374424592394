import { FC, memo } from 'react';
import styled from 'styled-components/macro';
import { iframeResizer } from 'iframe-resizer';
import lozad from 'lozad';
import { useSelector } from 'react-redux';

import CPanel, { HeaderTitle } from 'src/components/shared/CPanel';
import useLozad from 'src/hooks/useLozad';
import { appConfig } from 'src/modules/app-config';
import { breakPoints } from 'src/configs';
import { I18n } from 'src/components/I18n';
import { intlSelector } from 'src/store/intl/selectors';

/**
 * We have to use special breakpoints for Trustyou's iframe for mobile view;
 */
const PADDINGS = 60;
const MOBILE_SIZE_TRUSTYOU = breakPoints.md + PADDINGS;

const Wrapper = styled.div`
  ${HeaderTitle} {
    padding-top: 0;
  }
`;

const StyledIframe = styled.iframe`
  width: 1px;
  min-width: 100%;
  border: 1px solid ${(p) => p.theme.custom.borderColor};

  @media (max-width: ${MOBILE_SIZE_TRUSTYOU + 1}px) {
    padding: 0 10px;
    background-color: ${(p) => p.theme.custom.whiteMainColor};
  }
`;

const lozadOptions: lozad.Option = {
  loaded: (element) => {
    element.addEventListener('load', () => {
      iframeResizer({}, element as HTMLIFrameElement);
    });
  },
};

interface HotelReviewProps {
  hotelTrustYouId: string;
  sectionRef?: (el: HTMLDivElement | null) => void;
}

const HotelReview: FC<HotelReviewProps> = memo((props) => {
  const { hotelTrustYouId, sectionRef } = props;
  const { currentLocale } = useSelector(intlSelector);
  const src = `https://api.trustyou.com/hotels/${hotelTrustYouId}/meta_review.html?iframe_resizer=true&lang=${currentLocale}&key=24dc6ebf-64f7-43c8-b799-b65b7bc46691`;

  const iframeRef = useLozad<HTMLIFrameElement>(lozadOptions);
  const panelTitle = <I18n id="HOTEL_DETAILS_PAGE.REVIEWS.TITLE" />;

  return (
    <Wrapper ref={sectionRef} data-id="reviews">
      <CPanel title={panelTitle}>
        <StyledIframe data-src={src} scrolling="no" ref={iframeRef} />
      </CPanel>
    </Wrapper>
  );
});

export default HotelReview;
