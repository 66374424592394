import { VFC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { I18n } from 'src/components/I18n';
import { Button } from 'src/components/common/WrappedButtons';
import joinBy from 'src/modules/joinBy';
import { SearchParamsState } from 'src/store/search/reducers';
import {
  IntlFormatRecord,
  useMaxTypographyWidth,
} from 'src/hooks/useMaxTypographyWidth';

import { ListItem } from '../../../ListItem';
import { FieldsName } from '../../..';

import { Adults } from './Adults';
import { Children } from './Children';
import { labelVariant } from './RoomChooserFormItem';

export type FormSectionProps = { index: number; width: string };

export const MAX_GUESTS = 4;
export const MAX_CHILDREN = 2;
export const MAX_ROOMS = 4;

const StyledButton = styled(Button)`
  position: absolute;
  transform: translate(${({ theme }) => theme.spacing(2)}, -50%);
  border-radius: 50%;
  &&& {
    border-width: 1px;
  }
  line-height: 1;
  font-size: 14px;
  padding: 2px;
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const labels = (<T extends IntlFormatRecord>(v: T) => v)({
  adults: {
    id: 'HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.ADULTS_TITLE',
  },
  children: {
    id: 'HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.CHILDREN_TITLE',
  },
  childrenAge: {
    id: 'HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.CHILDREN_AGE_TITLE',
    values: { number: MAX_CHILDREN },
  },
});

export const RoomChooserForm: VFC = () => {
  const { fields, append, remove } = useFieldArray<
    SearchParamsState,
    FieldsName.ROOMS
  >({
    name: FieldsName.ROOMS,
  });

  const maxLabelWidth = useMaxTypographyWidth(labelVariant, labels);

  return (
    <Stack spacing={5}>
      {joinBy(
        fields.map((item, index) => (
          <ListItem
            key={item.id}
            title={
              <>
                <I18n
                  id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.TITLE"
                  values={{ count: index + 1 }}
                />
                {fields.length > 1 && (
                  <StyledButton
                    variant="outlined"
                    color="buttonSecondary"
                    onClick={() => remove(index)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </StyledButton>
                )}
              </>
            }
          >
            <Adults
              index={index}
              width={maxLabelWidth}
              labelId={labels.adults.id}
            />
            <Children
              index={index}
              width={maxLabelWidth}
              labelId={labels.children.id}
              ageLabelId={labels.childrenAge.id}
            />
          </ListItem>
        )),
        <Divider />
      )}
      <Divider />
      <Button
        variant="text"
        color="secondary"
        disableRipple
        onClick={() => append({ adults: 2, children: [] })}
        disabled={fields.length >= MAX_ROOMS}
        startIcon={<AddIcon />}
        style={{ justifyContent: 'flex-start' }}
      >
        <I18n id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.ADD_ROOM" />
      </Button>
    </Stack>
  );
};
