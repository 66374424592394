import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import { DeprecatedTypes } from 'src/constants';

export type DeprecatedState = {
  es?: string;
  searchId?: number;
  Residency?: string;
};

export const deprecated = createReducer(
  {},
  {
    [DeprecatedTypes.SET_DEPRECATED](
      state: DeprecatedState,
      action: {
        payload: Partial<DeprecatedState>;
      }
    ) {
      return update(state, {
        $merge: action.payload,
      });
    },
  }
);
