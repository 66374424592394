import { FC } from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { I18n } from 'src/components/I18n';
import { fontSizeSmall } from 'src/modules/fontSizes';

const ImagesCountWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => rgba(p.theme.custom.darkMainColor, 0.83)};
  color: ${(p) => p.theme.custom.whiteMainColor};
  ${fontSizeSmall};
  font-weight: 500;
  text-align: center;
`;

interface ImagesCountProps {
  className?: string;
  imagesCount: number;
}

export const ImagesCount: FC<ImagesCountProps> = ({
  className,
  imagesCount,
}) => (
  <ImagesCountWrapper className={className}>
    <I18n
      id="HOTEL_DETAILS_PAGE.HOTEL_PREVIEW"
      values={{ imageNumber: imagesCount }}
    />
  </ImagesCountWrapper>
);
