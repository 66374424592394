import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { Progress } from 'src/components/shared/CProgress';
import { hotelSelector } from 'src/store/hotel/selectors';
import CDrawer from 'src/components/common/CDrawer';
import { I18n } from 'src/components/I18n';
import useMatchMedia from 'src/hooks/useMatchMedia';
import CModalContainer from 'src/components/common/CModalContainer';
import Container from 'src/components/common/Container';

import { SearchForm } from './SearchForm';
import { HotelDetailsPortalContent } from './HotelDetailsPortalContent';

export interface HotelDetailsPortalProps {
  isSearchFormPortalOpen: boolean;
  setSearchFormPortalOpen: (value: boolean) => void;
  isStatic?: boolean;
}

const StyledWrapper = styled.div`
  margin: auto;

  width: 100%;
`;

const StyledContainer = styled(Container)`
  padding: var(--gutter);
`;

export const HotelDetailsPortal: FC<any> = memo(
  ({ isSearchFormPortalOpen, setSearchFormPortalOpen, isStatic }) => {
    const { isFinished: isSearchFinished } = useSelector(hotelSelector);

    const togglePortalOpen = () => {
      setSearchFormPortalOpen(!isSearchFormPortalOpen);
    };

    const isMobile = useMatchMedia(useTheme().breakpoints.down('sm'));

    const form = (
      <SearchForm onHandleSubmit={togglePortalOpen} isStatic={isStatic} />
    );

    return (
      <>
        <HotelDetailsPortalContent
          onChangeClick={togglePortalOpen}
          isStatic={isStatic}
        />
        <Progress isFinished={isStatic || isSearchFinished} />
        {isMobile ? (
          <CDrawer
            open={isSearchFormPortalOpen}
            onClose={togglePortalOpen}
            header={<I18n id="SEARCH_PANEL.MOBILE_PORTAL_TITLE" />}
          >
            {form}
          </CDrawer>
        ) : (
          <CModalContainer
            isOpen={isSearchFormPortalOpen}
            onClose={togglePortalOpen}
          >
            <StyledWrapper>
              <StyledContainer disableMargin>{form}</StyledContainer>
            </StyledWrapper>
          </CModalContainer>
        )}
      </>
    );
  }
);
