import { createContext, useEffect, useState, FC } from 'react';
import _debounce from 'lodash/debounce';

import { breakPoints } from 'src/configs';

const { md, lg } = breakPoints;

export enum ScreenSize {
  mobile = 'mobile',
  desktop = 'desktop',
  tablet = 'tablet',
}

interface Device {
  screenSize: ScreenSize;
}

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const recognizeDeviceBy = (screenWidth?: number): ScreenSize => {
  screenWidth = screenWidth || getSize().width;
  if (screenWidth < md) {
    return ScreenSize.mobile;
  }

  if (screenWidth < lg) {
    return ScreenSize.tablet;
  }

  return ScreenSize.desktop;
};

export const DeviceContext = createContext<Device>({
  screenSize: ScreenSize.desktop,
});

export const DeviceProvider: FC = ({ children }) => {
  const [device, setDevice] = useState<Device>({
    screenSize: recognizeDeviceBy(),
  });

  const handleResize = _debounce(() => {
    const { width } = getSize();
    const screenSize = recognizeDeviceBy(width);
    setDevice({ screenSize });
  }, 60);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  );
};
