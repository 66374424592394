import { Action } from 'redux';

import { CurrencyActionTypes } from 'src/constants';
import type { CurrencyType } from 'src/containers/ConnectedIntl/messages/defaultMessages';

interface Currency {
  currency: CurrencyType;
  rate: number;
}

const defaultCurrency: Currency = {
  currency: 'EUR',
  rate: 1,
};

export type ChangeCurrencyAction = Action<CurrencyActionTypes.UPDATE_CURRENCY> &
  Currency;

export type InitCurrencyAction = Action<CurrencyActionTypes.INIT_CURRENCY> &
  Currency;

export function changeCurrency({
  currency,
  rate,
}: Currency = defaultCurrency): ChangeCurrencyAction {
  return {
    type: CurrencyActionTypes.UPDATE_CURRENCY,
    currency,
    rate,
  };
}

export function initCurrency({
  currency,
  rate,
}: Currency = defaultCurrency): InitCurrencyAction {
  return {
    type: CurrencyActionTypes.INIT_CURRENCY,
    currency,
    rate,
  };
}
