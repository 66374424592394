import { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Skeleton from '@mui/material/Skeleton';

import { Image } from 'src/types';
import {
  CtDomainMarketingPricePresentation,
  RoomGroup,
} from 'src/models/hotels';
import { RoomFacility } from 'src/models/config';
import { media } from 'src/modules/mediaQuery';
import CPanel, {
  HeaderTitle,
  HeaderWrapper,
  Container,
} from 'src/components/shared/CPanel';
import { I18n } from 'src/components/I18n';
import { ImagesCountSmall } from 'src/components/HotelPreview/ImagesCountSmall';
import { HotelPreviewSize } from 'src/components/HotelPreview/type';
import { CheckoutStatuses } from 'src/models/checkout';

import { NoImagePlaceholder, StyledHotelPreview } from './CommonComponents';
import { RoomPackages as Packages } from './Packages';

const RoomPanel = styled(CPanel)<{ highlighted?: boolean }>`
  margin-bottom: 25px;
  border: 1px solid ${(p) => p.theme.custom.greyAlternativeColor};
  border-radius: 12px;
  overflow: hidden;

  ${(p) => media.phone`
    background: ${p.theme.custom.whiteMainColor};
  `}

  ${HeaderWrapper} {
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px dashed ${(p) => p.theme.custom.greyAlternativeColor};
    background-color: ${(p) => p.theme.custom.whiteMainColor};

    ${media.phone`
      padding: 15px;
    `}
  }

  ${HeaderTitle} {
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    ${media.phone`
      font-size: 14px;
      line-height: 16px;
    `}
  }

  ${Container} {
    display: flex;
    padding: 20px;

    ${media.phone`
      flex-wrap: wrap;
      padding: 0;
    `}
  }

  ${({ highlighted, theme }) =>
    highlighted &&
    `
      border: 1px solid ${theme.custom.greenMainColor};

      ${HeaderWrapper} {
        background-color: ${theme.custom.greenMainColor};
      }

      ${HeaderTitle} {
        color: ${theme.custom.whiteMainColor};
      }
    `};
`;

const HotelPreviewWrapper = styled.div`
  margin-right: 25px;
  width: 20%;

  height: fit-content;
  border-radius: 5px;
  overflow: hidden;

  ${media.phone`
    margin-right: 0;
    padding: 15px;
    width: 100%;
  `}
`;

const CheapestRoom = styled.span`
  margin-right: 5px;
`;

const StyledSkeleton = styled(Skeleton)`
  @media (min-width: 768px) {
    padding-bottom: 100%;
  }
  @media (max-width: 767px) {
    padding-bottom: 60%;
  }
`;

interface RoomProps {
  cheapestRoom?: boolean;
  name: string;
  images: Image[];
  showImages: boolean;
  bookBtnsLoading: boolean;
  pricePresentation: CtDomainMarketingPricePresentation;
  packages?: RoomGroup[];
  facilities: RoomFacility[];
  totalNights?: number;
  marketingRoom?: boolean;
  extras?: ReactNode;
  status?: CheckoutStatuses;
  priceChangePercent?: null | number;
}

export const Room: FC<RoomProps> = ({
  packages = [],
  name,
  cheapestRoom,
  images,
  showImages,
  facilities,
  bookBtnsLoading,
  pricePresentation,
  totalNights,
  marketingRoom,
  extras,
  status,
  priceChangePercent,
}) => {
  const title = cheapestRoom ? (
    <span>
      <CheapestRoom>
        <I18n id="HOTEL_DETAILS_PAGE.ROOMS.CHEAPEST_ROOM" />
      </CheapestRoom>
      {name}
    </span>
  ) : (
    name
  );

  const id = cheapestRoom ? 'cheapest-room' : '';

  return (
    <RoomPanel
      withBorder
      highlighted={cheapestRoom || marketingRoom}
      title={title}
      id={id}
      extras={extras}
    >
      {showImages && (
        <HotelPreviewWrapper>
          {images ? (
            <StyledHotelPreview
              images={images}
              size={HotelPreviewSize.SMALL}
              withGallery={true}
              placeholder={<NoImagePlaceholder />}
            >
              {images.length > 1 && (
                <ImagesCountSmall imagesCount={images.length - 1} />
              )}
            </StyledHotelPreview>
          ) : (
            <StyledSkeleton variant="rectangular" animation="wave" />
          )}
        </HotelPreviewWrapper>
      )}
      <Packages
        bookBtnsLoading={bookBtnsLoading}
        pricePresentation={pricePresentation}
        packages={packages}
        facilities={facilities}
        images={images}
        totalNights={totalNights}
        status={status}
        priceChangePercent={priceChangePercent}
      />
    </RoomPanel>
  );
};
