import { forkJoin, from, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import moment from 'moment';
import _get from 'lodash/get';

import { ActionTypes } from 'src/constants';
import { KeyValueType, loadScript } from 'src/modules/helpers';
import { LOCALES_MAP } from 'src/configs';
import { ChangeLocaleAction } from 'src/store/intl/actions';
import { ReduxState } from 'src/store/reducers';
import { mapResponse, mapErrorResponse } from 'src/store/epicHelpers';

export interface UpdateLocaleResponse {
  messages: KeyValueType<string>;
  locale: string;
}

// for dynamic initializing locales
window.moment = moment;

const setMomentLocale = (localeName: string) => {
  moment.locale(localeName);
};

const loadMomentLocale = (localeName: string): Promise<void> =>
  loadScript(
    `/i18n/moment/${_get(LOCALES_MAP, `${localeName}.moment`, localeName)}.js`
  ).then(() => setMomentLocale(localeName));

const loadTranslations = (localeName: string) => {
  const fileName = `${_get(
    LOCALES_MAP,
    `${localeName}.translation`,
    localeName
  )}`;
  return ajax<Record<string, string>>(`/i18n/translations/${fileName}.json`);
};

export function handleLocaleChange(
  action$: Observable<ChangeLocaleAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(ActionTypes.UPDATE_LOCALE_REQUEST),
    mergeMap(({ locale }) => {
      const { intl } = state$.value;
      const messagesFromCache = intl.messages[locale];

      if (!messagesFromCache) {
        return forkJoin(
          from(loadTranslations(locale)),
          from(loadMomentLocale(locale))
        ).pipe(
          mergeMap((resp) =>
            mapResponse<UpdateLocaleResponse>(
              ActionTypes.UPDATE_LOCALE_SUCCESS,
              {
                messages: resp[0].response,
                locale,
              }
            )
          ),
          catchError((err) =>
            mapErrorResponse(ActionTypes.UPDATE_LOCALE_FAILURE, err)
          )
        );
      }

      setMomentLocale(locale);

      return mapResponse<UpdateLocaleResponse>(
        ActionTypes.UPDATE_LOCALE_SUCCESS,
        {
          messages: messagesFromCache,
          locale,
        }
      );
    })
  );
}
