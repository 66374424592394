const with0 = (v: number) => (v < 10 ? `0${v}` : String(v));

export const dateToStr = (item: Date) => {
  const year = item.getFullYear();
  const month = item.getMonth() + 1;
  const day = item.getDate();

  return `${year}${with0(month)}${with0(day)}T120000+0000`;
};

export const strToDate = (str: string) => {
  const year = Number(str.slice(0, 4));
  const month = Number(str.slice(4, 6));
  const day = Number(str.slice(6, 8));

  return new Date(year, month - 1, day);
};

export const getInputFormat = (locale: string) => {
  switch (locale) {
    case 'zh-CN':
    case 'ja':
      return 'yyyy-MM-dd';

    default:
      return 'dd MMM yyyy';
  }
};
