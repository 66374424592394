import ClickAwayListener, {
  ClickAwayListenerProps,
} from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade/Fade';
import Popper, { PopperProps } from '@mui/material/Popper';
import { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { modalDividerMixin } from './CModal';

export type CPopoverProps = PropsWithChildren<
  Omit<PopperProps, 'children' | 'style'> & {
    onClickOutside?: ClickAwayListenerProps['onClickAway'];
  }
>;

const style = { zIndex: 15 };

const Container = styled.div`
  --padding: ${({ theme }) => theme.spacing(5)};
  border-radius: 24px;
  padding: var(--padding);
  box-shadow: ${({ theme }) => theme.customShadows.widget};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.paper};
  position: relative;
  ${modalDividerMixin}
`;

export const CPopover = forwardRef<HTMLDivElement, CPopoverProps>(
  ({ children, onClickOutside, className, ...rest }, ref) => (
    <Popper {...rest} style={style} ref={ref} transition>
      {({ TransitionProps }) => {
        const component = (
          <Fade {...TransitionProps}>
            <Container className={className}>{children}</Container>
          </Fade>
        );

        return onClickOutside ? (
          <ClickAwayListener onClickAway={onClickOutside}>
            {component}
          </ClickAwayListener>
        ) : (
          component
        );
      }}
    </Popper>
  )
);
