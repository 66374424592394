import update from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import { PopUpActionTypes } from 'src/constants';
import { InfoPopUpAction, CheckoutPopUpAction } from 'src/store/popUp/actions';

import { CheckoutBookActionParams } from '../checkout/actions';

export enum InfoPopUpType {
  NONE = 'none',
  CANCEL_BOOKING = 'cancelBooking',
  CONTACT_US_SUCCESS = 'contactUsSuccess',
}

export enum CheckoutPopUpEnum {
  NONE = 'none',
  PRICE_CHANGED = 'price-changed',
  SOLD_OUT_ROOM = 'sold-out-room',
  SOLD_OUT_HOTEL = 'sold-out-hotel',
  EXPIRED = 'expired',
  EXPIRED_NI = 'expired-ni',
  REFUNDABILITY_CHANGED = 'refundability-changed',
}

export interface PopUp<T> {
  popUpName: T;
  popUpId?: string | number;
}

export type CheckoutPopUpStateType = PopUp<CheckoutPopUpEnum> & {
  bookParams?: CheckoutBookActionParams;
};

export interface PopUpStateType {
  infoPopUp: PopUp<InfoPopUpType>;
  checkoutPopUp: CheckoutPopUpStateType;
}

const popUpInitialState: PopUpStateType = {
  infoPopUp: {
    popUpName: InfoPopUpType.NONE,
  },
  checkoutPopUp: {
    popUpName: CheckoutPopUpEnum.NONE,
  },
};
export const popUpReducer = createReducer(popUpInitialState, {
  [PopUpActionTypes.CHANGE_INFO_POP_UP_VIEW]: (
    state: PopUpStateType,
    action: InfoPopUpAction
  ) => {
    const { popUpName, popUpId } = action;

    return update(state, {
      infoPopUp: {
        popUpName: { $set: popUpName },
        popUpId: { $set: popUpId },
      },
    });
  },
  [PopUpActionTypes.CHANGE_CHECKOUT_POP_UP]: (
    state: PopUpStateType,
    action: CheckoutPopUpAction
  ) => {
    const { popUpName, bookParams } = action;

    return update(state, {
      checkoutPopUp: {
        popUpName: { $set: popUpName },
        bookParams: { $set: bookParams },
      },
    });
  },
});
