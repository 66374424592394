import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { media } from 'src/modules/mediaQuery';

const BorderedBlock = styled.div`
  box-shadow: 5px 5px 30px ${(p) => rgba(p.theme.custom.blackMainColor, 0.05)};

  &:nth-child(n + 2) {
    margin-top: 20px;

    ${media.phone`
      margin-top: 10px;
    `}
  }
`;

export default BorderedBlock;
