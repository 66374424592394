import 'styled-components/macro';
import { useRef, ReactNode, useEffect, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import CDrawer, { CDrawerProps } from 'src/components/common/CDrawer';
import { Freeze } from 'src/components/common/Freeze';
import { Button } from 'src/components/common/WrappedButtons';

import DrawerStickyBottomContainer, {
  DrawerStickyBottomContainerProps,
} from '../../common/CDrawer/DrawerStickyBottomContainer';

type Props = Omit<CDrawerProps, 'SlideProps'> &
  DrawerStickyBottomContainerProps & {
    fieldName: string;
    buttonText: ReactNode;
  };

export const AppliedDrawer = forwardRef<HTMLDivElement, Props>(
  (
    { onClose, children, buttonText, fieldName, withPadding, ...props },
    ref
  ) => {
    const { getValues, setValue } = useFormContext();

    const currValueRef = useRef<any>();

    const setCurrValue = () => {
      currValueRef.current = getValues(fieldName);
    };

    useEffect(setCurrValue, [fieldName]);

    const handleClose = () => {
      setIsClosing(true);

      setValue(fieldName, currValueRef.current);

      onClose();
    };

    const handleApply = () => {
      setCurrValue();

      onClose();
    };

    const [isClosing, setIsClosing] = useState(false);

    const handleExited = () => {
      setIsClosing(false);
    };

    return (
      <CDrawer
        {...props}
        onClose={handleClose}
        SlideProps={{
          onExited: handleExited,
        }}
        ref={ref}
      >
        <Freeze freezed={isClosing}>{children}</Freeze>
        <DrawerStickyBottomContainer withPadding={withPadding}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            fullWidth
            endIcon={<ArrowForwardIcon />}
          >
            {buttonText}
          </Button>
        </DrawerStickyBottomContainer>
      </CDrawer>
    );
  }
);
