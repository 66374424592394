import startOfDay from 'date-fns/startOfDay';
import { VFC } from 'react';

import { HorizontalCalendar } from './HorizontalCalendar';
import { VerticalCalendar, VerticalCalendarProps } from './VerticalCalendar';

export enum EDateSelection {
  START,
  END,
}

export enum ECalendarType {
  HORIZONTAL,
  VERTICAL,
}

export type DateRange = [start: Date | null, end: Date | null];

export type DateProps = {
  value: DateRange;
  onChange: (value: DateRange) => void;
};

export type CalendarProps = {
  value: DateRange;
  onChange: (value: Date) => void;
};

type Props = DateProps &
  VerticalCalendarProps & {
    dateSelection: EDateSelection;
    setDateSelection: (v: EDateSelection) => void;
    type: ECalendarType;
  };

export const Calendar: VFC<Props> = ({
  value,
  onChange,
  dateSelection,
  setDateSelection,
  type,
  parentRef,
}) => {
  let handleChange: (value: Date) => void;

  const [start, end] = value;

  switch (dateSelection) {
    case EDateSelection.START:
      handleChange = (value) => {
        onChange([value, end && value < end ? end : null]);

        setDateSelection(EDateSelection.END);
      };

      break;

    case EDateSelection.END:
      handleChange = (value) => {
        if (
          !start ||
          value <= start ||
          (end && +value === +end) ||
          start < startOfDay(new Date())
        ) {
          onChange([value, null]);
        } else {
          onChange([start, value]);

          setDateSelection(EDateSelection.START);
        }
      };

      break;
  }

  switch (type) {
    case ECalendarType.HORIZONTAL:
      return <HorizontalCalendar onChange={handleChange} value={value} />;

    case ECalendarType.VERTICAL:
      return (
        <VerticalCalendar
          parentRef={parentRef}
          onChange={handleChange}
          value={value}
        />
      );
  }
};
