import useSWR, { Fetcher, SWRConfiguration } from 'swr';

import useDebounce from '../useDebounce';
import { useExistedPrevious } from '../usePrevious';

const useDebouncedSWR = <Key extends string | null, Data>(
  key: Key,
  fetcher: Fetcher<Data, Key>,
  config: SWRConfiguration<Data, Error, Fetcher<Data, [Key, string]>> & {
    debounce: number;
    longLoad?: number;
  }
) => {
  const debouncedKey = useDebounce(key, config.debounce);

  const swr = useSWR(debouncedKey, fetcher, config);

  const { data } = swr;

  const prevData = useExistedPrevious(data);

  if (!data) {
    Object.defineProperty(swr, 'data', { value: prevData });
  }

  return swr;
};

export default useDebouncedSWR;
