import { Action } from 'redux';

import { ConfirmationActionTypes } from 'src/constants';
import { CustomerOfferRequest } from 'src/models/customers/manager';

export type StartConfirmationPollingAction =
  Action<ConfirmationActionTypes.START_CONFIRMATION_POLLING> &
    CustomerOfferRequest;

type StopConfirmationPollingAction =
  Action<ConfirmationActionTypes.STOP_CONFIRMATION_POLLING>;

export const getConfirmationInfo = (
  offerKey: string
): StartConfirmationPollingAction => ({
  type: ConfirmationActionTypes.START_CONFIRMATION_POLLING,
  offerKey,
});

export const stopPolling = (): StopConfirmationPollingAction => ({
  type: ConfirmationActionTypes.STOP_CONFIRMATION_POLLING,
});
