import { OverlayView } from 'google-maps-js-api-react';
import { VFC } from 'react';
import styled from 'styled-components/macro';

import CGoogleMap, { mapOptions } from 'src/components/CGoogleMap';
import CGoogleMapModal from 'src/components/CGoogleMap/CGoogleMapModal';
import GoogleMapLoader from 'src/components/CGoogleMap/GoogleMapLoader';
import { DEFAULT_MAP_ZOOM } from 'src/configs';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { Marker } from './Marker';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  info: NonNullable<HotelInfoSelectorResult>;
};

type _Props = {
  coords: google.maps.LatLngLiteral;
};

const MarkerContainer = styled.div`
  transform: translate(-50%, -100%);
  display: flex;
`;

const MapModal: VFC<_Props> = ({ coords }) => (
  <CGoogleMap
    defaultOptions={{ ...mapOptions, center: coords, zoom: DEFAULT_MAP_ZOOM }}
  >
    <OverlayView component={MarkerContainer} {...coords}>
      <Marker />
    </OverlayView>
  </CGoogleMap>
);

const Map: VFC<Props> = ({ isOpen, onClose, info }) => (
  <CGoogleMapModal isOpen={isOpen} onClose={onClose}>
    <GoogleMapLoader component={MapModal} coords={info.geo} />
  </CGoogleMapModal>
);

export default Map;
