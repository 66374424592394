import _mapValues from 'lodash/mapValues';
import { MessageDescriptor } from 'react-intl';

import type { DefaultAppMessagesTypeKey } from './defaultMessages';
import defaultAppMessages from './defaultMessages.json';

export const defaultMessages: Record<
  DefaultAppMessagesTypeKey,
  MessageDescriptor
> = _mapValues<typeof defaultAppMessages, MessageDescriptor>(
  defaultAppMessages,
  (value, key) => ({
    id: key as DefaultAppMessagesTypeKey,
    defaultMessage: value,
  })
);
