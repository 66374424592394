import 'styled-components/macro';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  resetAccountErrorCode,
  sendResetPasswordLink,
} from 'src/store/user/actions';
import { I18n } from 'src/components/I18n';
import { emailValidation } from 'src/components/shared/FormElements/utils';
import { resetPasswordLinkSelector } from 'src/store/user/selectors';
import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';
import { LoadingTypes } from 'src/constants';
import { Link } from 'src/components/common/Link';

import { ContentForm } from '../ContentForm/ContentForm';
import { handleModal } from '../utils';
import { EAuth } from '..';

const validationSchema = yup.object().shape({
  email: emailValidation,
});

interface ResetPasswordSectionProps {
  setActiveModal?: (modal: EAuth) => void;
}

export const ResetPasswordSection = ({ setActiveModal }: ResetPasswordSectionProps) => {
  const dispatch = useDispatch();

  const { isLoading, errorCode } = useSelector(resetPasswordLinkSelector);

  useEffect(
    () => () => {
      dispatch(resetAccountErrorCode('resetPasswordLink'));
    },
    [dispatch]
  );

  const { handleSubmit, control } = useForm<{
    email: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '' },
  });

  const intlFormatMessage = useIntlFormatMessage();

  return (
    <ContentForm
      loading={isLoading === LoadingTypes.RUNNING}
      headerTextId="RESET_PASSWORD_FORM.MODAL.HEADER"
      submitButtonI18nId="RESET_PASSWORD_FORM.MODAL.BUTTON_NAME"
      hideOAuth
      alertCode={errorCode}
      onSubmit={handleSubmit((values) => {
        dispatch(sendResetPasswordLink(values));
      })}
      navigateBlock={
        <Typography variant="body4">
          <Link
            to={`/${EAuth.SIGN_IN}`}
            onClick={handleModal(setActiveModal, EAuth.SIGN_IN)}
            color="secondary"
            variant="body4"
          >
            Back to Sign in
          </Link>
        </Typography>
      }
    >
      <Typography variant="body3" textAlign="center" color="text.secondary">
        <I18n id="RESET_PASSWORD.MODAL.FORM_FIELDS.HEADER" />
      </Typography>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            placeholder={intlFormatMessage(
              'SIGN_IN_FORM.EMAIL_FIELD.PLACEHOLDER'
            )}
            color="focusSecondary"
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            label={<I18n id="SIGN_IN_FORM.EMAIL_FIELD.LABEL" />}
          />
        )}
      />
    </ContentForm>
  );
};
