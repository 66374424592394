import { FC } from 'react';
import styled from 'styled-components/macro';

const Wrap = styled.span`
  display: inline-block;
  padding: 0 5px;
`;

interface TextDividerProps {
  className?: string;
}

export const TextDivider: FC<TextDividerProps> = (props) => (
  <Wrap className={props.className}>|</Wrap>
);
