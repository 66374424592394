import { VFC } from 'react';
import { useSelector } from 'react-redux';

import { CPopoverProps } from 'src/components/common/CPopover';
import { userSelector } from 'src/store/user/selectors';

import { Account } from './Account';
import { SignIn } from './SignIn';

export type ContentProps = {
  onClose: () => void;
};

interface LogoIconProps {
  isContrast?: boolean;
}

export const authPopoverProps: Partial<CPopoverProps> = {
  disablePortal: true,
  placement: 'bottom-end',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
  ],
};

export const AuthBlock: VFC<LogoIconProps> = ({isContrast}) => {
  const { isAuthenticated } = useSelector(userSelector);

  return isAuthenticated ? <Account isContrast={isContrast} /> : <SignIn isContrast={isContrast} />;
};
