import { FC, ButtonHTMLAttributes } from 'react';
import styled, {
  css,
  DefaultTheme,
  ThemeProps,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components/macro';
import { rgba } from 'polished';

const superLargeButtonStyles = css`
  height: 72px;
  padding: 0 25px;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
`;

const largeButtonStyles = css`
  height: 46px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
`;

const mediumButtonStyles = css`
  height: 34px;
  padding: 0 15px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
`;

const blockButtonStyles = css`
  width: 100%;
`;

const disabledButtonStyles = css`
  background-color: ${(p) => p.theme.custom.greyAlternativeColor};
  color: ${(p) => p.theme.custom.grayTextColor};
  cursor: not-allowed;
`;

const linkButtonStyles = css`
  padding: 0;
  background-color: transparent;
  color: ${(p) => p.theme.custom.linkColor};
  font-size: inherit;
  line-height: inherit;
  height: auto;

  &:hover,
  &:focus {
    color: ${(p) => p.theme.custom.linkHoverColor};
  }

  &:active {
    color: ${(p) => p.theme.custom.linkActiveColor};
  }

  &:disabled {
    color: ${(p) => p.theme.custom.grayTextColor};
  }
`;

const secondaryButtonStyles = css`
  border: 1px solid ${(p) => p.theme.custom.primaryColor};
  background-color: ${(p) => p.theme.custom.whiteMainColor};
  color: ${(p) => p.theme.custom.primaryColor};

  &:hover,
  &:focus {
    border-color: ${(p) => p.theme.custom.secondaryColor};
    color: ${(p) => p.theme.custom.secondaryColor};
  }

  &:active {
    border-color: ${(p) => p.theme.custom.new_orangeSecondaryColor};
    color: ${(p) => p.theme.custom.new_orangeSecondaryColor};
  }

  &:disabled {
    border-color: ${(p) => p.theme.custom.greyAlternativeColor};
    ${disabledButtonStyles};
  }
`;

const primaryButtonStyles = css`
  background-color: ${(p) => p.theme.custom.primaryColor};
  color: ${(p) => p.theme.custom.whiteMainColor};

  &:hover,
  &:focus {
    background: #0162CF;
    color: ${(p) => p.theme.custom.whiteMainColor};
  }

  &:active {
      background: #0162CF;
    color: ${(p) => p.theme.custom.whiteMainColor};
  }

  &:disabled {
    ${disabledButtonStyles};
  }
`;

const alternativeButtonStyles = css`
  background-color: ${(p) => p.theme.custom.primaryColor};
  color: ${(p) => p.theme.custom.whiteMainColor};

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.custom.secondaryColor};
  }

  &:active {
    background-color: ${(p) => p.theme.custom.new_orangeSecondaryColor};
  }

  &:disabled {
    ${disabledButtonStyles};
  }
`;

const facebookButtonStyles = css`
  background-color: ${(p) => p.theme.custom.faceBookBackgroundButtonColor};
  color: ${(p) => p.theme.custom.whiteMainColor};

  &:hover,
  &:focus {
    background-color: ${(p) =>
      rgba(p.theme.custom.faceBookBackgroundButtonColor, 0.9)};
  }
`;

const linkedInButtonStyles = css`
  background-color: ${(p) => p.theme.custom.linkedInBackgroundButtonColor};
  color: ${(p) => p.theme.custom.whiteMainColor};

  &:hover,
  &:focus {
    background-color: ${(p) =>
      rgba(p.theme.custom.linkedInBackgroundButtonColor, 0.9)};
  }
`;

const googleButtonStyles = css`
  background-color: ${(p) => p.theme.custom.googleBackgroundButtonColor};

  &:hover,
  &:focus {
    background-color: ${(p) =>
      rgba(p.theme.custom.googleBackgroundButtonColor, 0.9)};
  }
`;

const buttonStyles: Record<
  ButtonType,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  primary: primaryButtonStyles,
  secondary: secondaryButtonStyles,
  link: linkButtonStyles,
  alternative: alternativeButtonStyles,
  facebook: facebookButtonStyles,
  linkedIn: linkedInButtonStyles,
  google: googleButtonStyles,
};

const buttonSizeStyles: Record<Size, FlattenSimpleInterpolation> = {
  superLarge: superLargeButtonStyles,
  large: largeButtonStyles,
  medium: mediumButtonStyles,
};

const Button = styled.button<ButtonProps>`
  /* all: unset; */
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
  ${({ size = 'large' }) => buttonSizeStyles[size]};
  ${({ btnType }) => buttonStyles[btnType]};
  ${({ block }) => block && blockButtonStyles};
`;

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'alternative'
  | 'facebook'
  | 'linkedIn'
  | 'google';

type Size = 'superLarge' | 'large' | 'medium';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnType: ButtonType;
  block?: boolean;
  size?: Size;
}

export const CustomButton: FC<ButtonProps> = ({
  className,
  children,
  btnType,
  type = 'button',
  onClick,
  disabled,
  block,
  size = 'large',
  ...rest
}) => (
  <Button
    className={className}
    type={type}
    onClick={onClick}
    disabled={disabled}
    btnType={btnType}
    block={block}
    size={size}
    {...rest}
  >
    {children}
  </Button>
);
