import { VFC } from 'react';
import _isNil from 'lodash/isNil';
import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { HotelPolicy } from 'src/models/hotels';

import { StyledPanel } from '../common';

const CHILD_POLICIES = [
  {
    renderPolicyTitle: (data: HotelPolicy['data']) => (
      <I18n
        id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.INFANTS.TITLE"
        values={{ ageTo: data.childrenAgeFrom - 1 }}
      />
    ),
    renderPolicyDescription: () => (
      <I18n id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.INFANTS.DESCRIPTION" />
    ),
  },
  {
    renderPolicyTitle: (data: HotelPolicy['data']) => (
      <I18n
        id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.CHILDREN.TITLE"
        values={{ ageFrom: data.childrenAgeFrom, ageTo: data.childrenAgeTo }}
      />
    ),
    renderPolicyDescription: (data: HotelPolicy['data']) =>
      data.childrenStayFree ? (
        <I18n id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.CHILDREN.DESCRIPTION" />
      ) : (
        <I18n
          id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.CHILDREN.DESCRIPTION.WITH_EXTRA_BED"
          values={{ b: (...chunks: any) => <b>{chunks}</b> }}
        />
      ),
  },
  {
    renderPolicyTitle: (data: HotelPolicy['data']) => (
      <I18n
        id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.ADULTS.TITLE"
        values={{ ageFrom: data.childrenAgeTo + 1 }}
      />
    ),
    renderPolicyDescription: () => (
      <I18n id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.ADULTS.DESCRIPTION" />
    ),
  },
];

const PolicyWrapper = styled.div`
  margin-bottom: 26px;
`;

const PolicyTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.custom.blackMainColor};
`;

const PolicyContent = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${({ theme }) => theme.custom.blackSecondaryColor};
  margin: 0;
`;

interface ChildAndExtraBedPolicyProps {
  id?: string;
  hotelPolicies: HotelPolicy[];
}

export const ChildAndExtraBedPolicy: VFC<ChildAndExtraBedPolicyProps> = ({
  id,
  hotelPolicies,
}) => {
  if (_isNil(hotelPolicies[0]?.data)) return null;
  return (
    <StyledPanel
      title={<I18n id="HOTEL_DETAILS_PAGE.CHILD_AND_EXTRA_BED_POLICY.TITLE" />}
      id={id}
    >
      {CHILD_POLICIES.map(
        ({ renderPolicyTitle, renderPolicyDescription }, index) => (
          <PolicyWrapper key={index}>
            <PolicyTitle>
              {renderPolicyTitle(hotelPolicies[0].data)}
            </PolicyTitle>
            <PolicyContent>
              {renderPolicyDescription(hotelPolicies[0].data)}
            </PolicyContent>
          </PolicyWrapper>
        )
      )}
    </StyledPanel>
  );
};
