import useSWR from 'swr';

import { CustomerDetails } from 'src/models/customers/common';
import { apiCall } from 'src/modules/api';

const useCustomer = (disable?: boolean) =>
  useSWR(
    disable ? null : '/customers',
    async (url) => {
      const res = await apiCall<CustomerDetails>(url);

      return { ...res, favorites: new Set(res.favorites) };
    },
    { revalidateIfStale: true, revalidateOnFocus: true }
  );

export default useCustomer;
