import { FC } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { fontSizeSmall } from 'src/modules/fontSizes';
import { media } from 'src/modules/mediaQuery';
import { TabletDesktop } from 'src/components/shared/CResponsive';
import { I18n } from 'src/components/I18n';

const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 10px;
  background-color: ${(p) => rgba(p.theme.custom.blackMainColor, 0.8)};
  ${fontSizeSmall};
  font-weight: 500;
  color: ${(p) => p.theme.custom.whiteMainColor};
  cursor: pointer;

  .icon-no-image {
    margin-right: 8px;
  }

  span:nth-child(2) {
    margin-right: 2px;
  }

  ${media.phone`
    bottom: 10px;
    right: 10px;
  `}
`;

interface ImagesCountSmallProps {
  imagesCount: number;
}

export const ImagesCountSmall: FC<ImagesCountSmallProps> = ({
  imagesCount,
}) => (
  <Wrapper>
    <span className="icon-no-image" />
    <span>{imagesCount}+</span>
    <TabletDesktop>
      <I18n id="HOTEL_DETAILS_PAGE.ROOMS.IMAGES" />
    </TabletDesktop>
  </Wrapper>
);
