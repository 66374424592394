import { RefObject } from 'react';

export const handleOutsideClick =
  <
    Target extends HTMLElement = HTMLElement,
    Children extends HTMLElement = HTMLElement
  >(
    targetRef: RefObject<Target>,
    clickOutsideHandler: (event?: MouseEvent | TouchEvent) => void,
    additionalChildren: (Children | null)[] = []
  ) =>
  (event: any) => {
    const { current } = targetRef;
    const { target } = event;

    if (!current) {
      return;
    }

    const targetElements = [
      current,
      ...Array.from(current?.childNodes ?? []),
      ...additionalChildren,
    ];

    const isContains = (node: Node | null) => node && node.contains(target);

    const clickedOutside = !targetElements.some(isContains);

    if (clickedOutside) {
      return clickOutsideHandler();
    }
  };
