import { of, merge, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';

import { SubmitContactFormAction } from 'src/store/contactForm/actions';
import { observableApi$ } from 'src/modules/api';
import { ActionTypes } from 'src/constants';
import { toggleInfoPopUp } from 'src/store/popUp/actions';
import { InfoPopUpType } from 'src/store/popUp/reducers';
import { ContactRequest, ContactResponse } from 'src/models/contact';
import { mapErrorResponse, mapResponse } from 'src/store/epicHelpers';

const GeneralAPI = {
  submitContactForm$: (params: ContactRequest) =>
    observableApi$<ContactResponse>('/contact', {
      method: 'post',
      data: params,
    }),
};

export function sendContactForm(action$: Observable<SubmitContactFormAction>) {
  return action$.pipe(
    ofType(ActionTypes.SUBMIT_CONTACT_FORM_REQUEST),
    mergeMap(({ params }) =>
      GeneralAPI.submitContactForm$(params).pipe(
        mergeMap((resp) =>
          merge(
            mapResponse(ActionTypes.SUBMIT_CONTACT_FORM_SUCCESS, resp),
            of(toggleInfoPopUp(InfoPopUpType.CONTACT_US_SUCCESS))
          )
        ),
        catchError((err) =>
          mapErrorResponse(ActionTypes.SUBMIT_CONTACT_FORM_FAILURE, err)
        )
      )
    )
  );
}
