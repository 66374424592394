import addMonths from 'date-fns/addMonths';
import getWeeksInMonth from 'date-fns/getWeeksInMonth';

import { DAY_SIZE } from '../Day/styled';
import { PHONE_HEADER_HEIGHT, WEEKDAY_HEIGHT } from '../Month/styled';

import { GUTTER_SIZE } from '.';

export const handleMonthHeight = (from: Date, locale: Locale) => {
  const staticHeight = PHONE_HEADER_HEIGHT + WEEKDAY_HEIGHT + GUTTER_SIZE;

  return (index: number) =>
    staticHeight +
    getWeeksInMonth(addMonths(from, index), { locale }) * DAY_SIZE;
};
