import { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import 'styled-components/macro';

import CModal, { CModalProps } from 'src/components/common/CModal';

const StyledHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

type Props = Omit<CModalProps, 'title'> & { title: ReactNode };

export const CurrencyLocaleModal: FC<Props> = ({
  title,
  children,
  ...props
}) => (
  <CModal {...props} withCloseButton>
    <div
      css={`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <StyledHeader variant="h4">{title}</StyledHeader>
      {children}
    </div>
  </CModal>
);
