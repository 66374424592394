import { ComponentProps, ReactNode } from 'react';

import { ListItem } from '../../ListItem';

export const generateVariants = <T extends {}>(
  title: ReactNode,
  key: string,
  items: T[] | undefined,
  renderVariant: (item: T | undefined, index: number) => JSX.Element
): ComponentProps<typeof ListItem> & { key: string } => ({
  title,
  children: (items || Array.from<undefined>({ length: 5 })).map(renderVariant),
  key,
});
