import {
  css,
  CSSObject,
  SimpleInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components/macro';

import { breakPoints } from '../configs';

interface SizesType {
  [key: string]: (number | null)[];
}

type CSSFunc = (
  first: TemplateStringsArray | CSSObject,
  ...args: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

interface MediaAccType {
  [key: string]: CSSFunc;
}

interface MediaType extends MediaAccType {
  superLarge: CSSFunc;
  notSuperLarge: CSSFunc;
  large: CSSFunc;
  smallDesktop: CSSFunc;
  desktop: CSSFunc;
  tablet: CSSFunc;
  phone: CSSFunc;
  phoneTablet: CSSFunc;
  tabletDesktop: CSSFunc;
  extraSmall: CSSFunc;
  notExtraSmall: CSSFunc;
  xxsSmall: CSSFunc;
}

const SIZES: SizesType = {
  superLarge: [breakPoints.xxl, null],
  notSuperLarge: [null, breakPoints.xxl - 1],
  large: [breakPoints.xl, null],
  smallDesktop: [breakPoints.lg, breakPoints.xl - 1],
  desktop: [breakPoints.lg, null],
  tablet: [breakPoints.md, breakPoints.lg - 1],
  tabletDesktop: [breakPoints.md, null],
  phone: [null, breakPoints.md - 1],
  phoneTablet: [null, breakPoints.lg - 1],
  extraSmall: [null, breakPoints.sm - 1],
  notExtraSmall: [breakPoints.sm, null],
  xxsSmall: [null, breakPoints.xxs],
};

const RANGES: string[] = ['min', 'max'];

// Iterate through the sizes and create a media template
export const media = Object.keys(SIZES).reduce(
  (acc: MediaAccType, label: string) => {
    const widths: string = RANGES.reduce(
      (accum: string[], range: string, index: number) => {
        if (SIZES[label][index]) {
          accum.push(`(${range}-width: ${SIZES[label][index]}px)`);
        }

        return accum;
      },
      []
    ).join(' and ');

    acc[label] = (
      first: TemplateStringsArray | CSSObject,
      ...args: SimpleInterpolation[]
    ) => css`
      @media ${widths} {
        ${css(first, ...args)}
      }
    `;

    return acc;
  },
  {}
) as MediaType;
