import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import { XOR } from 'src/types';

import { I18n } from '../components/I18n';

export type TextProps = XOR<
  [{ textI18nKey: DefaultAppMessagesTypeKey }, { text: string }]
>;

const getText = (props: TextProps) =>
  props.textI18nKey ? <I18n id={props.textI18nKey} /> : props.text;

export default getText;
