import { ParseOptions, StringifyOptions } from 'query-string';

const clientBaseOptions: Pick<
  StringifyOptions,
  'arrayFormat' | 'arrayFormatSeparator' | 'sort'
> = {
  arrayFormat: 'comma',
  sort: false,
};

export const stringifyClientOptions: StringifyOptions = {
  ...clientBaseOptions,
  skipEmptyString: true,
};

export const stringifyServerOptions: StringifyOptions = {
  arrayFormat: 'comma',
  sort: false,
  skipEmptyString: true,
  skipNull: true,
};

export const parseClientOptions: ParseOptions = {
  ...clientBaseOptions,
  parseBooleans: true,
  parseNumbers: true,
};
