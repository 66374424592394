import Button from '@mui/material/Button';
import { darken } from 'polished';
import styled from 'styled-components/macro';
import CloseIcon from '@mui/icons-material/Close';
import { VFC } from 'react';

import { POINTER_FINE } from 'src/configs/muiTheme';

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  &&& {
    box-shadow: ${({ theme }) => theme.customShadows.text};
  }
  padding: 4px;
  font-size: 24px;
  background: ${({ theme }) => theme.palette.background.default};
  ${POINTER_FINE} {
    &:hover {
      background: ${({ theme }) =>
        darken(0.1, theme.palette.background.default)};
    }
  }
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.buttonSecondary.light};
  > svg {
    font-size: 1em;
  }
`;

type Props = { onClick: () => void };

const CloseButton: VFC<Props> = ({ onClick }) => (
  <StyledButton
    variant="contained"
    size="large"
    color="buttonSecondary"
    onClick={onClick}
  >
    <CloseIcon />
  </StyledButton>
);

export default CloseButton;
