import { VFC, FC, useEffect, useRef } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import Select from '@mui/material/Select';
import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { SearchParamsState } from 'src/store/search/reducers';
import { NumberStepper } from 'src/components/common/NumberStepper';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

import { FieldsName } from '../../..';

import { RoomChooserFormItem } from './RoomChooserFormItem';

import { FormSectionProps, MAX_CHILDREN, MAX_GUESTS } from '.';

type Props = FormSectionProps & {
  labelId: DefaultAppMessagesTypeKey;
  ageLabelId: DefaultAppMessagesTypeKey;
};

const StyledSelectWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
`;

const SelectWrapper: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (el && el.parentElement) {
      el.parentElement.style.height = `${el.clientHeight}px`;
    }
  }, []);

  return <StyledSelectWrapper ref={ref}> {children}</StyledSelectWrapper>;
};

export const Children: VFC<Props> = ({ index, width, labelId, ageLabelId }) => {
  const { fields, append, remove } = useFieldArray<
    SearchParamsState,
    `${FieldsName.ROOMS}.${number}.children`
  >({
    name: `${FieldsName.ROOMS}.${index}.children`,
  });

  const adults = useWatch<
    SearchParamsState,
    `${FieldsName.ROOMS}.${number}.adults`
  >({ name: `${FieldsName.ROOMS}.${index}.adults` });

  const handleChange = (value: number) => {
    const diff = value - fields.length;

    if (diff > 0) {
      append(Array.from({ length: diff }, () => ({ age: 0 })));
    } else if (diff < 0) {
      const lastIndex = fields.length - 1;

      remove(Array.from({ length: -diff }, (_, i) => lastIndex - i));
    }
  };

  return (
    <>
      <RoomChooserFormItem label={<I18n id={labelId} />} width={width}>
        <NumberStepper
          min={0}
          max={Math.min(MAX_GUESTS - adults, MAX_CHILDREN)}
          value={fields.length}
          onChange={handleChange}
        />
      </RoomChooserFormItem>
      {fields.map((item, j) => (
        <RoomChooserFormItem
          label={<I18n id={ageLabelId} values={{ number: j + 1 }} />}
          key={item.id}
          width={width}
        >
          <SelectWrapper>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                  fullWidth
                  variant="outlined"
                  label={false}
                  native
                >
                  <I18n id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.CHILD_LESS_THAN_YEAR">
                    {(message) => <option value={0}>{message}</option>}
                  </I18n>
                  {Array.from({ length: 17 }, (_, i) => {
                    const age = i + 1;

                    return (
                      <option key={age} value={age}>
                        {age}
                      </option>
                    );
                  })}
                </Select>
              )}
              name={`${FieldsName.ROOMS}.${index}.children.${j}.age`}
            />
          </SelectWrapper>
        </RoomChooserFormItem>
      ))}
    </>
  );
};
