import { VFC } from 'react';

const MinusIcon: VFC = () => (
  <svg
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2H0V0H12V2Z"
      fill="currentColor"
    />
  </svg>
);

export default MinusIcon;
