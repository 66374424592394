import immutable from 'immutability-helper';

import { createReducer } from 'src/modules/helpers';
import { DEFAULT_LOCALE } from 'src/configs';
import { ActionSuccess } from 'src/models/actions';
import { ActionTypes, LoadingTypes } from 'src/constants';
import { UpdateLocaleResponse } from 'src/store/intl/epics';
import { setGlobalLocale } from 'src/modules/setGlobalLocale';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

export interface IntlState {
  status: LoadingTypes;
  currentLocale: string;
  messages: Record<string, Record<DefaultAppMessagesTypeKey, string>>;
}

const localeState: IntlState = {
  status: LoadingTypes.IDLE,
  currentLocale: '',
  messages: {},
};

export const intlReducer = createReducer(localeState, {
  [ActionTypes.UPDATE_LOCALE_REQUEST](state: IntlState) {
    return immutable(state, {
      status: { $set: LoadingTypes.RUNNING },
    });
  },
  [ActionTypes.UPDATE_LOCALE_SUCCESS](
    state: IntlState,
    action: ActionSuccess<
      ActionTypes.UPDATE_LOCALE_SUCCESS,
      UpdateLocaleResponse
    >
  ) {
    const { payload } = action;
    setGlobalLocale(payload.locale);
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      messages: {
        $merge: {
          [payload.locale]: payload.messages,
        },
      },
      currentLocale: { $set: payload.locale },
    });
  },
  [ActionTypes.UPDATE_LOCALE_FAILURE](state: IntlState) {
    setGlobalLocale(DEFAULT_LOCALE);
    return immutable(state, {
      status: { $set: LoadingTypes.IDLE },
      messages: {
        $merge: {
          [DEFAULT_LOCALE]: {} as any,
        },
      },
      currentLocale: { $set: DEFAULT_LOCALE },
    });
  },
});
