import { useLayoutEffect, useMemo } from 'react';

const usePortal = () => {
  const rootElem = useMemo(() => document.createElement('div'), []);

  useLayoutEffect(() => {
    document.body.appendChild(rootElem);

    return () => {
      document.body.removeChild(rootElem);
    };
  }, []);

  return rootElem;
};

export default usePortal;
