import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import useMatchMedia from 'src/hooks/useMatchMedia';

import { SignInSection } from './SignInSection';
import { SignUpSection } from './SignUpSection';
import { ResetPasswordSection } from './ResetPasswordSection';
import Banner from './Banner';

export enum EAuth {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  RESET_PASSWORD = 'forgot-password',
}

export const AUTH_TYPE_PARAM = 'authType';

export const authPath = `/:${AUTH_TYPE_PARAM}(${Object.values(EAuth).join(
  '|'
)})`;

export type AuthBlockProps = {
  cvexCampaign: boolean;
  nordicCampaign: boolean;
  onSubmit?: () => void;
  setActiveModal?: (modal: EAuth) => void;
};

const getModal = (activeModal: EAuth, props: AuthBlockProps) => {
  switch (activeModal) {
    case EAuth.SIGN_IN:
      return <SignInSection {...props} />;
    case EAuth.SIGN_UP:
      return <SignUpSection {...props} />;
    case EAuth.RESET_PASSWORD:
      return <ResetPasswordSection {...props} />;
  }
};

type Props = Omit<AuthBlockProps, 'hideHeader' | 'isDesktop'> & {
  activeModal: EAuth;
  alwaysShowHeader?: boolean;
};

const notMobileMixin = css`
  display: grid;
  height: 100%;
  flex-grow: 1;
`;

const desktopMixin = css`
  grid-template-columns: auto clamp(600px, calc(600px + (100vw - 1200px) / 2), 960px);
`;

const Container = styled.div<{ isDesktop: boolean; isMobile: boolean; }>`
  ${({ isMobile }) => !isMobile && notMobileMixin}

  ${({ isDesktop }) => isDesktop && desktopMixin}

  width: 100%;
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
`;

export const Auth: FC<Props> = ({
  activeModal,
  alwaysShowHeader,
  cvexCampaign,
  nordicCampaign,
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useMatchMedia(theme.breakpoints.up('lg'));

  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return (
    <Container isDesktop={isDesktop} isMobile={isMobile}>
      {isDesktop && <Banner />}
      {getModal(activeModal, {
        ...props,
        cvexCampaign,
        nordicCampaign
      })}
    </Container>
  );
};
