import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { ReactNode, VFC } from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import Divider from '@mui/material/Divider';

import { I18n } from 'src/components/I18n';
import { modalDividerMixin } from 'src/components/common/CModal';
import { DateRange, EDateSelection } from 'src/components/Calendar';
import { useCalendarLocale } from 'src/components/Calendar/CalendarProvider';

import { NightsCount } from './NightsCount';

type Props = {
  dateSelection: EDateSelection;
  dates: DateRange;
  setDateSelection: (v: EDateSelection) => void;
};

const StyledWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => rgba(theme.palette.text.disabled, 0.2)};
  border-radius: 20px;
`;

type StyledGridContainerProps = { $dateSelection: Props['dateSelection'] };

const StyledGridContainer = styled(Grid)<StyledGridContainerProps>`
  position: relative;
  &::before {
    content: '';
    z-index: 0;
    background: ${({ theme }) => theme.palette.background.default};
    border-radius: 18px;
    position: absolute;
    top: 0;
    transition: ${({ theme }) => theme.transitions.create('left')};
    left: ${({ $dateSelection }) =>
      $dateSelection === EDateSelection.START ? '0%' : '50%'};
    width: 50%;
    height: 100%;
  }
`;

const StyledStack = styled(Stack)`
  position: sticky;
  margin-top: calc(-1 * var(--padding));
  top: calc(-1.5 * var(--padding));
  padding-top: var(--padding);
  z-index: 1;
  background: ${({ theme }) => theme.palette.background.default};
  ${modalDividerMixin}
`;

const StyledGridItem = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(2)};
  z-index: 1;
  position: relative;
  cursor: pointer;
`;

type ItemProps = {
  date: Date | null;
  label: ReactNode;
  placeholder: ReactNode;
  type: EDateSelection;
} & Omit<Props, 'dates'>;

const Item: VFC<ItemProps> = ({
  dateSelection,
  setDateSelection,
  date,
  label,
  placeholder,
  type,
}) => {
  const locale = useCalendarLocale();

  return (
    <StyledGridItem
      item
      container
      alignContent="center"
      justifyContent="center"
      xs={8}
      onClick={() => setDateSelection(type)}
    >
      <Stack spacing={0.5} alignItems="center">
        <Typography
          variant="overline"
          color={
            dateSelection === type ? 'focusSecondary.main' : 'text.secondary'
          }
        >
          {label}
        </Typography>
        <Typography variant="body3">
          {date ? format(date, 'EEE, dd MMM', { locale }) : placeholder}
        </Typography>
      </Stack>
    </StyledGridItem>
  );
};

export const MobileDatesToggler: VFC<Props> = ({
  dateSelection,
  dates,
  setDateSelection,
}) => (
  <StyledStack spacing={3}>
    <StyledWrapper>
      <StyledGridContainer container $dateSelection={dateSelection}>
        <Item
          dateSelection={dateSelection}
          setDateSelection={setDateSelection}
          date={dates[0]}
          type={EDateSelection.START}
          label={<I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKIN" />}
          placeholder={<I18n id="CALENDAR.START_DATE" />}
        />
        <Item
          dateSelection={dateSelection}
          setDateSelection={setDateSelection}
          date={dates[1]}
          type={EDateSelection.END}
          label={<I18n id="CHECKOUT_PAGE.HOTEL_INFO_BLOCK.CHECKOUT" />}
          placeholder={<I18n id="CALENDAR.END_DATE" />}
        />
      </StyledGridContainer>
    </StyledWrapper>
    <NightsCount dates={dates} />
    <Divider />
  </StyledStack>
);
