import { MutableRefObject, Ref, RefCallback, useCallback } from 'react';

const useMergedRef = <T>(...refs: Ref<T>[]): RefCallback<T> =>
  useCallback(
    (value) =>
      refs.forEach((ref) => {
        if (!ref) return;

        if (typeof ref === 'function') {
          ref(value);
        } else {
          (ref as MutableRefObject<T | null>).current = value;
        }
      }),
    refs
  );

export default useMergedRef;
