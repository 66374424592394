import { MouseEventHandler } from 'react';

export const handleModal = <T>(
  setActiveModal: ((v: T) => void) | undefined,
  type: T
): MouseEventHandler<HTMLAnchorElement> | undefined => {
  if (!setActiveModal) return undefined;

  return (e) => {
    e.preventDefault();
    setActiveModal(type);
  };
};
