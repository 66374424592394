import noop from 'lodash/noop';
import {
  createContext,
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import CModal from '../common/CModal';

import { Auth, EAuth } from '.';

const StyledModal = styled(CModal)`
  border-radius: 0;
  width: 100vw;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  padding: 0;
  display: flex;
  position: relative;
`;

const AuthModalContext = createContext<(modal: EAuth) => void>(noop);

export const useOpenAuthModal = () => useContext(AuthModalContext);

const OnCloseContext = createContext<MutableRefObject<() => void>>(
  undefined as any
);

export const useSetOnAuthModalClose = (onClose: () => void) => {
  const onCloseRef = useContext(OnCloseContext);

  useEffect(() => {
    onCloseRef.current = onClose;

    return () => {
      onCloseRef.current = noop;
    };
  }, [onClose]);
};

const AuthModalProvider: FC = ({ children }) => {
  const onCloseRef = useRef<() => void>(noop);
  const urlParams = new URLSearchParams(window.location.search);
  const cvexCampaign = urlParams.get('cvexCampaign') === 'true';
  const nordicCampaign = urlParams.get('nordicCampaign') === 'true';

  const [modal, setModal] = useState(EAuth.SIGN_IN);

  const [toggler, setToggler] = useState(false);

  const onOpen = useCallback<typeof setModal>((modal) => {
    setModal(modal);

    setToggler(true);
  }, []);

  const handleClose = () => {
    setToggler(false);

    onCloseRef.current();
  };

  const content = (
    <Auth
      cvexCampaign={cvexCampaign}
      nordicCampaign={nordicCampaign}
      onSubmit={handleClose}
      activeModal={modal}
      setActiveModal={setModal}
    />
  );

  return (
    <OnCloseContext.Provider value={onCloseRef}>
      <AuthModalContext.Provider value={onOpen}>
        {children}
        <StyledModal open={toggler} onClose={handleClose} withCloseButton={!cvexCampaign && !nordicCampaign}>
          {content}
        </StyledModal>
      </AuthModalContext.Provider>
    </OnCloseContext.Provider>
  );
};

export default AuthModalProvider;
