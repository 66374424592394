import { VFC } from 'react';

export const CurrencyIcon: VFC = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5081 3.82157C4.50024 4.05245 4.57604 4.27853 4.72184 4.45912C4.92332 4.65567 5.17735 4.79136 5.45412 4.85026V2.80461C5.18476 2.8339 4.93487 2.95736 4.74955 3.15272C4.58606 3.3373 4.49973 3.57641 4.5081 3.82157Z"
      fill="currentcolor"
    />
    <path
      d="M5.89795 5.68743V7.95993C6.18266 7.92716 6.44357 7.78704 6.62627 7.5688C6.81614 7.35172 6.91894 7.07342 6.91523 6.78652C6.93401 6.55262 6.86808 6.31973 6.72919 6.12941C6.49785 5.91026 6.2105 5.75747 5.89795 5.68743Z"
      fill="currentcolor"
    />
    <path
      d="M11.2494 6.35604C11.3655 5.56477 11.3076 4.75806 11.0794 3.99095C10.8513 3.22384 10.4584 2.51439 9.92744 1.91104C9.39651 1.30769 8.7401 0.824632 8.003 0.494839C7.26591 0.165045 6.46548 -0.00371991 5.65635 6.21908e-05C4.1562 6.21908e-05 2.71748 0.588938 1.65671 1.63714C0.595935 2.68535 0 4.10702 0 5.58941C0 7.0718 0.595935 8.49347 1.65671 9.54168C2.71748 10.5899 4.1562 11.1788 5.65635 11.1788C6.11848 11.1762 6.5785 11.1171 7.02591 11.0027C7.27161 9.9371 7.78845 8.95104 8.52753 8.13791C9.2666 7.32477 10.2034 6.71146 11.2494 6.35604ZM7.19216 8.02619C6.85201 8.37318 6.38661 8.57289 5.89781 8.58161V9.33259H5.45448V8.60508C5.13628 8.57343 4.82579 8.48879 4.53617 8.35475C4.28249 8.21834 4.07058 8.01705 3.92264 7.77195C3.74194 7.47423 3.6386 7.13696 3.62181 6.7902L4.38179 6.64939C4.40975 6.9634 4.51581 7.26572 4.69054 7.52945C4.77993 7.65176 4.89393 7.7545 5.02539 7.83123C5.15685 7.90796 5.30293 7.95703 5.45448 7.97535V5.5855C5.1205 5.51815 4.79971 5.39796 4.5045 5.22956C4.27537 5.10483 4.08615 4.91921 3.95826 4.69371C3.83 4.45249 3.76341 4.18392 3.76431 3.91143C3.75569 3.66787 3.80242 3.4255 3.90106 3.20214C3.9997 2.97877 4.14774 2.78008 4.3343 2.62068C4.66248 2.37933 5.05748 2.24285 5.46636 2.22954V1.82667H5.89781V2.18261C6.27847 2.20409 6.64325 2.34063 6.94279 2.57374C7.27201 2.85579 7.47891 3.25209 7.52069 3.68066L6.72904 3.79409C6.71165 3.55351 6.62514 3.32284 6.47967 3.12916C6.32481 2.96647 6.12057 2.85801 5.89781 2.82016V4.97141C6.15416 5.02891 6.40665 5.10206 6.65384 5.19045C6.86664 5.27839 7.06121 5.40436 7.22778 5.56203C7.37652 5.71175 7.49241 5.89017 7.56819 6.08615C7.65023 6.29435 7.69185 6.51594 7.6909 6.73935C7.70275 7.21648 7.52352 7.67895 7.19216 8.02619Z"
      fill="currentcolor"
    />
    <path
      d="M13.3436 6.8213C12.2249 6.8213 11.1313 7.14911 10.2011 7.76328C9.27096 8.37744 8.54597 9.25038 8.11785 10.2717C7.68974 11.293 7.57772 12.4168 7.79597 13.5011C8.01423 14.5853 8.55294 15.5812 9.344 16.3629C10.1351 17.1446 11.1429 17.6769 12.2401 17.8926C13.3374 18.1083 14.4747 17.9976 15.5082 17.5745C16.5418 17.1515 17.4252 16.4351 18.0467 15.5159C18.6683 14.5968 19 13.5161 19 12.4106C18.999 10.9286 18.4027 9.50751 17.3421 8.45953C16.2816 7.41155 14.8435 6.82234 13.3436 6.8213ZM15.5563 10.3767C15.2315 10.1042 14.8187 9.9557 14.3926 9.95822C14.053 9.93805 13.7144 10.0115 13.4146 10.1703C13.1147 10.3292 12.8654 10.5672 12.6945 10.8578C12.5528 11.0744 12.4597 11.3185 12.4214 11.5736H15.2792L15.1644 12.1251H12.3541C12.3541 12.1838 12.3541 12.2385 12.3541 12.2933C12.3541 12.5045 12.3541 12.6297 12.3541 12.6844H15.0417L14.923 13.2399H12.4293C12.4608 13.5121 12.5532 13.7741 12.6997 14.0068C12.8462 14.2395 13.0431 14.4371 13.2764 14.5854C13.5941 14.7735 13.9589 14.8697 14.3293 14.8631C14.7742 14.8951 15.2142 14.7548 15.5563 14.4719V15.3285C15.1916 15.4898 14.796 15.5711 14.3965 15.5671C13.1339 15.5671 12.2828 15.0469 11.8355 14.0026C11.7351 13.7493 11.6583 13.4874 11.606 13.2203H10.9647L11.0835 12.6649H11.5228C11.5228 12.5475 11.5228 12.4172 11.5228 12.2738C11.5228 12.219 11.5228 12.1603 11.5228 12.1017H10.9647L11.0835 11.5462H11.5703C11.627 11.1414 11.779 10.7553 12.0141 10.419C12.2492 10.0828 12.5609 9.80571 12.9241 9.61011C13.3804 9.36433 13.8927 9.23777 14.4124 9.24244C14.8727 9.22794 15.3276 9.34472 15.7226 9.57882L15.5563 10.3767Z"
      fill="currentcolor"
    />
  </svg>
);
