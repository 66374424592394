import styled, { useTheme } from 'styled-components/macro';
import { useRef } from 'react';

import { I18n } from 'src/components/I18n';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { useOpenAuthModal } from 'src/components/Auth/AuthModalProvider';
import { EAuth } from 'src/components/Auth';

import { StyledButton, StyledContainer } from './styles';

interface LogoIconProps {
  isContrast?: boolean;
}

export const SignIn = ({ isContrast = false }: LogoIconProps) => {
  const color = isContrast ? '#000000' : '#FFF';
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const handleOpenModal = useOpenAuthModal();
  const openSignIn = () => {
    handleOpenModal(EAuth.SIGN_IN);
  };

  const Text = styled.div`
  color: ${color};
`;
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={anchorRef}>
      <StyledContainer>
        <StyledButton
          color="buttonSecondary"
          onClick={openSignIn}
          className="styled-button"
        >
          <Text>
            <I18n id="HEADER.SIGN_IN_BTN" />
          </Text>
        </StyledButton>
      </StyledContainer>
    </div>
  );
};
