import update from 'immutability-helper';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

import { createReducer } from 'src/modules/helpers';
import { ActionTypes } from 'src/constants';
import { ConfigResponse, HotelFacility } from 'src/models/config';
import { Nullable, XOR } from 'src/types';
import { ActionFailure, Error, ActionSuccess } from 'src/models/actions';

interface Country {
  value: string;
  text: string;
}

type Config = Omit<
  ConfigResponse,
  'countries' | 'defaultSettings' | 'currencies'
>;
export type SettingsState = Config & {
  currencies: XOR<[ConfigResponse['currencies'], {}]>;
  countries: Country[];
  filterableFacilities: HotelFacility[];
  fetched: boolean;
  error?: Nullable<Error>;
};

const initialState: SettingsState = {
  countries: [],
  currencies: {},
  locales: {},
  suitableTypes: [],
  hotelScoreCategoryOrder: [],
  hotFilters: [],
  facilities: [],
  hotelFacilityGroups: [],
  filterableFacilities: [],
  fetched: false,
};

export const settings = createReducer(initialState, {
  [ActionTypes.FETCH_SETTINGS_SUCCESS]: (
    state: SettingsState,
    {
      payload,
    }: ActionSuccess<ActionTypes.FETCH_SETTINGS_SUCCESS, ConfigResponse>
  ) => {
    const { countries, facilities } = payload;
    const countriesCode = Object.keys(countries);
    const mapCountryCode = (countryCode: string) => ({
      value: countryCode.toLowerCase(),
      text: countries[countryCode],
    });
    const mappedCountries = _map(countriesCode, mapCountryCode);
    const sortedCountries = _sortBy(mappedCountries, ['text']);
    const filterableFacilities = _filter<HotelFacility>(
      facilities,
      'filterable'
    );

    return update(state, {
      countries: { $set: sortedCountries },
      currencies: { $set: payload.currencies },
      locales: { $set: payload.locales },
      suitableTypes: { $set: payload.suitableTypes },
      hotelScoreCategoryOrder: { $set: payload.hotelScoreCategoryOrder },
      hotFilters: { $set: payload.hotFilters },
      filterableFacilities: { $set: filterableFacilities },
      facilities: { $set: payload.facilities },
      hotelFacilityGroups: { $set: payload.hotelFacilityGroups },
      fetched: { $set: true },
      error: { $set: null },
    });
  },

  [ActionTypes.FETCH_SETTINGS_FAILURE]: (
    state: SettingsState,
    action: ActionFailure<ActionTypes.FETCH_SETTINGS_FAILURE, Error>
  ) => {
    const { error } = action;

    return update(state, {
      error: { $set: error },
    });
  },
});
