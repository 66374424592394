import { useState, useEffect } from 'react';

/**
 *
 * @param mediaQuery media query string
 * @param ssrDefaultValue As `window.matchMedia()` is unavailable on the server, we return a default matches during the first mount. The default value is false.
 */
const useMatchMedia = (mediaQuery: string, ssrDefaultValue = false) => {
  const [isMatch, setIsMatch] = useState(() =>
    typeof window !== 'undefined'
      ? matchMedia(mediaQuery.replace(/^@media( ?)/m, '')).matches
      : ssrDefaultValue
  );

  useEffect(() => {
    const mediaMatch = matchMedia(mediaQuery.replace(/^@media( ?)/m, ''));

    const listener = ({ matches }: MediaQueryListEvent) => setIsMatch(matches);

    let isModernBrowser = true;

    try {
      mediaMatch.addEventListener('change', listener);
    } catch (err) {
      isModernBrowser = false;
      mediaMatch.addListener(listener);
    }

    return () =>
      isModernBrowser
        ? mediaMatch.removeEventListener('change', listener)
        : mediaMatch.removeListener(listener);
  }, [mediaQuery]);

  return isMatch;
};

export default useMatchMedia;
