import { List } from "@mui/material";
import styled from "styled-components";

export const StyledList = styled(List)(() => `
  min-width: 320px;
`);

export const ListItemHeader = styled.div(() => `
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`);
