import { createSelector } from 'reselect';
import _reduce from 'lodash/reduce';
import _values from 'lodash/values';

import { ReduxState } from 'src/store/reducers';
import {
  hotelFacilitiesMapSelector,
  hotelFacilityGroupsMapSelector,
} from 'src/store/settings/selectors';

export const hotelSelector = (state: ReduxState) => state.hotel;

interface FacilityGroup {
  id: number;
  name: string;
  facilities: {
    id: number;
    name: string;
  }[];
}

interface FacilityGroupsMap {
  [key: string]: FacilityGroup;
}

const cheapestPackageSelector = createSelector(hotelSelector, ({ rooms }) => {
  const cheapest = rooms.find((room) =>
    room.groups.some((groupItem) => groupItem.isCheapest)
  );

  return cheapest?.groups[0];
});

export const cheapestPackagePriceSelector = createSelector(
  cheapestPackageSelector,
  (cheapestPackage) => cheapestPackage?.price.price
);

export type HotelInfoSelectorResult = ReturnType<typeof hotelInfoSelector>;

export const hotelInfoSelector = createSelector(
  (state: ReduxState) => state.hotel.info,
  hotelFacilitiesMapSelector,
  hotelFacilityGroupsMapSelector,
  cheapestPackageSelector,
  (hotelInfo, hotelFacilitiesMap, hotelFacilityGroupsMap, cheapestPackage) =>
    hotelInfo
      ? {
          ...cheapestPackage?.price,
          ...hotelInfo,
          facilityGroups: _values(
            _reduce<number, FacilityGroupsMap>(
              hotelInfo.hotelFacilities,
              (facilityGroupsMap, hotelFacility) => {
                const facility = hotelFacilitiesMap[hotelFacility];
                return {
                  ...facilityGroupsMap,
                  [facility.groupId]: {
                    ...hotelFacilityGroupsMap[facility.groupId],
                    facilities: [
                      ...(facilityGroupsMap[facility.groupId]
                        ? facilityGroupsMap[facility.groupId].facilities
                        : []),
                      {
                        name: facility.name,
                        id: facility.id,
                      },
                    ],
                  },
                };
              },
              {}
            )
          ),
        }
      : undefined
);
