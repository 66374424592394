import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { EllipsisTypography } from 'src/components/common/EllipsisTypography';
import { CRate } from 'src/components/shared/CRate';
import { HotelSuggestion } from 'src/models/search';

const AutocompleteVariant = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

type VariantProps<T> = {
  item?: T;
};

export const GooglePredictionVariant = forwardRef<
  HTMLDivElement,
  VariantProps<google.maps.places.AutocompletePrediction>
>(({ item, ...rest }, ref) => (
  <AutocompleteVariant {...rest} ref={ref}>
    <EllipsisTypography variant="label1">
      {item ? item.description : <Skeleton variant="text" />}
    </EllipsisTypography>
  </AutocompleteVariant>
));

export const ClicktripPredictionVariant = forwardRef<
  HTMLDivElement,
  VariantProps<HotelSuggestion>
>(({ item, ...rest }, ref) => {
  let text;
  let address;
  let stars;

  if (item) {
    text = item.name;
    address = `${item.cityName}, ${item.countryName}`;
    stars = <CRate value={item.stars} />;
  } else {
    text = <Skeleton variant="text" />;
    address = <Skeleton variant="text" width="30%" />;
  }

  return (
    <AutocompleteVariant {...rest} ref={ref}>
      <Stack direction="row" spacing={3} alignItems="center">
        <EllipsisTypography variant="label1">{text}</EllipsisTypography>
        {stars}
      </Stack>
      <EllipsisTypography variant="body2">{address}</EllipsisTypography>
    </AutocompleteVariant>
  );
});
