import { StateAction } from 'src/models/actions';
import { PopUpActionTypes } from 'src/constants';

import { CheckoutBookActionParams } from '../checkout/actions';

import {
  InfoPopUpType,
  PopUp,
  CheckoutPopUpEnum,
  CheckoutPopUpStateType,
} from './reducers';

export type InfoPopUpAction = StateAction<
  PopUpActionTypes.CHANGE_INFO_POP_UP_VIEW,
  PopUp<InfoPopUpType>
>;

export function toggleInfoPopUp(
  popUpName: InfoPopUpType,
  popUpId?: string | number
): InfoPopUpAction {
  return {
    type: PopUpActionTypes.CHANGE_INFO_POP_UP_VIEW,
    popUpName,
    popUpId,
  };
}

export type CheckoutPopUpAction = StateAction<
  PopUpActionTypes.CHANGE_CHECKOUT_POP_UP,
  CheckoutPopUpStateType
>;

export function toggleCheckoutPopUp(
  popUpName: CheckoutPopUpEnum,
  bookParams?: CheckoutBookActionParams
): CheckoutPopUpAction {
  return {
    type: PopUpActionTypes.CHANGE_CHECKOUT_POP_UP,
    popUpName,
    bookParams,
  };
}
