import { FC, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ImageWithLazyLoad } from 'src/components/common/ImageWithLazyLoad';
import { Image } from 'src/types';
import { NoImagePlaceholder } from 'src/components/HotelPreview/NoImagePlaceholder';

// Tutorial of how to handle aspect ratio.
// https://css-tricks.com/aspect-ratio-boxes/

type WrapperProps = { withDashedBorder: boolean };

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: ${({ theme }) => theme.custom.noimageBackgroundColor};

  ${(p) =>
    p.withDashedBorder &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px dashed ${p.theme.custom.greyAlternativeColor};
      background: transparent;
    }
  `}
`;

const LazyImage = styled(ImageWithLazyLoad)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledImage = styled(LazyImage)`
  position: absolute;
  top: 0;
  left: 0;
`;

interface ImageComponentProps {
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  id?: string;
  trackingId?: string;
  image: Image;
  placeholder?: ReactNode;
}

export const ImageComponent: FC<ImageComponentProps> = ({
  className,
  onClick,
  id,
  trackingId,
  image,
  placeholder = <NoImagePlaceholder />,
  children,
}) => (
  <Wrapper
    className={className}
    onClick={onClick}
    data-id={id}
    data-test-id={trackingId}
    withDashedBorder={!image.url}
  >
    {image && image.url ? <StyledImage url={image.url} /> : placeholder}
    {children}
  </Wrapper>
);
